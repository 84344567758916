import React, { Component } from 'react'
import { FaCheck } from 'react-icons/fa/index'
import PropTypes from 'prop-types'
import Question from '../../../_models/Question'

class ConfirmButton extends Component {
  render () {
    return (
      <div className='col-12'>
        <button type="button"
                onClick={() => this.props.handleAnswerConfirm({ questionId: this.props.question.id }, () => {
                  this.props.setCanQuestionAnswerBeConfirmed(false)
                  this.props.setIsQuestionConfirmedByUser(true)
                  this.props.updateUsedHints()
                })}
                className="assignment-item__question-confirm-button w-100">Confirm <FaCheck/></button>
      </div>
    )
  }
}

ConfirmButton.propTypes = {
  question: PropTypes.instanceOf(Question).isRequired,
  handleAnswerConfirm: PropTypes.func.isRequired,
  updateUsedHints: PropTypes.func.isRequired,
  setCanQuestionAnswerBeConfirmed: PropTypes.func.isRequired,
  setIsQuestionConfirmedByUser: PropTypes.func.isRequired,
}

export default ConfirmButton
