import React, { Component } from 'react'
import PropTypes from 'prop-types'
import NotificationsListItem from './NotificationsListItem'

class NotificationsList extends Component {
  render () {
    return (
      <div className="notifications-list p-3">
        <ul className='list-unstyled fade-container'>
          {this.props.notifications.map((notification, index) => {
            return (
              <li key={notification.id} className=''>
                <NotificationsListItem notification={notification} utc fromNow tz={this.props.tz || ''}/>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
}

NotificationsList.propTypes = {
  notifications: PropTypes.array.isRequired,
  tz: PropTypes.string,
}

export default NotificationsList
