import React from 'react'
import AchievementItem from './AchievementItem'
import * as PropTypes from 'prop-types'
import SectionHeader from '../SectionHeader'

class Achievements extends React.Component {
  state = {
    activeItem: null,
  }
  
  handleItemClick = (activeItem) => {
    this.setState({ activeItem: ![null, this.state.activeItem].includes(activeItem) ? activeItem : null })
  }
  
  render () {
    let { achievements } = this.props
    return (
      <>
        <div className="row">
          <div className="col-12">
            <SectionHeader wrapperClass='col awards-section-header--achievements' title={'Achievements'}/>
          </div>
          <div className="col-12">
            <div className="d-flex flex-wrap achievements-list">
              {achievements.map((achievement, index) => {
                return <div ref={React.createRef()} key={index}>
                  <AchievementItem description={achievement.description}
                                   thumbnail={achievement.thumbnail} title={achievement.title}
                                   index={index}
                                   handleClick={this.handleItemClick}
                                   expanded={this.state.activeItem === index}
                  />
                </div>
              })}
            </div>
          </div>
        </div>
      </>
    )
  }
}

Achievements.propTypes = { achievements: PropTypes.arrayOf(PropTypes.any) }

Achievements.defaultProps = { achievements: [] }

export default Achievements
