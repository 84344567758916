import React from 'react'
import Footer from '../common/Footer'
import learningLawsImg from '../../resources/img/learning_laws.jpg'

const PlatformInfo = () => {
  return (
    <>
      <div className="background-sp-full">
        <div className="container padding-bottom-x2-large">
          <div className="row">
            <div className="col-6 ml-auto mr-auto mt-5 background-white p-2">
              <h3 className="text-center font-sp">The Study Bubble</h3>
              <p>There are two ways to use it.</p>
              <p>Assignment - you respond to a set of questions issued by your teacher in advance.</p>
              <p>Explore - you freely roam about in all available questions, as you like.</p>
              <p>The Progress menu allows you to follow your achievements over time.</p>
              <p>Note that your achievements are by default private. Your bubble is yours alone!  Not even your teacher can see how you are doing unless you actively decide to unlock it in the Progress menu.</p>
              <div>
                <img src={learningLawsImg} alt={'Learning Laws'} className="img-fluid mb-3"/>
              </div>
              <p>The Study Bubble is based <a href="https://trackfootballconsortium.com/do-it-to-remember-it/" target="_blank">on basketball coach John Wooden’s 8 laws of learning</a> - especially the last 5 laws.</p>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  )
}

export default PlatformInfo
