import { fetchWrapper } from '../_helpers';
const apiUrl =  process.env.REACT_APP_API_URL;
const baseUrl = `${apiUrl}/api/v1/comments`;

export const commentService = {
    getByAssignmentQuestionId,
    create,
    update,
    delete: _delete,
};

function getByAssignmentQuestionId(id, limit, offset) {
    // TODO: refactor
    if(limit !== undefined) {
        if(offset !== undefined) {
            return fetchWrapper.get(`${baseUrl}/${id}/${limit}/${offset}`);
        }
        return fetchWrapper.get(`${baseUrl}/${id}/${limit}`);
    }
    return fetchWrapper.get(`${baseUrl}/${id}`);
}

function create(params) {
    return fetchWrapper.post(baseUrl, params);
}

function update(id, params) {
    return fetchWrapper.put(`${baseUrl}/${id}`, params);
}

// prefixed with underscore because 'delete' is a reserved word in javascript
function _delete(id) {
    return fetchWrapper.delete(`${baseUrl}/${id}`);
}
