import React, { Component } from 'react'
import QuestionListItem from './questionList/QuestionListItem'
import Question from '../../_models/Question'
import { accountService, alertService, statisticsService } from '../../_services'
import { MathJaxContext } from 'better-react-mathjax'
import PropTypes from 'prop-types'

class ExploreList extends Component {
  state = {
    activeQuestion: null,
  }
  
  timer = null
  user = null
  
  sendLogApiCall = (question, sec) => {
    const params = {
      questionId: question.id,
      userId: this.user.id,
      seconds: sec,
    }
    statisticsService.questionViewed(params)
      .catch(() => {
        alertService.error('Something went wrong while sending the stats.')
      })
  }
  
  handleToggleCollapse = (question) => {
    const activeQuestion = this.state.activeQuestion
      ? (this.state.activeQuestion.id !== question.id ? question : null)
      : question
    this.setState({ activeQuestion })
    if (activeQuestion) {
      clearInterval(this.timer)
      this.timer = null
      this.sendLogApiCall(activeQuestion, 5)
      this.timer = setInterval(() => this.sendLogApiCall(activeQuestion, 10), 10000)
    } else {
      clearInterval(this.timer)
      this.timer = null
    }
  }
  
  componentDidMount () {
    this.user = accountService.userValue
  }
  
  render () {
    const { questions, questionFlagCategories, handleTopicBadgeClick, showPreviousAnswers } = this.props
    return (
      <>
        <MathJaxContext>
          <div className="col">
            {questions.map(item => {
              const isActiveQuestion = this.state.activeQuestion && this.state.activeQuestion.id === item.id
      
              return (
                <div className="row mb-3" key={'question_item_' + item.id}>
                  <div className="col">
                    <QuestionListItem question={new Question(item)}
                                      collapsed={!isActiveQuestion}
                                      handleToggleCollapsed={this.handleToggleCollapse}
                                      handleMarkUsersAnswersUpdated={false}
                                      questionFlagCategories={questionFlagCategories}
                                      showPreviousAnswers={showPreviousAnswers}
                                      handleTopicBadgeClick={handleTopicBadgeClick}/>
                  </div>
                </div>
              )
            })}
          </div>
        </MathJaxContext>
      </>
    )
  }
}

ExploreList.propTypes = {
  handleTopicBadgeClick: PropTypes.any,
  questionFlagCategories: PropTypes.any,
  questions: PropTypes.any,
  showPreviousAnswers: PropTypes.bool,
}

export default ExploreList