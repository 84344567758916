import { fetchWrapper } from '../_helpers'

const apiUrl = process.env.REACT_APP_API_URL
const baseUrl = `${apiUrl}/api/v1/hints`

export const hintService = {
  getById,
  getByAssignmentQuestionId,
  getByQuestionId,
  getByQuestionAnswerId,
  delete: _delete,
}

function getById (id) {
  return fetchWrapper.get(`${baseUrl}/${id}`)
}

function getByQuestionId (id) {
  return fetchWrapper.get(`${baseUrl}/question/${id}`)
}

function getByAssignmentQuestionId (id) {
  return fetchWrapper.get(`${baseUrl}/assignment-question/${id}`)
}

function getByQuestionAnswerId (id) {
  return fetchWrapper.get(`${baseUrl}/answer/${id}`)
}

// prefixed with underscore because 'delete' is a reserved word in javascript
function _delete (id) {
  return fetchWrapper.delete(`${baseUrl}/${id}`)
}