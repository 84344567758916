import React, { Component } from 'react'
import PropTypes from 'prop-types'
import UserAnswerIndicator from './UserAnswerIndicator'
import QuestionUserAnswer from '../../../_models/QuestionUserAnswer'
import QuestionAnswer from '../../../_models/QuestionAnswer'
import Question from '../../../_models/Question'
import QuestionResultResponse from '../../../_models/QuestionResultResponse'

class ListItem extends Component {
  render () {
    const wrapperClass = 'answer-item-body'
    
    return (
      <a
        className={'h-100 d-block ' + wrapperClass
        + ((this.props.isSelected && (this.props.isCorrect || this.props.isWrong)) ? (' ' + wrapperClass + '--' + (this.props.isCorrect ? 'correct' : 'wrong')) : '')
        + (this.props.isSelected ? ' active' : '')}
        onClick={() => !this.props.handleSelect ? () => {} : this.props.handleSelect({
          questionId: this.props.question.id,
          questionAnswerId: this.props.answer.id,
        }, () => this.props.setCanQuestionAnswerBeConfirmed(true))}
      >
        <div className='row no-gutters h-100 p-2'>
          <div className='col'>
            <div
              className='assignment-item__answer_body'>{this.props.answer.body}</div>
          </div>
          <div className="col-auto d-flex flex-row justify-content-between">
            {this.props.questionAnswerResultResponse.answers.map(userAnswer => {
              return (this.props.isSelected && userAnswer.questionAnswerId === this.props.answer.id)
                ? <div key={userAnswer.id}>
                  <UserAnswerIndicator userAnswer={new QuestionUserAnswer(userAnswer)}/>
                </div>
                : false
            })}
          </div>
        </div>
      </a>
    )
  }
}

ListItem.propTypes = {
  answer: PropTypes.instanceOf(QuestionAnswer).isRequired,
  question: PropTypes.instanceOf(Question).isRequired,
  isSelected: PropTypes.bool.isRequired,
  isCorrect: PropTypes.bool,
  isWrong: PropTypes.bool,
  handleSelect: PropTypes.func,
  questionAnswerResultResponse: PropTypes.instanceOf(QuestionResultResponse),
  setCanQuestionAnswerBeConfirmed: PropTypes.func.isRequired,
}

export default ListItem
