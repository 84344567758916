import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CommentsListItem from './CommentsListItem'
import CommentsLoader from './CommentsLoader'

class CommentsList extends Component {
    doLoadMoreComments = async () => {
        this.props.loadMoreComments();
    }

  render () {
    return (
      <div className="CommentsList">
        <ul className='list-unstyled'>
          {this.props.comments.map((comment, index) => {
            return (
              <li key={comment.id} className='mb-1'>
                <CommentsListItem comment={comment}/>
              </li>
            )
          })}
        </ul>

        <CommentsLoader loadingComments={this.props.loadingComments} handleClick={() => this.doLoadMoreComments()}/>
      </div>
    )
  }
}

CommentsList.propTypes = {
  comments: PropTypes.array.isRequired,
  loadingComments: PropTypes.bool,
}

export default CommentsList
