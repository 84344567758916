import React, { Component } from 'react'
import { FaUndo } from 'react-icons/fa/index'
import PropTypes from 'prop-types'
import AssignmentQuestion from '../../../_models/AssignmentQuestion'

class RetryButton extends Component {
  render () {
    return (
      <div className='col-12'>
        <button type="button"
                onClick={() => this.props.handleAnswerRetry({ assignmentQuestionId: this.props.assignmentQuestion.id }, () => {
                  this.props.setCanQuestionAnswerBeConfirmed(false)
                  this.props.setIsQuestionConfirmedByUser(false)
                })}
                className="assignment-item__question-confirm-button w-100">Retry <FaUndo/></button>
      </div>
    )
  }
}

RetryButton.propTypes = {
  assignmentQuestion: PropTypes.instanceOf(AssignmentQuestion).isRequired,
  handleAnswerRetry: PropTypes.func.isRequired,
  setCanQuestionAnswerBeConfirmed: PropTypes.func.isRequired,
  setIsQuestionConfirmedByUser: PropTypes.func.isRequired,
}

export default RetryButton
