import React from 'react'
import * as PropTypes from 'prop-types'

class TopicList extends React.Component {
  render () {
    let { tests } = this.props
    return (
      <div className="col-12">
        {Object.entries(tests).map(([key, test]) => {
          return (
            <div key={'topic_' + key} className="row mb-1">
              <div className="col-9">
                {test.category &&
                <div className="topic-list__category" style={{ backgroundColor: test.category.color }}>
                  <img src={test.category.logo} alt={test.category.title}/>
                </div>
                }
              </div>
              <div className="col-1 topic-list__test-count ml-n1 ml-sm-n2 ml-md-n3 ml-lg-n4 ml-xl-n5">
                <p
                  className="font-white font-weight-bold font-size-20 text-center">{test.perfect.length + test.withHints.length + test.failed.length}</p>
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}

TopicList.propTypes = { tests: PropTypes.any }

export default TopicList
