import React, { Component } from 'react'
import PropTypes from 'prop-types'

class QuestionCategoryLogo extends Component {
  render () {
    return (
      <div style={{ backgroundColor: this.props.backgroundColor }} className='h-100'>
        <img src={this.props.icon} className='img-fluid w-25 mx-auto d-block py-5' alt={'Question Category Logo'}/>
      </div>
    )
  }
}

QuestionCategoryLogo.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
}

export default QuestionCategoryLogo
