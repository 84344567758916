import React, { Component } from 'react'
import PropTypes from 'prop-types'

class CollapseButton extends Component {
  render () {
    return (
      <div className={'collapse-btn ' + (this.props.wrapperClass || '')} onClick={this.props.handleClick}>
        <div className={'btn btn-link ' + (this.props.noPadding ? 'p-0' : '')}>
          {this.props.isExpanded
            ? (this.props.expandedIcon || '-')
            : (this.props.collapsedIcon || '+')}
        </div>
      </div>
    )
  }
}

CollapseButton.propTypes = {
  collapsedIcon: PropTypes.object,
  expandedIcon: PropTypes.object,
  isExpanded: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
  wrapperClass: PropTypes.string,
  noPadding: PropTypes.bool
}

export default CollapseButton
