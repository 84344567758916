import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Moment from 'react-moment'
import 'moment-timezone'
import ReactHtmlParser from 'react-html-parser'

class NotificationsListItem extends Component {
  render () {
    const { createdAt, message } = this.props.notification
    return (
      <div className="notifications-item-body row no-gutters h-100">
        <div className='col-12'>
          <Moment utc fromNow tz={this.props.tz || ''}>{createdAt}</Moment> | <div
          className="d-inline">{ReactHtmlParser(message)}</div>
        </div>
      </div>
    )
  }
}

NotificationsListItem.propTypes = {
  notification: PropTypes.object.isRequired,
  tz: PropTypes.string,
}

export default NotificationsListItem
