import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ListItems from './ListItems'
import Selector from './Selector'
import AssignmentQuestion from '../../../_models/AssignmentQuestion'
import User from '../../../_models/User'
import AssignmentQuestionResultResponse from '../../../_models/AssignmentQuestionResultResponse'

class Questions extends Component {
  static questionTypes
  
  state = {
    selectedAssignmentQuestion: this.props.assignmentQuestions[0]
  }
  
  handleNavButtonClick = (questionNum) => {
    this.setState({ selectedAssignmentQuestion: this.props.assignmentQuestions[questionNum] || this.props.assignmentQuestions[0] })
  }

  handleSelectorNavButtonClick = (selectedAssignmentQuestion) => {
    this.setState({ selectedAssignmentQuestion })
  }
  
  render () {
    const activeQuestion = this.state.selectedAssignmentQuestion
    
    return (
      <div className="Questions row no-gutters mt-2">
        <div className='col-1'>
          <Selector questions={this.props.assignmentQuestions} activeQuestion={activeQuestion}
                    handleSelect={this.handleSelectorNavButtonClick}
                    userAnswers={this.props.usersAnswers}/>
        </div>
        <div className='col-11'>
          <ListItems user={this.props.user}
                     assignmentQuestions={this.props.assignmentQuestions}
                     assignment={this.props.assignment}
                     groupId={this.props.groupId}
                     handleAnswerRetry={this.props.handleAnswerRetry}
                     handleAnswerSelect={this.props.handleAnswerSelect}
                     handleAnswerSubmit={this.props.handleAnswerSubmit}
                     handleAnswerConfirm={this.props.handleAnswerConfirm}
                     afterAnswerConfirm={this.props.afterAnswerConfirm}
                     handleNavButtonClick={this.handleNavButtonClick}
                     selectedAssignmentQuestion={activeQuestion}
                     usersAnswers={this.props.usersAnswers}
                     questionFlagCategories={this.props.questionFlagCategories}/>
        </div>
      </div>
    )
  }
}

Questions.propTypes = {
  assignmentQuestions: PropTypes.arrayOf(PropTypes.shape(new AssignmentQuestion())).isRequired,
  groupId: PropTypes.number,
  handleAnswerRetry: PropTypes.func.isRequired,
  handleAnswerSelect: PropTypes.func.isRequired,
  handleAnswerSubmit: PropTypes.func.isRequired,
  handleAnswerConfirm: PropTypes.func.isRequired,
  afterAnswerConfirm: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(User).isRequired,
  usersAnswers: PropTypes.arrayOf(PropTypes.shape(new AssignmentQuestionResultResponse())).isRequired,
}

Questions.questionTypes = {
  multipleChoice: 2,
  trueOrFalse: 3,
  text: 4,
}

export default Questions
