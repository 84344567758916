import React, { Component } from 'react'
import PropTypes from 'prop-types'
import UserAnswerIndicator from './UserAnswerIndicator'
import FinalIndicator from './FinalIndicator'
import AssignmentQuestion from '../../../_models/AssignmentQuestion'
import QuestionUserAnswer from '../../../_models/QuestionUserAnswer'
import QuestionAnswer from '../../../_models/QuestionAnswer'
import AssignmentQuestionAnswerResultResponse from '../../../_models/AssignmentQuestionAnswerResultResponse'

class ListItem extends Component {
  state = {
    isSubmitting: false,
  }
  
  render () {
    const wrapperClass = 'answer-item-body'
    
    return (
      <a
        className={'h-100 d-block ' + wrapperClass
        + ((this.props.isFinal && (this.props.isCorrect || this.props.isWrong))
            ? (' ' + wrapperClass + '--' + ((this.props.isCorrect && this.props.allSelectedAnswersCorrect) ? 'correct' : 'wrong'))
            : '')
        + (this.props.isSelected ? ' active' : '')}
        onClick={!this.props.handleSelect ? () => {} : () => {
          this.setState({ isSubmitting: true })
          this.props.handleSelect({
            assignmentQuestionId: this.props.assignmentQuestion.id,
            questionAnswerId: this.props.answer.id,
          }, () => {
            this.setState({ isSubmitting: false })
            this.props.setCanQuestionAnswerBeConfirmed(true)
          })
        }
        }
      >
        <div className='row no-gutters h-100 p-2'>
          <div className='col'>
            {this.state.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"/>}
            <div
              className='assignment-item__answer_body'>{(this.props.assignmentQuestion.groupQuestion && this.props.isFinal) &&
            <FinalIndicator/>}{this.props.answer.body}</div>
          </div>
          <div className="col-auto d-flex flex-row justify-content-between">
            {this.props.assignmentQuestionAnswerResultResponse.userAnswers.map(userAnswer => {
              return (
                <div key={userAnswer.id}>
                  <UserAnswerIndicator userAnswer={new QuestionUserAnswer(userAnswer)}/>
                </div>
              )
            })}
          </div>
        </div>
      </a>
    )
  }
}

ListItem.propTypes = {
  allSelectedAnswersCorrect: PropTypes.bool.isRequired,
  answer: PropTypes.instanceOf(QuestionAnswer).isRequired,
  assignmentQuestion: PropTypes.instanceOf(AssignmentQuestion).isRequired,
  isSelected: PropTypes.bool.isRequired,
  isFinal: PropTypes.bool.isRequired,
  isCorrect: PropTypes.bool,
  isWrong: PropTypes.bool,
  handleSelect: PropTypes.func,
  assignmentQuestionAnswerResultResponse: PropTypes.instanceOf(AssignmentQuestionAnswerResultResponse),
  setCanQuestionAnswerBeConfirmed: PropTypes.func.isRequired,
}

export default ListItem
