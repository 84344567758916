import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Alert from 'react-bootstrap/Alert'
import QuestionFeedback from '../../../_models/QuestionFeedback'
import RetryButton from './RetryButton'
import Question from '../../../_models/Question'
import Hint from './Hint'

class Result extends Component {
  state = {
    show: true, // TODO: check if we need mor complex logic here
  }
  
  setShow (show) {
    this.setState({ show })
  }
  
  render () {
    // TODO: show only when the answer is given for the first time? Or figure out a better business logic here?
    return this.state.show ?
      <Alert variant="light" onClose={() => this.setShow(false)} dismissible className={'p-0 border-0 mt-3'}>
        <Alert.Heading className={'position-relative'}>
          <div
            className={'question-item-type d-table p-2 mr-0 ml-auto ' + (!this.props.correct ? 'type-danger' : 'type-success')}>
            <div className={'pr-5 text-white'}>
              {!this.props.correct ? 'Wrong' : 'Correct'}
            </div>
          </div>
        </Alert.Heading>
        <div className="question-item-body h-100 bg-white border-0">
          <div className='row no-gutters py-2'>
            <div className='col-12'>
              <div
                className={(this.props.correct ? 'text-success' : 'text-danger') + ' assignment-item__question_body px-4'}>
                {this.props.correct
                  ? (this.props.feedback.body || 'Congratulations! Your answer is correct')
                  : (this.props.feedback.body || 'Sorry, the answer is not correct.')}
                {(!this.props.correct && this.props.hint) && <Hint hint={this.props.hint}/>}
              </div>
            </div>
            {!this.props.correct &&
            <div className='col-12 col-lg-6 mx-auto mt-3'>
              <RetryButton
                question={this.props.question}
                handleAnswerRetry={this.props.handleAnswerRetry}
                setIsQuestionConfirmedByUser={this.props.setIsQuestionConfirmedByUser}
                setCanQuestionAnswerBeConfirmed={this.props.setCanQuestionAnswerBeConfirmed}
              />
            </div>
            }
          </div>
        </div>
      </Alert>
      : ''
  }
}

Result.propTypes = {
  question: PropTypes.instanceOf(Question).isRequired,
  handleAnswerRetry: PropTypes.func.isRequired,
  correct: PropTypes.bool.isRequired,
  feedback: PropTypes.instanceOf(QuestionFeedback),
  setCanQuestionAnswerBeConfirmed: PropTypes.func.isRequired,
  setIsQuestionConfirmedByUser: PropTypes.func.isRequired,
}

export default Result
