import React, { Component } from 'react'
import PropTypes from 'prop-types'
import InfoSlider from './InfoSlider'
import Notifications from './notifications/Index'
import { accountService, notificationService } from '../../_services/'
import Spinner from '../common/Spinner'


class InfoPanel extends Component {
  state = {
    notifications: [],
    notificationsLoading: true
  }
  
  componentDidMount() {
    notificationService.getRecent().then((res) => this.initNotifications(res));
    const user = accountService.userValue;
    if (this.props.echo) {
      this.props.echo.private(`App.User.${user.id}`).listen('QuestionCommentCreatedNotification', (response) => {
        if (response.notification) {
          const {notifications} = this.state;
          this.setState({notifications: [response.notification, ...notifications]});
        }
      });
  
      // TODO: refactor
      this.props.echo.private(`App.User.${user.id}`).listen('AssignmentAssignedNotification', (response) => {
        if (response.notification) {
          const {notifications} = this.state;
          this.setState({notifications: [response.notification, ...notifications]});
        }
      });
    }
  }
  
  initNotifications(notifications) {
    this.setState({notifications, notificationsLoading: false});
  }

  render () {
    const currentUser = accountService.userValue;
    return (
      <div className="info-panel w-100 bg-white position-fixed">
        <div className='row no-gutters'>
          <div className='col-12 col-lg-8'>
            { this.state.notificationsLoading
              ? <Spinner/>
              : this.state.notifications.length > 0 && <Notifications notifications={this.state.notifications} user={currentUser} utc fromNow tz={this.props.tz || ''}/> }
          </div>
          <div className='col-12 col-lg-4'>
            {this.props.slideItem && <InfoSlider slideItem={this.props.slideItem}/>}
          </div>
        </div>
      </div>
    )
  }
}

InfoPanel.propTypes = {
  echo: PropTypes.object,
  slideItem: PropTypes.object,
  tz: PropTypes.string,
}

export default InfoPanel
