import { BehaviorSubject } from 'rxjs'

import { fetchWrapper, history } from '../_helpers'

const userSubject = new BehaviorSubject(null)
const apiUrl = process.env.REACT_APP_API_URL
const baseUrl = `${apiUrl}/api/v1/accounts`

export const accountService = {
  login,
  logout,
  refreshToken,
  register,
  verifyEmail,
  forgotPassword,
  validateEmail,
  validateResetToken,
  resetPassword,
  getAll,
  getById,
  create,
  update,
  lock,
  unlock,
  delete: _delete,
  user: userSubject.asObservable(),
  get userValue () { return userSubject.value }
}

function login (email, password) {
  return fetchWrapper.post(`${baseUrl}/authenticate`, { email, password })
    .then(user => {
      // publish user to subscribers and start timer to refresh token
      userSubject.next(user)
      startRefreshTokenTimer() // TODO
      return user
    })
}

function logout () {
  // revoke token, stop refresh timer, publish null to user subscribers and redirect to login page
  fetchWrapper.post(`${baseUrl}/revoke-token`, {})
  stopRefreshTokenTimer()
  userSubject.next(null)
  history.push('/account/login')
}

function refreshToken () {
  return fetchWrapper.post(`${baseUrl}/refresh-token`, {})
    .then(user => {
      // publish user to subscribers and start timer to refresh token
      userSubject.next(user)
      startRefreshTokenTimer()
      return user
    }, function (reason) {
      // The refresh token route returns unauthorized if the user was not logged in in the first place
      console.log('User not logged in, token not refreshed')
    })
}

function register (params) {
  return fetchWrapper.post(`${baseUrl}/register`, params)
}

function verifyEmail (token) {
  return fetchWrapper.post(`${baseUrl}/verify-email`, { token })
}

function forgotPassword (email) {
  return fetchWrapper.post(`${baseUrl}/forgot-password`, { email })
}

function validateResetToken ({ token, email }) {
  return fetchWrapper.post(`${baseUrl}/validate-reset-token`, { token, email })
}

function validateEmail (email) {
  return fetchWrapper.post(`${baseUrl}/validate-email`, { email })
}

function resetPassword ({ token, email, password, confirmPassword }) {
  return fetchWrapper.post(`${baseUrl}/reset-password`, { token, email, password, confirmPassword })
}

function lock () {
  return fetchWrapper.post(`${baseUrl}/lock`)
}

function unlock () {
  return fetchWrapper.post(`${baseUrl}/unlock`)
}

function getAll () {
  return fetchWrapper.get(baseUrl)
}

function getById (id) {
  return fetchWrapper.get(`${baseUrl}/${id}`)
}

function create (params) {
  return fetchWrapper.post(baseUrl, params)
}

function update (id, params) {
  return fetchWrapper.put(`${baseUrl}/${id}`, params)
    .then(user => {
      // update stored user if the logged in user updated their own record
      if (user.id === userSubject.value.id) {
        // publish updated user to subscribers
        user = { ...userSubject.value, ...user }
        userSubject.next(user)
      }
      return user
    })
}

// prefixed with underscore because 'delete' is a reserved word in javascript
function _delete (id) {
  return fetchWrapper.delete(`${baseUrl}/${id}`)
    .then(x => {
      // auto logout if the logged in user deleted their own record
      if (id === userSubject.value.id) {
        logout()
      }
      return x
    })
}

// helper functions

let refreshTokenTimeout

function startRefreshTokenTimer () {
  // parse json object from base64 encoded jwt token
  const jwtToken = JSON.parse(atob(userSubject.value.jwtToken.split('.')[1]))
  
  // set a timeout to refresh the token a minute before it expires
  const expires = new Date(jwtToken.exp * 1000)
  const timeout = expires.getTime() - Date.now() - (60 * 1000)
  refreshTokenTimeout = setTimeout(refreshToken, timeout)
}

function stopRefreshTokenTimer () {
  clearTimeout(refreshTokenTimeout)
}
