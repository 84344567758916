/**
 * @property {number} assignmentQuestionId
 * @property {?boolean} [isCorrect]
 * @property {AssignmentQuestionAnswerResultResponse[]} [answers]
 */
class AssignmentQuestionResultResponse {
  constructor (obj) {
    Object.assign(this, obj)
  }
}

export default AssignmentQuestionResultResponse
