import React, { Component } from 'react'
import { FaCheck } from 'react-icons/fa/index'
import PropTypes from 'prop-types'
import AssignmentQuestion from '../../../_models/AssignmentQuestion'

class ConfirmButton extends Component {
  render () {
    return (
      <div className='col-12'>
        <button type="button"
                onClick={() => this.props.handleAnswerConfirm({ assignmentQuestionId: this.props.assignmentQuestion.id }, (usersAnswers) => {
                  this.props.setCanQuestionAnswerBeConfirmed(false)
                  this.props.setIsQuestionConfirmedByUser(true)
                  this.props.updateUsedHints(usersAnswers)
                  this.props.afterAnswerConfirm(usersAnswers)
                })}
                className="assignment-item__question-confirm-button w-100">Confirm <FaCheck/></button>
      </div>
    )
  }
}

ConfirmButton.propTypes = {
  assignmentQuestion: PropTypes.instanceOf(AssignmentQuestion).isRequired,
  handleAnswerConfirm: PropTypes.func.isRequired,
  afterAnswerConfirm: PropTypes.func.isRequired,
  updateUsedHints: PropTypes.func.isRequired,
  setCanQuestionAnswerBeConfirmed: PropTypes.func.isRequired,
  setIsQuestionConfirmedByUser: PropTypes.func.isRequired,
}

export default ConfirmButton
