import React, { Component } from 'react'
import PropTypes from 'prop-types'

class CommentsLoader extends Component {
  render () {
    return (
      <div className="comments-loader text-center my-2">
        <div className="d-flex">
          <div className="flex-grow-1">
            <div className='assignment-item__middle-line'/>
          </div>
          <div className="px-3">
            <button type='button' onClick={this.props.handleClick}
                    className='btn btn-no-style text-white'>
                {this.props.loadingComments && <span className="spinner-border spinner-border-sm mr-1"></span>}
                {'Load More Comments'}</button>
          </div>
          <div className="flex-grow-1">
            <div className='assignment-item__middle-line'/>
          </div>
        </div>
      </div>
    )
  }
}

CommentsLoader.propTypes = {
  handleClick: PropTypes.func.isRequired,
    loadingComments: PropTypes.bool,
}

export default CommentsLoader
