import React from 'react'

const TopicBadge = ({ title, value, clickAction }) => {
  return (
    <span className="badge badge-secondary cursor-pointer select-none mr-1"
          onClick={() => clickAction(value)}>{title}</span>
  )
}

export default TopicBadge
