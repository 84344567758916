import React from 'react'
import * as PropTypes from 'prop-types'
import Item from './Item'

class TestRatingList extends React.Component {
  render () {
    let { tests } = this.props
    let testData = {
      perfectCount: 0,
      withHintsCount: 0,
      failedCount: 0,
      total: 0
    }
    
    Object.entries(tests).forEach(([key, test]) => {
      if (test.perfect) {
        testData.perfectCount += test.perfect.length
        testData.total += test.perfect.length
      }
      
      if (test.withHints) {
        testData.withHintsCount += test.withHints.length
        testData.total += test.withHints.length
      }
      
      if (test.failed) {
        testData.failedCount += test.failed.length
        testData.total += test.failed.length
      }
    })
    
    return (
      <>
        <Item label={'Tests done'} rating={testData.total}/>
        <Item label={'Perfect'} rating={testData.perfectCount} iconClass='rating-icon rating-icon--perfect'/>
        <Item label={'With Hints'} rating={testData.withHintsCount} iconClass='rating-icon rating-icon--hints'/>
        <Item label={'Failed'} rating={testData.failedCount} iconClass='rating-icon rating-icon--failed'/>
      </>
    )
  }
}

TestRatingList.propTypes = {
  tests: PropTypes.array
}

export default TestRatingList
