/**
 * @property {number} id
 * @property {number} assignmentId
 * @property {Question} question
 * @property {boolean} groupQuestion
 * @property {number} groupVotesNeeded
 * @property {number} order
 */
class AssignmentQuestion {
  constructor (obj) {
    Object.assign(this, obj)
  }
}

export default AssignmentQuestion
