import React from 'react'
import { Link } from 'react-router-dom'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'

import { accountService, alertService } from '../../_services'

function ForgotPassword () {
  const initialValues = {
    email: ''
  }
  
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email is invalid')
      .required('Email is required')
  })
  
  function onSubmit ({ email }, { setSubmitting }) {
    alertService.clear()
    accountService.forgotPassword(email)
      .then(() => alertService.success('Please check your email for password reset instructions'))
      .catch(error => {
        console.log(error)
        alertService.error('Something went wrong. Please try again.')
      })
      .finally(() => setSubmitting(false))
  }
  
  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ errors, touched, isSubmitting }) => (
        <Form>
          <div className="form-row">
            <div className="form-group col-md-12 col-sm-12 col-10 px-5 padding-top-x-small min-vh-60">
              <h3 className="font-sp">Forgot Password</h3>
              <div className="form-row">
                <div className="form-group col">
                  <Field name="email" type="text" placeholder="E-Mail..."
                         className={'width-100 font-white placeholder-white placeholder-italic background-sp border-sp' + (errors.email && touched.email ? ' is-invalid' : '')}/>
                  <ErrorMessage name="email" component="div" className="invalid-feedback"/>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col">
                  <button type="submit" disabled={isSubmitting} className="button-sp font-white">
                    {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"/>}
                    Submit
                  </button>
                  <Link to="login" className="btn btn-link">Cancel</Link>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
  
  /*    <h3 className="card-header">Forgot Password</h3>
  <div className="card-body">
      <div className="form-group">
          <label>Email</label>
          <Field name="email" type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
          <ErrorMessage name="email" component="div" className="invalid-feedback" />
      </div>
      <div className="form-row">
          <div className="form-group col">
              <button type="submit" disabled={isSubmitting} className="btn btn-primary">
                  {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                  Submit
              </button>
              <Link to="login" className="btn btn-link">Cancel</Link>
          </div>
      </div>
  </div>*/
}

export { ForgotPassword }
