import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FormikTextInput from '../../common/TextInput'
import { Formik } from 'formik'
import { FaTelegramPlane } from 'react-icons/fa'
import AssignmentQuestion from '../../../_models/AssignmentQuestion'
import QuestionUserAnswer from '../../../_models/QuestionUserAnswer'

class TextInput extends Component {
  render () {
    const wrapperClass = 'answer-item-body'
    
    return (
      <div className={'question-item__comment_body h-100'}>
        <Formik
          initialValues={this.props.userAnswer || {}}
          enableReinitialize={true}
          validate={values => {
            const errors = {}
            if (!values.body) {
              errors.body = 'Required'
            }
            
            return errors
          }}
          onSubmit={!this.props.handleSubmit ? () => {} : (values, { setSubmitting }) =>
            this.props.handleSubmit({
              assignmentQuestionId: this.props.assignmentQuestion.id,
              body: values.body
            }, () => {
              setSubmitting(false)
              this.props.setCanQuestionAnswerBeConfirmed(true)
            })
          }
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form
              onSubmit={handleSubmit}
              method={1 ? 'PUT' : 'POST'}>
              <div className='row no-gutters mb-2'>
                <div className={this.props.isAnswered ? 'col-12' : 'col-11'}>
                  <FormikTextInput
                    name="body"
                    label=""
                    placeholder="Enter your answer here"
                    inputClass={wrapperClass + ((this.props.isVotedFor && (null !== this.props.isCorrect)) ? (' ' + wrapperClass + '--' + (this.props.isCorrect ? 'correct' : 'wrong')) : '')}
                    value={values.body}
                    onBlur={handleBlur}
                    onChange={(e) => (new Promise(() => handleChange(e))).finally(this.props.setCanQuestionAnswerBeConfirmed(false))}
                    disabled={isSubmitting || values.confirmed}
                    error={errors.body && touched.body && errors.body}
                  />
                </div>
                {!this.props.isAnswered
                &&
                <div className='col-1'>
                  <button
                    style={{ borderRadius: '0', marginRight: 0, marginLeft: '0', display: 'block' }}
                    type="submit"
                    disabled={isSubmitting || values.confirmed}
                    className="btn btn-success h-100 w-100">
                    {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"/>}
                    <FaTelegramPlane size="30px"/>
                  </button>
                </div>
                }
              </div>
            
            </form>
          )}
        </Formik>
      </div>
    )
  }
}

TextInput.propTypes = {
  assignmentQuestion: PropTypes.instanceOf(AssignmentQuestion).isRequired,
  setCanQuestionAnswerBeConfirmed: PropTypes.func,
  handleSubmit: PropTypes.func,
  isAnswered: PropTypes.bool,
  isCorrect: PropTypes.bool,
  isVotedFor: PropTypes.bool,
  userAnswer: PropTypes.instanceOf(QuestionUserAnswer),
}

export default TextInput
