import React from 'react'
import PropTypes from 'prop-types'

class PieArc extends React.Component {
  render () {
    let { arc, color } = this.props
    return (
      <g>
        <path className="arc" d={arc} fill={color}/>
      </g>
    )
  }
}

PieArc.propTypes = {
  arc: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
}

export default PieArc
