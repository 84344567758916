/**
 * @property {number} id
 * @property {number} type
 * @property {number} minAnswersQt
 * @property {boolean} display_correct_qt
 * @property {number} displayedCorrectQt
 * @property {number} difficulty_level
 * @property {string} title
 * @property {string} body
 * @property {QuestionAttachment[]} [attachments]
 * @property {QuestionAnswer[]} [answers]
 * @property {QuestionFeedback[]} [feedback]
 */
class Question {
  constructor (obj) {
    Object.assign(this, obj)
  }
}

export default Question
