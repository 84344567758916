import React from 'react'
import AwardListItemMedal from './ListItemMedal'
import * as PropTypes from 'prop-types'

class AwardListItem extends React.Component {
  render () {
    let { medal, number } = this.props
    return (
      <tr>
        <td className="width-10"/>
        <td className="width-5">
          <div className="d-inline-block align-top ml-1">
            <span className="font-white align-items-center">{number}.</span>
          </div>
        </td>
        <td className="width-40">
          <div className="award-list__category d-inline-block" style={{ backgroundColor: medal.category.color }}>
            <img src={medal.category.icon} alt={medal.category.title}/>
          </div>
          <div className="d-inline-block align-top ml-1">
            <span className="font-white align-items-center d-inline-block font-size-18">{medal.category.title}</span>
          </div>
        </td>
        <td className='align-middle'>
          <AwardListItemMedal bronze={medal.bronze} gold={medal.gold} silver={medal.silver}/>
        </td>
        <td className="width-10"/>
      </tr>
    )
  }
}

AwardListItem.propTypes = {
  medal: PropTypes.any,
  number: PropTypes.any
}

export default AwardListItem
