import { fetchWrapper } from '../_helpers';
const apiUrl =  process.env.REACT_APP_API_URL;
const baseUrl = `${apiUrl}/api/v1/escapeLines`;

export const escapeLineService = {
    getByQuestionId,
};

function getByQuestionId(id) {
    return fetchWrapper.get(`${baseUrl}/${id}`);
}
