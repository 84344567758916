/**
 * @property {number} id
 * @property {number} assignmentQuestionId
 * @property {number} questionId
 * @property {string} [body]
 * @property {boolean} confirmed
 * @property {boolean} [correct]
 * @property {boolean} [wrong]
 * @property {boolean} final
 * @property {number} createdBy
 * @property {string} createdAt
 * @property {string} updatedAt
 * @property {User} user
 */
class QuestionUserAnswer {
  constructor (obj) {
    Object.assign(this, obj)
  }
}

export default QuestionUserAnswer
