import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ListItem from './ListItem'
import AssignmentQuestion from '../../../_models/AssignmentQuestion'
import User from '../../../_models/User'
import AssignmentQuestionResultResponse from '../../../_models/AssignmentQuestionResultResponse'

class ListItems extends Component {
  render () {
    return (
      <div className="assignments-list">
        <ul className='list-unstyled'>
          {this.props.assignmentQuestions.map((assignmentQuestion, index) => {
            const isSelected = this.props.selectedAssignmentQuestion && this.props.selectedAssignmentQuestion.id === assignmentQuestion.id

            return (
              <li key={assignmentQuestion.id} className={(isSelected || !this.props.selectedAssignmentQuestion) ? '' : ' d-none'}>
                <ListItem
                  user={this.props.user}
                  assignmentQuestion={assignmentQuestion}
                  assignment={this.props.assignment}
                  groupId={this.props.groupId}
                  handleAnswerSelect={this.props.handleAnswerSelect}
                  handleAnswerSubmit={this.props.handleAnswerSubmit}
                  handleAnswerConfirm={this.props.handleAnswerConfirm}
                  handleNextButtonClick={this.props.handleNextButtonClick}
                  nextAssignmentQuestionNum={(index + 1 <= this.props.assignmentQuestions.length) ? index + 1 : 0}
                  assignmentQuestionNum={index + 1}
                  assignmentQuestionsTotalCount={this.props.assignmentQuestions.length}
                  assignmentQuestionAnswerResultResponse={this.props.usersAnswers
                    .filter(response => response.assignmentQuestionId === assignmentQuestion.id)[0]}
                  handleAnswerRetry={this.props.handleAnswerRetry}/>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
}

ListItems.propTypes = {
  assignmentQuestions: PropTypes.arrayOf(PropTypes.shape(new AssignmentQuestion())).isRequired,
  groupId: PropTypes.number,
  handleAnswerRetry: PropTypes.func.isRequired,
  handleAnswerSelect: PropTypes.func.isRequired,
  handleAnswerSubmit: PropTypes.func.isRequired,
  handleAnswerConfirm: PropTypes.func.isRequired,
  handleNextButtonClick: PropTypes.func.isRequired,
  selectedAssignmentQuestion: PropTypes.instanceOf(AssignmentQuestion),
  user: PropTypes.instanceOf(User).isRequired,
  usersAnswers: PropTypes.arrayOf(PropTypes.shape(new AssignmentQuestionResultResponse())).isRequired, // TODO: check if .isRequired needed
}

export default ListItems
