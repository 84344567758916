import React, { Component } from 'react'
import ScienceCarousel from '../common/ScienceCarousel'
import YearSelector from './YearSelector'
import Target from './target/Target'
import Timeline from './target/Timeline'
import TopicList from './target/TopicList/Index'
import TestRatingList from './target/TestRatingList/Index'
import MedalOverview from './medals/overview/Index'
import Privacy from './privacy/Index'
import Achievements from './achievements/Index'
import Spinner from '../common/Spinner'
import { accountService, achievementService, alertService } from '../../_services'
import User from '../../_models/User'

class Awards extends Component {
  user = new User(accountService.userValue)
  currentYear = new Date().getFullYear()
  
  state = {
    locked: this.user.private,
    loadingAchievements: true,
    loadingTests: true,
    loadingMedals: true,
    selectedYear: this.currentYear,
    timelineSliderValues: [],
    tests: [],
    filteredTests: [],
    medals: [],
    achievements: [],
  }
  
  componentDidMount () {
    // TODO: refactor - think of using one API call instead (to avoid multiple re-renderings on state update)
    // this.fetchMedals()
    this.fetchTests()
    this.fetchAchievements()
    // TODO: Data order should be sorted on server depending on medal values
  }
  
  fetchTests () {
    achievementService
      .getTests()
      .then((tests) => this.setState({
        tests,
        filteredYears: tests.filter(test => test.year === this.state.selectedYear)
      }))
      .catch(error => {
        alertService.error('Something went wrong while loading tests.')
      })
      .finally(() => this.setState({ loadingTests: false }))
  }
  
  fetchMedals () {
    achievementService
      .getMedals()
      .then((medals) => this.setState({ medals }))
      .catch(error => {alertService.error('Something went wrong while loading medals.')})
      .finally(() => this.setState({ loadingMedals: false }))
  }
  
  fetchAchievements () {
    achievementService
      .getAll()
      .then((achievements) => this.setState({ achievements }))
      .catch(error => {alertService.error('Something went wrong while loading achievements.')})
      .finally(() => this.setState({ loadingAchievements: false }))
  }
  
  handleYearChange = (year) => {
    this.setState({ selectedYear: parseInt(year), timelineSliderValues: [] })
  }
  
  handleChange = (event, timelineSliderValues) => {
    const isBetween = (min, max, value) => value >= min && value <= max
    const filterByRange = (testDates, timeRange) => testDates.filter(testDate => isBetween(timeRange[0], timeRange[1], (new Date(testDate)).getTime() / 1000))
    const filteredTests = this.state.tests
      .filter(test => test.year === this.state.selectedYear)
      .map(test => {
        let filteredTest = Object.assign({}, test)
  
        filteredTest.perfect = filterByRange(filteredTest.perfect, timelineSliderValues)
        filteredTest.withHints = filterByRange(filteredTest.withHints, timelineSliderValues)
        filteredTest.failed = filterByRange(filteredTest.failed, timelineSliderValues)
      
        return filteredTest
      })
  
    this.setState({filteredTests, timelineSliderValues: timelineSliderValues})
  }
  
  handlePrivacyChange () {
    (this.state.locked ? accountService.unlock() : accountService.lock())
      .then(() => this.setState({ locked: !this.state.locked }))
      .catch(error => {alertService.error('Something went wrong while trying to lock/unlock profile.')})
      .finally(() => this.setState({ loadingMedals: false }))
  }
  
  render () {
    const years = [...new Set(this.state.tests.map(test => test.year)), [this.currentYear]]
    
    return (
      <ScienceCarousel infoPanel={false}>
        <div className="p-4">
          <div className="container">
            <div className="divided row align-items-center">
              <div className='col-auto'>
                <span className="font-white font-size-20">Profile --- Achievements</span>
              </div>
              <div className='col'>
                <div className="divider"/>
              </div>
              <div className='col-auto'>
                {years.length > 0 &&
                <YearSelector year={this.state.selectedYear} years={years} handleYearChange={this.handleYearChange}/>}
              </div>
            </div>
            <div className="row">
              <div className="col-8">
                {this.state.loadingTests ? <Spinner/> : <Target key="userTarget" tests={this.state.filteredTests} width={600}
                                                                height={600}/>}
              </div>
              <div className="col-4 align-self-center">
                <div className="row">
                  <div className="col-7 col-sm-7 col-md-7 col-lg-6 col-xl-6 align-self-center">
                    {this.state.loadingTests ? <Spinner/> : <TopicList tests={this.state.filteredTests}/>}
                  </div>
                  <div className="col-5">
                    {this.state.loadingTests ? <Spinner/> : <TestRatingList tests={this.state.filteredTests}/>}
                  </div>
                </div>
              </div>
            </div>
            <div className="row awards-timeline">
              <div className="col">
                {this.state.loadingTests ? <Spinner/> : <Timeline tests={this.state.filteredTests} year={this.state.selectedYear}
                                                                  key={this.state.selectedYear}
                                                                  onChange={(event, values) => this.handleChange(event, values)}
                                                                  onChangeCommitted={(event, values) => this.handleChange(event, values)}
                                                                  values={this.state.timelineSliderValues}
                />}
              </div>
            </div>
            {
              false && <div className="row mt-5">
                <div className="col">
                  {this.state.loadingMedals ? <Spinner/> : <MedalOverview medals={this.state.medals}/>}
                </div>
              </div>
            }
            <div className="row mt-5 pb-5">
              <div className="col">
                {this.state.loadingAchievements ? <Spinner/> : this.state.achievements.length > 0 &&
                  <Achievements achievements={this.state.achievements}/>}
              </div>
            </div>
            <div className="mt-5">
              <Privacy locked={this.state.locked}
                       onPrivacyChange={() => this.handlePrivacyChange(!this.state.locked)}/>
            
            </div>
          </div>
        </div>
      </ScienceCarousel>
    )
  }
}

export { Awards }