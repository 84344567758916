/**
 * @property {number} id
 * @property {number} [questionFlagCategoryId]
 * @property {number} [questionId]
 * @property {number} [createdBy]
 * @property {string} body
 */
class QuestionFlag {
  constructor (obj) {
    Object.assign(this, obj)
  }
}

export default QuestionFlag
