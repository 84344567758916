import React, { Component } from 'react'
import PropTypes from 'prop-types'
import NotificationsList from './NotificationsList'

class Notifications extends Component {
  render () {
    return (
      <div className="d-flex" style={{maxHeight: '133px'}}>
        <div className=''>
          <img src={this.props.user.avatarUrl} className='img-fluid info-panel-picture d-block sp-avatar border-none'
               alt={'notification author avatar'}/>
        </div>
        <div className="flex-grow-1 pr-2">
          <NotificationsList notifications={this.props.notifications} utc fromNow tz={this.props.tz || ''}/>
        </div>
      </div>
    )
  }
}

Notifications.propTypes = {
  notifications: PropTypes.array.isRequired,
  tz: PropTypes.string,
}

export default Notifications
