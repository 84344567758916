import React from 'react'
import Footer from '../common/Footer'

const SchoolInfo = () => {
  return (
    <>
      <div className="background-sp-full">
        <div className="container padding-bottom-x2-large">
          <div className="row">
            <div className="col-6 ml-auto mr-auto mt-5 background-white p-2">
              <h3 className="text-center font-sp">Our School</h3>
              <p></p>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  
  )
}

export default SchoolInfo
