import React, { Component } from 'react'

class CategoryBarItem extends Component {
  render () {
    const { backgroundColor, icon, id, title } = this.props
    return (
      <div key={'category_item_' + id} className="topic-list__category cursor-pointer"
           style={{ backgroundColor: backgroundColor }}>
        <img src={icon} alt={title}/>
      </div>
    )
  }
}

export default CategoryBarItem