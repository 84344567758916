import { fetchWrapper } from '../_helpers';

const apiUrl =  process.env.REACT_APP_API_URL;
const baseUrl = `${apiUrl}/api/v1/achievements`;

export const achievementService = {
    getAll,
    getTests,
    getMedals,
};

function getAll() {
    return fetchWrapper.get(`${baseUrl}`);
}

function getTests() {
    return fetchWrapper.get(`${baseUrl}/tests`);
}

function getMedals() {
    return fetchWrapper.get(`${baseUrl}/medals`);
}
