import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CollapseButton from '../../common/CollapseButton'
import QuestionCategoryLogo from '../QuestionCategoryLogo'
import QuestionListItemBody from './QuestionListItemBody'
import { Collapse } from 'react-bootstrap'
import { FaMinus, FaPlus } from 'react-icons/fa/index'
import { accountService, alertService, userAnswerService} from '../../../_services'
import User from '../../../_models/User'
import Question from '../../../_models/Question'
import ListItem from '../questions/ListItem'
import QuestionResultResponse from '../../../_models/QuestionResultResponse'

class QuestionListItem extends Component {
  /**
   *
   * @type {{usersAnswers: AssignmentQuestionResultResponse[], assignmentQuestions: AssignmentQuestion[], loadingAssignmentQuestions: boolean, loadingUsersAnswers: boolean}}
   */
  state = {
    loadingQuestionAnswers: true,
    isNewAnswer: !this.props.showPreviousAnswers,
    usersAnswers: [],
    usedHints: []
  }
  
  componentDidMount () {
    this.fetchUsersAnswers(this.props.question.id)
  }
  
  updateResponse (usersAnswers) {
    this.setState({ usersAnswers, isNewAnswer: false })
  }

  updateUsedHints = () => {
    if (this.state.usersAnswers && this.state.usersAnswers.isCorrect === false) {
      const hints = this.props.question.hints || []
      const unusedHint = hints.find(
        h => this.state.usedHints.findIndex(uh => h.id === uh.id) === -1
      )
      const usedHints = (typeof unusedHint === 'undefined')
        ? (hints.length > 0 ? [hints[0]] : [] )
        : [...this.state.usedHints, unusedHint]

      this.setState({ usedHints })
    }
  }
  
  fetchUsersAnswers = (questionId) => {
    userAnswerService.getByQuestionId(questionId)
      .then((response) =>
        this.setState({
          usersAnswers: ( new QuestionResultResponse({
            answers: this.props.showPreviousAnswers ? response.answers : [],
            questionId: response.questionId,
            isCorrect: this.props.showPreviousAnswers ? response.isCorrect : null,
          })),
          loadingQuestionAnswers: false
        })
      )
      .catch(error => {
        alertService.error('Something went wrong while loading submitted answers.')
      })
  }
  
  // TODO: refactor
  handleAnswerSelect = (params, callback) => {
    const userSelectedAnswer = {} // TODO: get from userAnswers
    if (userSelectedAnswer.id !== undefined && userSelectedAnswer.confirmed === true) {
      return false
    }
    
    userAnswerService.select(params)
      .then((response) => this.updateResponse(response ? new QuestionResultResponse(response) : []))
      .catch(error => {
        alertService.error('There occurred an error selecting an answer.') // TODO
      })
      .finally(() => callback())
  }
  
  // TODO: refactor
  handleAnswerSubmit = (params, callback) => {
    const userSelectedAnswer = {} // TODO: get from userAnswers
    if (userSelectedAnswer.id !== undefined && userSelectedAnswer.confirmed === true) {
      return false
    }

    params.isNewAnswer = this.state.isNewAnswer
    userAnswerService.submit(params)
      .then((response) => this.updateResponse(response ? new QuestionResultResponse(response) : []))
      .catch(error => {
        alertService.error('There occurred an error submitting an answer.') // TODO
      })
      .finally(() => callback())
  }
  
  // TODO: refactor
  handleAnswerRetry = (params, callback) => {
    userAnswerService.retry(params)
      .then((response) => this.updateResponse(response ? new QuestionResultResponse(response) : []))
      .then(() => callback())
      .catch(error => {
        alertService.error('There occurred an error trying to re-vote.') // TODO
      })
  }
  
  handleAnswerConfirm = (params, callback) => {
    params = { ...params }
    userAnswerService.confirm(params)
      .then((response) => this.updateResponse(response ? new QuestionResultResponse(response) : []))
      .then(() => callback())
      .catch(error => {
        alertService.error('There occurred an error confirming an answer.') // TODO
      })
  }
  
  render () {
    const { question } = this.props
    const currentUser = new User(accountService.userValue)
    
    return (
      <div className="assignment-item row no-gutters">
        <div className='col-1'><QuestionCategoryLogo backgroundColor={this.props.question.category.themeColor}
                                                     icon={this.props.question.category.themeLogo}/></div>
        <div className='col-10'>
          <QuestionListItemBody
            isExpanded={!this.props.collapsed}
            user={currentUser}
            question={question}
            questionFlagCategories={this.props.questionFlagCategories}
            handleTopicBadgeClick={this.props.handleTopicBadgeClick}
          />
        </div>
        <div className='col-1'>
          <CollapseButton
            handleClick={() => this.props.handleToggleCollapsed(this.props.question)}
            isExpanded={!this.props.collapsed}
            collapsedIcon={<FaPlus size='25px'/>}
            expandedIcon={<FaMinus size='25px'/>}
            wrapperClass='h-100 text-center py-5 bg-white'
          />
        </div>
        
        <Collapse in={!this.props.collapsed}>
          <div className='mt-2 width-100'>
            <div className='row'>
              {(question && !this.state.loadingQuestionAnswers)
              && <ListItem
                question={question}
                hint={[...this.state.usedHints].pop() || null}
                user={currentUser}
                handleAnswerConfirm={this.handleAnswerConfirm}
                handleAnswerRetry={this.handleAnswerRetry}
                handleAnswerSelect={this.handleAnswerSelect}
                handleAnswerSubmit={this.handleAnswerSubmit}
                updateUsedHints={this.updateUsedHints}
                questionAnswerResultResponse={this.state.usersAnswers}
                questionFlagCategories={this.props.questionFlagCategories}
              />}
            </div>
          </div>
        </Collapse>
      </div>
    )
  }
}

QuestionListItem.propTypes = {
  question: PropTypes.instanceOf(Question).isRequired,
  questionFlagCategories: PropTypes.array,
  collapsed: PropTypes.bool.isRequired,
  handleToggleCollapsed: PropTypes.func.isRequired,
  showPreviousAnswers: PropTypes.bool,
  usersAnswersNeedUpdate: PropTypes.bool,
  tz: PropTypes.string,
}

export default QuestionListItem
