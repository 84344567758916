import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ListItem from './ListItem'
import AssignmentQuestion from '../../../_models/AssignmentQuestion'
import User from '../../../_models/User'
import AssignmentQuestionResultResponse from '../../../_models/AssignmentQuestionResultResponse'

class ListItems extends Component {

  render () {
    return (
      <div className="assignments-list">
        <ul className='list-unstyled'>
          {this.props.assignmentQuestions.map((assignmentQuestion, index) => {
            const isSelected = this.props.selectedAssignmentQuestion && this.props.selectedAssignmentQuestion.id === assignmentQuestion.id
            
            return (
              <li key={assignmentQuestion.id}
                  className={(isSelected || !this.props.selectedAssignmentQuestion) ? '' : ' d-none'}>
                <ListItem
                  assignment={this.props.assignment}
                  assignmentQuestion={assignmentQuestion}
                  assignmentQuestionAnswerResultResponse={this.props.usersAnswers
                    .filter(response => response.assignmentQuestionId === assignmentQuestion.id)[0]}
                  assignmentQuestionNum={index}
                  assignmentQuestionsTotalCount={this.props.assignmentQuestions.length}
                  groupId={this.props.groupId}
                  handleAnswerConfirm={this.props.handleAnswerConfirm}
                  handleAnswerRetry={this.props.handleAnswerRetry}
                  handleAnswerSelect={this.props.handleAnswerSelect}
                  handleAnswerSubmit={this.props.handleAnswerSubmit}
                  handleNavButtonClick={this.props.handleNavButtonClick}
                  afterAnswerConfirm={this.props.afterAnswerConfirm}
                  questionFlagCategories={this.props.questionFlagCategories}
                  user={this.props.user}
                />
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
}

ListItems.propTypes = {
  assignmentQuestions: PropTypes.arrayOf(PropTypes.shape(new AssignmentQuestion())).isRequired,
  groupId: PropTypes.number,
  handleAnswerRetry: PropTypes.func.isRequired,
  handleAnswerSelect: PropTypes.func.isRequired,
  handleAnswerSubmit: PropTypes.func.isRequired,
  handleAnswerConfirm: PropTypes.func.isRequired,
  afterAnswerConfirm: PropTypes.func.isRequired,
  handleNavButtonClick: PropTypes.func.isRequired,
  selectedAssignmentQuestion: PropTypes.instanceOf(AssignmentQuestion),
  user: PropTypes.instanceOf(User).isRequired,
  usersAnswers: PropTypes.arrayOf(PropTypes.shape(new AssignmentQuestionResultResponse())),
}

export default ListItems
