import { fetchWrapper } from '../_helpers'

const apiUrl = process.env.REACT_APP_API_URL
const baseUrl = `${apiUrl}/api/v1/topics`

export const topicService = {
  search,
  index
}

function search (params) {
  return fetchWrapper.post(`${baseUrl}/search`, params)
}

function index () {
  return fetchWrapper.get(`${baseUrl}/index`)
}