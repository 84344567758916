import React, { Component } from 'react'
import CategoryBarItem from './CategoryBarItem'
import PropTypes from 'prop-types'

class CategoryBar extends Component {
  state = {
    category: []
  }

  selectCategory = (selectedCategory) => {
    const { onSearch } = this.props
    const currentCategories = this.props.searchParams.category
    const category = (currentCategories.filter((i) => {return i === selectedCategory}).length > 0)
      ? currentCategories.filter((i) => {return i !== selectedCategory})
      : [...currentCategories, selectedCategory]
    this.props.updateSearchParams({category: category, page: 1}, function () {onSearch()})
  }

  generateBackgroundColor = (item) => {
    const { category } = this.props.searchParams

    return (category.length < 1 || category.filter((i) => {return i === item.id}).length > 0)
      ? item.color
      : '#696969'
  }

  getTooltipText = (selectedCategory) => {
    const { category } = this.props.searchParams
    let result = ''
    if (category.length > 0) {
      if (category.filter((i) => i === selectedCategory.id).length > 0) {
        if (category.length === 1) {
          return 'Click to show questions from all subjects.'
        } else {
          result = 'Click to hide questions in subject: '
        }
      } else {
        result = 'Click to also show questions in subject: '
      }
    } else {
      result = 'Click to only show questions in subject: '
    }

    return result + selectedCategory.title
  }

  render () {
    const { categories } = this.props

    return (
      <div className="row">
        {categories.map(item => {
          return (
            <div className="ml-3" key={'category_item_' + item.id} onClick={() => this.selectCategory(item.id)}
                 data-tip={this.getTooltipText(item)}>
              <CategoryBarItem backgroundColor={this.generateBackgroundColor(item)} icon={item.logo} id={item.id}
                               title={item.title}/>
            </div>
          )
        })}
      </div>
    )
  }
}

CategoryBar.propTypes = {
  categories: PropTypes.any,
  searchParams: PropTypes.any,
  onSearch: PropTypes.any,
  updateSearchParams: PropTypes.any,
}

export default CategoryBar