/**
 * @property {number} id
 * @property {string} avatar
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} username
 */
class User {
  constructor (obj) {
    Object.assign(this, obj)
  }
}

export default User
