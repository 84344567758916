import React, { Component, createRef } from 'react'
import BackgroundSlider from './BackgroundSlider'
import InfoPanel from './InfoPanel'
import {
  accountService,
  alertService,
  carouselItemService,
  questionFlagService,
  questionService
} from '../../_services'
import Echo from 'laravel-echo'
import pusher from 'pusher-js'
import Explore from './Explore'
import _ from 'lodash'

class ExploreSection extends Component {
  tz = Intl.DateTimeFormat().resolvedOptions().timeZone
  state = {
    activeCarouselItemIndex: 0,
    carouselItems: [],
    categories: [],
    isLastPage: false,
    loadingQuestions: true,
    questionFlagCategories: [],
    questions: [],
    searchParams: {
      includeAnswered: false,
      showPreviousAnswers: false,
      query: '',
      difficulties: [],
      topics: [],
      category: [],
      page: 1,
    },
    topicOptions: [],
    topicBadges: []
  }
  echoService = new Echo({
    broadcaster: 'pusher',
    key: process.env.REACT_APP_PUSHER_KEY,
    cluster: process.env.REACT_APP_PUSHER_CLUSTER,
    forceTLS: process.env.REACT_APP_PUSHER_FORCETLS,
    encrypted: process.env.REACT_APP_PUSHER_FORCETLS === 'true',
    wsHost: process.env.REACT_APP_PUSHER_HOST,
    wsPort: process.env.REACT_APP_PUSHER_WS_PORT,
    wssPort: process.env.REACT_APP_PUSHER_WSS_PORT,
    disableStats: true,
    enabledTransports: ['ws', 'wss'],
    authEndpoint: process.env.REACT_APP_PUSHER_AUTH_ENDPOINT,
    auth: {
      headers: {
        Authorization: `Bearer ${accountService.userValue.jwtToken}`,
        Accept: 'application/json'
      },
    },
  })

  constructor (props) {
    super(props)
    this.topicSearchRef = createRef()
  }

  componentDidMount () {
    // TODO: Use API and get public questions
    carouselItemService.getAll().then((res) => this.initCarousel(res)).catch(error => {
      alertService.error('Something went wrong while loading carouselItems.')
    })
    questionService.getAllQuestionCategories().then((res) => {
      this.setState({ categories: res })
    }).catch(error => {
      alertService.error('Something went wrong while loading question categories.')
    })
    this.setState({ loadingQuestions: true })
    questionService.search(this.state.searchParams).then((res) => {
      this.setState({
        questions: res.questions,
        loadingQuestions: false,
        isLastPage: res.isLastPage,
        topicOptions: res.relatedTopics
      })
    }).catch(error => {
      alertService.error('Something went wrong while loading questions.')
      this.setState({ loadingQuestions: false })
    })
    questionFlagService.getCategories().then(r => {
      this.setState({ questionFlagCategories: r })
    }).catch(e => {
      alertService.error('Something went wrong while loading question flag categories.')
    })
  }

  initCarousel = (carouselItems) => {
    this.setState({ carouselItems })
    this.transitionSlides(true)
    this.carouselInterval = setInterval(() => {this.transitionSlides()}, 120 * 1000)
  }

  transitionSlides = (isInitial = false) => {
    const activeCarouselItemIndex = this.state.activeCarouselItemIndex
    const nextCarouselItemIndex = (!isInitial && this.state.carouselItems[activeCarouselItemIndex + 1])
      ? (activeCarouselItemIndex + 1)
      : 0

    this.setState({ activeCarouselItemIndex: nextCarouselItemIndex })
  }

  handleTopicBadgeClick = (input) => {
    // TODO: use searchParams update
    // let topics = [...this.state.topicBadges]
    // input = this.formatTopicBadge(input)
    // if (_.indexOf(topics, input) === -1) {
    //   topics.push(input)
    // }
    // this.setState({ topicBadges: topics })
    // if (this.topicSearchRef.current) {
    //   // search is triggered by select component after this change
    //   this.topicSearchRef.current.select.select.setValue(topics)
    // }
  }

  formatTopics = (input) => {
    let topics = []
    if (input && _.isArray(input) && input.length > 0) {
      input.forEach((value) => {
        topics.push(value.value)
      })
    }

    return topics
  }

  formatDifficulties = (input) => {
    if (input) {
      return input.map(item => {
        return item.value
      })
    }
  }

  updateSearchParams = (params, callback) => {
    const searchParams = { ...this.state.searchParams, ...params }
    this.setState({ searchParams }, callback)
  }

  onQuestionSearch = (append = false) => {
    const searchParams = {
      ...this.state.searchParams,
      topics: this.formatTopics(this.state.searchParams.topics),
      difficulties: this.formatDifficulties(this.state.searchParams.difficulties)
    }
    this.setState({ loadingQuestions: true })
    questionService.search(searchParams).then((res) => {
      let questions = res.questions
      if (append) {
        questions = [...this.state.questions, ...questions]
      }

      if (searchParams.page > 1 && res.questions.length < 1) {
        // revert page if there are no results
        searchParams.page = searchParams.page - 1
      }

      this.setState({
        questions,
        loadingQuestions: false,
        isLastPage: res.isLastPage,
        topicOptions: res.relatedTopics
      })
    }).catch(error => {
      alertService.error('Something went wrong while loading questions.')
      this.setState({ loadingQuestions: false })
    })
  }

  componentWillUnmount () {
    clearInterval(this.carouselInterval)
  }

  render () {
    return (
      <div className="" style={{ height: 'calc(100% - 72px)' }}>
        <BackgroundSlider slideItem={this.state.carouselItems[this.state.activeCarouselItemIndex] || null}>
          {this.state.questions &&
          <Explore
            categories={this.state.categories}
            handleTopicBadgeClick={this.handleTopicBadgeClick}
            loadingQuestions={this.state.loadingQuestions}
            isLastPage={this.state.isLastPage}
            onSearch={this.onQuestionSearch}
            questionFlagCategories={this.state.questionFlagCategories}
            questions={this.state.questions}
            searchParams={this.state.searchParams}
            topicOptions={this.state.topicOptions}
            topicSearchRef={this.topicSearchRef}
            updateSearchParams={this.updateSearchParams}
          />}
        </BackgroundSlider>
        <InfoPanel slideItem={this.state.carouselItems[this.state.activeCarouselItemIndex] || null} tz={this.tz}
                   echo={this.echoService}/>
      </div>
    )
  }
}

export { ExploreSection }
