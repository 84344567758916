import React from 'react'
import ArrowButton from '../../common/ArrowButton'
import { ErrorMessage, Field } from 'formik'
import AvatarSelector from '../../profile/AvatarSelector'

class Step3 extends React.Component {
  render () {
    const { step, onPreviousStep, maxStep, errors, touched, setFieldValue, avatar } = this.props
    
    if (this.props.step !== 3) {
      return null
    }
    
    return (
      <React.Fragment>
        <div className="form-row">
          <div className="form-group col-12">
            <label htmlFor="avatar" className="form-check-label">Choose avatar</label>
            <Field as={AvatarSelector} name="avatar" id="avatar" avatar={avatar} config='narrow' setFieldValue={setFieldValue}/>
          </div>
        </div>
        <div className="form-group form-check">
          <Field type="checkbox" name="acceptTerms" id="acceptTerms"
                 className={'form-check-input ' + (errors.acceptTerms && touched.acceptTerms ? ' is-invalid' : '')}/>
          <label htmlFor="acceptTerms" className="form-check-label">Accept Terms & Conditions</label>
          <ErrorMessage name="acceptTerms" component="div" className="invalid-feedback"/>
        </div>
        <hr className="hr-sp"/>
        <div className="form-row col">
          <div className="col-12 ml-auto">
            <div className="form-row">
              <ArrowButton direction="left" classes="ml-auto" onClick={onPreviousStep}/>
              <button type="submit" className="btn-no-style">
                <ArrowButton direction="tick"/>
              </button>
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="col-3 ml-auto">
            <span className="font-italic font-sp">{step} / {maxStep}</span>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default Step3
