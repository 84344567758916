import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FaMinus, FaPlus } from 'react-icons/fa'
import CollapseButton from './CollapseButton'
import { Collapse } from 'react-bootstrap'
import AvatarImage from './AvatarImage'

class AvatarOverflow extends Component {
  state = {
    overflowVisible: false
  }
  
  handleCollapse = () => {
    this.setState({ overflowVisible: !this.state.overflowVisible })
  }
  
  render () {
    return (
      <>
        <div className={'sp-assignment-avatar-group-overflow'}>
          <CollapseButton
            handleClick={this.handleCollapse}
            isExpanded={this.state.overflowVisible}
            noPadding={true}
            collapsedIcon={<FaPlus size="20px"/>}
            expandedIcon={<FaMinus size="20px"/>}
            wrapperClass="h-100 text-center"
          />
        </div>
        <Collapse in={this.state.overflowVisible}>
          <div className="w-100">
            <div className="row col">
              {this.props.users.map((elem, index) => {
                return <AvatarImage key={'assignment-avatar_overflow_' + index} url={elem.avatar} hover={true}
                                    hoverTarget={'avatar-tip-component'}
                                    hoverText={elem.firstName + ' ' + elem.lastName}/>
              })}
            </div>
          
          </div>
        </Collapse>
      </>
    )
  }
}

AvatarOverflow.propTypes = {
  users: PropTypes.array
}

export default AvatarOverflow
