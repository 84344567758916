import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FaLink } from 'react-icons/all'
import ReactHtmlParser from 'react-html-parser'
import { MathJax } from 'better-react-mathjax'

class EscapeLinesListItemBodyDetails extends Component {
  render () {
    const html = this.props.escapeLine.body
    return (
      <>
        {this.props.escapeLine.body
        && <div className={'assignment-item__section__content'}>
          <MathJax>{ReactHtmlParser(html)}</MathJax>
        </div>}
        {this.props.escapeLine.url &&
        <div className="float-right">
          <a href={this.props.escapeLine.url} target="_blank" rel="noreferrer"><FaLink size='24px'/></a>
        </div>
        }
      </>
    )
  }
}

EscapeLinesListItemBodyDetails.propTypes = {
  escapeLine: PropTypes.object.isRequired,
}

export default EscapeLinesListItemBodyDetails
