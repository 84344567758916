import React, { Component } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import { accountService, carouselItemService } from '../../_services'

import { Login } from './Login'
import { Register } from './Register'
import { VerifyEmail } from './VerifyEmail'
import { ForgotPassword } from './ForgotPassword'
import { ResetPassword } from './ResetPassword'
import Footer from '../common/Footer'
import BackgroundSlider from '../assignment/BackgroundSlider'
import bubbleImg from '../../resources/img/landing/bubble.png'

class Account extends Component {
  
  carouselItems = []
  
  state = {
    activeCarouselItemIndex: 0
  }
  
  componentDidMount () {
    carouselItemService.getAll().then((res) => this.initCarousel(res))
  }
  
  initCarousel = (carouselItems) => {
    this.carouselItems = carouselItems
    this.transitionSlides(true)
    this.carouselInterval = setInterval(() => {
      this.transitionSlides()
    }, 120 * 1000)
  }
  
  transitionSlides = (isInitial = false) => {
    const activeCarouselItemIndex = this.state.activeCarouselItemIndex
    const nextCarouselItemIndex = (!isInitial && this.carouselItems[activeCarouselItemIndex + 1])
      ? (activeCarouselItemIndex + 1)
      : 0
    
    this.setState({ activeCarouselItemIndex: nextCarouselItemIndex })
  }
  
  componentWillUnmount () {
    clearInterval(this.carouselInterval)
  }
  
  render () {
    if (accountService.userValue) {
      return <Redirect to="/"/>
    } else {
      // TODO: Make greeting and info text dynamic?
      return (
        <>
          <BackgroundSlider slideItem={this.carouselItems[this.state.activeCarouselItemIndex] || null}>
            <div className="container padding-bottom-x2-large">
              <div className="row">
                <div className="col-md-6 col-lg-5 sp-landing-container font-white offset-md-0 offset-lg-2">
                  <h1 className="font-size-50 font-weight-semibold">Welcome to the Study Bubble!</h1>
                  <p>Login to your own bubble, and study.</p>
                  <div>
                    <img src={bubbleImg} alt={'Bubble'} className="img-fluid mb-3"/>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-5 sp-landing-container">
                  <div className="m-3 background-white">
                    <Switch>
                      <Route path={`/account/login`} component={Login}/>
                      <Route path={`/account/register`} component={Register}/>
                      <Route path={`/account/verify-email`} component={VerifyEmail}/>
                      <Route path={`/account/forgot-password`} component={ForgotPassword}/>
                      <Route path={`/account/reset-password`} component={ResetPassword}/>
                    </Switch>
                  </div>
                </div>
              </div>
            </div>
          </BackgroundSlider>
          <Footer/>
        </>
      )
    }
    
  }
}

export { Account }
