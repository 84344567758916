import React from 'react'
import * as PropTypes from 'prop-types'
import Icon from './Icon'

class Item extends React.Component {
  render () {
    return (
      <div className="row">
        <div className="col">
          <div className="font-white font-size-40 text-bold text-center mb-3">{this.props.rating}</div>
          <div className="font-white font-size-16 text-center mt-n4 mb-3">{this.props.label} {this.props.iconClass &&
          <Icon iconClass={this.props.iconClass}/>}</div>
        </div>
      </div>
    )
  }
}

Item.propTypes = {
  iconClass: PropTypes.string,
  label: PropTypes.string.isRequired,
  rating: PropTypes.number.isRequired,
}

export default Item
