import { fetchWrapper } from '../_helpers'

const apiUrl = process.env.REACT_APP_API_URL
const baseUrl = `${apiUrl}/api/v1/user-answers`

export const userAnswerService = {
  getByAssignmentQuestionId,
  getByAssignmentId,
  getByQuestionId,
  confirm,
  retry,
  select,
  submit
}

function getByAssignmentQuestionId (id) {
  return fetchWrapper.get(`${apiUrl}/api/v1/assignment-questions/${id}/users-answers`)
}

function getByAssignmentId (id) {
  return fetchWrapper.get(`${apiUrl}/api/v1/assignments/${id}/users-answers`)
}

function getByQuestionId (id) {
  return fetchWrapper.get(`${baseUrl}/question/${id}`)
}

function retry (params) {
  return fetchWrapper.post(`${baseUrl}/retry`, params)
}

function select (params) {
  return fetchWrapper.post(`${baseUrl}/select`, params)
}

function submit (params) {
  return fetchWrapper.post(`${baseUrl}/submit`, params)
}

function confirm (params) {
  return fetchWrapper.post(`${baseUrl}/confirm`, params)
}
