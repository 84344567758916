import { fetchWrapper } from '../_helpers';
const apiUrl =  process.env.REACT_APP_API_URL;
const baseUrl = `${apiUrl}/api/v1/notifications`;

export const notificationService = {
    getRecent,
};

function getRecent() {
    return fetchWrapper.get(`${baseUrl}`);
}
