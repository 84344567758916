import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Answers from '../answers/Index'
import EscapeLines from '../escapeLines/Index'
import User from '../../../_models/User'
import QuestionAnswer from '../../../_models/QuestionAnswer'
import Question from '../../../_models/Question'
import QuestionResultResponse from '../../../_models/QuestionResultResponse'

class ListItem extends Component {
  render () {
    
    return (
      <div className="questions-list-item col-11 offset-1">
        <Answers answers={this.props.question.answers.map(el => new QuestionAnswer(el))}
                 question={this.props.question}
                 hint={this.props.hint}
                 handleAnswerSelect={this.props.handleAnswerSelect}
                 handleAnswerSubmit={this.props.handleAnswerSubmit}
                 handleAnswerConfirm={this.props.handleAnswerConfirm}
                 updateUsedHints={this.props.updateUsedHints}
                 questionResultResponse={this.props.questionAnswerResultResponse}
                 user={this.props.user}
                 handleAnswerRetry={this.props.handleAnswerRetry}/>
        <EscapeLines question={this.props.question}/>
      </div>
    )
  }
}

ListItem.propTypes = {
  question: PropTypes.instanceOf(Question).isRequired,
  questionFlagCategories: PropTypes.array,
  hint: PropTypes.object,
  handleAnswerRetry: PropTypes.func.isRequired,
  handleAnswerSelect: PropTypes.func.isRequired,
  handleAnswerSubmit: PropTypes.func.isRequired,
  updateUsedHints: PropTypes.func.isRequired,
  handleAnswerConfirm: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(User).isRequired,
  questionAnswerResultResponse: PropTypes.instanceOf(QuestionResultResponse),
}

export default ListItem
