import React from 'react'
import ArrowButton from '../../common/ArrowButton'
import { ErrorMessage, Field } from 'formik'

class Step2 extends React.Component {
  render () {
    const { step, onNextStep, onPreviousStep, maxStep, errors, touched } = this.props
    
    if (this.props.step !== 2) {
      return null
    }
    
    return (
      <React.Fragment>
        <div className="form-row margin-bottom-x-small">
          <div className="col-12">
            <Field name="username" type="text" placeholder="Username..."
                   className={'width-100 font-white placeholder-white placeholder-italic background-sp border-sp' + (errors.username && touched.username ? ' is-invalid' : '')}/>
            <ErrorMessage name="username" component="div" className="invalid-feedback"/>
          </div>
        </div>
        <div className="form-row margin-bottom-x-small">
          <div className="col-12">
            <Field name="password" type="password" placeholder="Password..."
                   className={'width-100 font-white placeholder-white placeholder-italic background-sp border-sp ' + (errors.password && touched.password ? ' is-invalid' : '')}/>
            <ErrorMessage name="password" component="div" className="invalid-feedback"/>
          </div>
        </div>
        <div className="form-row">
          <div className="col-12">
            <Field name="confirmPassword" type="password" placeholder="Confirm Password..."
                   className={'width-100 font-white placeholder-white placeholder-italic background-sp border-sp ' + (errors.confirmPassword && touched.confirmPassword ? ' is-invalid' : '')}/>
            <ErrorMessage name="confirmPassword" component="div" className="invalid-feedback"/>
          </div>
        </div>
        <hr className="hr-sp"/>
        <div className="form-row col">
          <div className="col-12 ml-auto">
            <div className="form-row">
              <ArrowButton direction="left" classes="ml-auto" onClick={onPreviousStep}/>
              <ArrowButton direction="right"
                           classes="ml-2"
                           onClick={(!(errors.confirmPassword || errors.password || errors.username) && (touched.confirmPassword && touched.password && touched.username))
                             ? onNextStep
                             : () => {}} />
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="col-3 ml-auto">
            <span className="font-italic font-sp">{step} / {maxStep}</span>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default Step2
