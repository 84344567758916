import React, { Component } from 'react'
import Slider from '@material-ui/core/Slider'
import moment from 'moment-timezone'
import createMuiTheme from '@material-ui/core/styles/createMuiTheme'
import { ThemeProvider } from '@material-ui/styles'
import { FaCalendar } from 'react-icons/all'
import * as PropTypes from 'prop-types'

class Timeline extends Component {
  timelineYearStart = (new Date(this.props.year, 0, 1, 0, 0, 0, 0)).getTime() / 1000
  timelineYearEnd = (new Date(this.props.year, 11, 31, 23, 59, 59, 0)).getTime() / 1000
  
  getDragLabelFormattedDate = (value) => {
    return moment.unix(value).format('DD.MM')
  }
  
  getEdgeLabelFormattedDate = (value) => {
    const timestamp = moment.unix(value)
    const toSingleUnderlined = (value) => {
      const elements = value.split('').map(str => <span className="text-decoration-underline">{str}</span>)
      
      return <>{elements[0]} {elements[1]}</>
    }
    
    return <>{toSingleUnderlined(timestamp.format('DD'))} / {toSingleUnderlined(timestamp.format('MM'))}</>
  }
  
  componentDidMount () {
    const values = this.props.values.length > 1
      ? this.props.values
      : [this.timelineYearStart, this.timelineYearEnd]
    this.props.onChangeCommitted(null, values)
  }
  
  componentDidUpdate (prevProps, prevState, snapshot) {
    const timelineSliderTrack = document.getElementsByClassName('MuiSlider-track')[0]
    if (typeof timelineSliderTrack !== 'undefined') {
      const testsCount = this.props.tests.reduce((count, test) => parseInt(count)
        + test.perfect.length
        + test.withHints.length
        + test.failed.length, 0)
      timelineSliderTrack.innerHTML = testsCount + ' Tests done'
    }
  }
  
  render () {
    const TimelineSlider = createMuiTheme({
      // TODO: use relevant styles from awards.scss here?
      overrides: {
        MuiSlider: {
          root: {
            color: '#ffffff',
          },
          thumb: {
            backgroundColor: '#ffffff',
            border: '2px solid currentColor',
          },
          valueLabel: {
            left: 'calc(-50% - 6px)',
            top: 25,
            '& *': {
              background: 'transparent',
              fontSize: 14,
              fontWeight: 'bold',
              color: '#ffffff',
            },
          },
        }
      }
    })
    
    return (
      <div className="row">
        <div className="col">
          <div className="row text-center">
            <div className="col-2">
              <FaCalendar className="font-white" size={'24px'}/>
              <p className="font-white font-italic font-size-20">Time Period</p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-auto">
              <p
                className="font-white font-size-14 font-weight-bold font-kerning-medium font-italic">{this.getEdgeLabelFormattedDate(this.props.values[0])}</p>
            </div>
            <div className="col">
              <ThemeProvider theme={TimelineSlider}>
                <Slider
                  value={this.props.values}
                  min={this.timelineYearStart}
                  max={this.timelineYearEnd}
                  step={24 * 60 * 60}
                  onChangeCommitted={(event, values) => this.props.onChangeCommitted(event, values)}
                  onChange={(event, values) => this.props.onChange(event, values)}
                  valueLabelDisplay="on"
                  aria-labelledby="range-slider"
                  getAriaValueText={this.getDragLabelFormattedDate}
                  valueLabelFormat={this.getDragLabelFormattedDate}
                />
              </ThemeProvider>
            </div>
            <div className="col-12 col-sm-auto mt-sm-0 mt-4">
              <p
                className="font-white font-size-14 font-weight-bold font-kerning-medium font-italic">{this.getEdgeLabelFormattedDate(this.props.values[1])}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Timeline.propTypes = {
  onChange: PropTypes.func.isRequired,
  onChangeCommitted: PropTypes.func.isRequired,
  tests: PropTypes.array.isRequired,
  values: PropTypes.array.isRequired,
  year: PropTypes.number.isRequired,
}

export default Timeline
