import { fetchWrapper } from '../_helpers'

const apiUrl = process.env.REACT_APP_API_URL
const baseUrl = `${apiUrl}/api/v1/questions`

export const questionService = {
  getAllPublicQuestions,
  getAllQuestionCategories,
  search
}

function getAllQuestionCategories () {
  return fetchWrapper.get(`${apiUrl}/api/v1/questionCategories/index`)
}

function getAllPublicQuestions () {
  return fetchWrapper.get(`${baseUrl}/public`)
}

function search (params) {
  return fetchWrapper.post(`${baseUrl}/exploreSearch`, params)
}
