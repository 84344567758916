import React, { Component } from 'react'
import PropTypes from 'prop-types'

class QuestionStatus extends Component {
  render () {
    return (
      <div className="d-table mx-auto">
        <div className="assignment-item__question__status d-flex flex-row no-gutters px-2 justify-content-center">
          <div className={''} key='0'>
            <span>Votes needed: {this.props.votesNeeded}</span>
          </div>
        </div>
      </div>
    )
  }
}

QuestionStatus.propTypes = {
  votesNeeded: PropTypes.number.isRequired,
}

export default QuestionStatus
