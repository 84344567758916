import React, { Component } from 'react'
import PropTypes from 'prop-types'

class EscapeLinesListItemBody extends Component {
  render () {
    return (
      <div className="assignment-item-body row no-gutters h-100 mr-2 p-3 bg-white">
        <div className='col-12'>
          <div className='assignment-item__title'>{this.props.escapeLine.title}</div>
          <div className='assignment-item__questions-status'>{this.props.children}</div>
        </div>
      </div>
    )
  }
}

EscapeLinesListItemBody.propTypes = {
  escapeLine: PropTypes.object.isRequired,
}

export default EscapeLinesListItemBody
