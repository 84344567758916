import React from 'react'
import * as PropTypes from 'prop-types'
import { Collapse } from 'react-bootstrap'

class AchievementItem extends React.Component {
  render () {
    const { description, thumbnail, title, index } = this.props
    const id = 'achievement-body-'+ index
    return (
      <div className="card flex-row flex-wrap rounded-0 border-1 mr-1 mb-1">
        <div className="card-header p-0 collapse-btn" onClick={() => this.props.handleClick(index)}>
          <img src={thumbnail} className="card-img rounded-0" alt="Achievement Logo"/>
        </div>
  
        <Collapse in={this.props.expanded}>
          <div className="card-block px-3 py-2 collapse" id={id}>
            <h4 className="card-title assignment-item__title mb-0">{title}</h4>
            <p className="card-text assignment-item__question_body">{description}</p>
          </div>
        </Collapse>
      </div>
    )
  }
}

AchievementItem.propTypes = {
  description: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  thumbnail: PropTypes.string.isRequired,
  title: PropTypes.any.isRequired
}

export default AchievementItem
