import React, { Component } from 'react'
import {carouselItemService } from '../../_services'
import BackgroundSlider from '../assignment/BackgroundSlider'
import InfoPanel from '../assignment/InfoPanel'

class ScienceCarousel extends Component {
  carouselItems = []
  
  state = {
    activeCarouselItemIndex: 0,
  }
  
  componentDidMount () {
    carouselItemService.getAll().then((res) => this.initCarousel(res)).catch(error => {
      console.log(error)
    });
  }
  
  initCarousel = (carouselItems) => {
    this.carouselItems = carouselItems
    this.transitionSlides(true)
    this.carouselInterval = setInterval(() => {this.transitionSlides()}, this.props.timeout ? this.props.timeout : 120 * 1000)
  }
  
  transitionSlides = (isInitial = false) => {
    const activeCarouselItemIndex = this.state.activeCarouselItemIndex
    const nextCarouselItemIndex = (!isInitial && this.carouselItems[activeCarouselItemIndex + 1])
      ? (activeCarouselItemIndex + 1)
      : 0
    
    this.setState({ activeCarouselItemIndex: nextCarouselItemIndex })
  }
  
  componentWillUnmount () {
    clearInterval(this.carouselInterval)
  }
  
  render () {
    return (
      <div className="" style={{ height: 'calc(100% - 72px)' }}>
        <BackgroundSlider slideItem={this.carouselItems[this.state.activeCarouselItemIndex] || null}>
          {this.props.children}
          {this.props.infoPanel && <InfoPanel slideItem={this.carouselItems[this.state.activeCarouselItemIndex] || null}/>}
        </BackgroundSlider>
      </div>
    )
  }
}

export default ScienceCarousel