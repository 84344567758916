import React from 'react'
import * as PropTypes from 'prop-types'

class Icon extends React.Component {
  render () {
    return (
      <div className={this.props.iconClass + ' ml-2'}>
        <div/>
        <div/>
        <div/>
        <div/>
        <div/>
      </div>
    )
  }
}

Icon.propTypes = {
  iconClass: PropTypes.string,
}

export default Icon
