/**
 * @property {number} id
 * @property {string} title
 * @property {string} dueDate
 * @property {object} user
 * @property {number} [userGroupId]
 * @property {array} groupUsers
 * @property {array} categories
 * @property {string} category.title
 * @property {string} [category.themeColor]
 * @property {string} [category.themeLogo]
 */
class Assignment {
  constructor (obj) {
    Object.assign(this, obj)
  }
}

export default Assignment
