import React from 'react'
import * as PropTypes from 'prop-types'
import MedalOverviewListItem from './MedalOverviewListItem'

class MedalOverviewList extends React.Component {
  render () {
    let { bronze, gold, silver } = this.props
    return (
      <div id="medal-overview__big-medal-badges" className="row">
        <MedalOverviewListItem medalsCount={gold}
                               wrapperClass='col-12 col-sm-6 col-lg-4 mb-4 mb-lg-auto medal-container--large offset-sm-3 offset-lg-0 order-lg-2'
                               altText={'Medal Big Gold'} type='gold'/>
        <MedalOverviewListItem medalsCount={silver}
                               wrapperClass='col-12 col-sm-6 col-lg-4 mb-4 mt-lg-5 medal-container--large order-lg-1'
                               altText={'Medal Big Silver'} type='silver'/>
        <MedalOverviewListItem medalsCount={bronze}
                               wrapperClass='col-12 col-sm-6 col-lg-4 mb-4 mt-lg-5 medal-container--large order-lg-3'
                               altText={'Medal Big Bronze'} type='bronze'/>
      </div>
    )
  }
}

MedalOverviewList.propTypes = {
  bronze: PropTypes.number.isRequired,
  gold: PropTypes.number.isRequired,
  silver: PropTypes.number.isRequired,
}

MedalOverviewList.defaultProps = {
  bronze: 0,
  gold: 0,
  silver: 0
}

export default MedalOverviewList
