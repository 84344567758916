import { fetchWrapper } from '../_helpers';

const apiUrl =  process.env.REACT_APP_API_URL;
const baseUrl = `${apiUrl}/api/v1/carousel-items`;

export const carouselItemService = {
    getAll
};

function getAll() {
    return fetchWrapper.get(baseUrl);
}
