import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'

class InfoSlider extends Component {
  render () {
    return (
      <div className="info-slider row align-items-center">
        <div className="col-8 col-sm-9 col-md-9 col-lg-7">
          <h5 className="text-right font-sp info-slider_title">{this.props.slideItem.title}</h5>
          <a className="text-right font-sp mr-0 ml-auto info-slider_more-button d-table"
             data-for="main"
             data-tip={this.props.slideItem.description}
             data-iscapture="true"
          >{'Learn More'} </a>
          <ReactTooltip
            id="main"
            place='top'
            type='info'
            effect='float'
            multiline={true}
          />
        
        </div>
        <div className="col-4 col-sm-3 col-md-3 col-lg-5">
          <img src={this.props.slideItem.imageUrl} alt={'Slider'}
               className='img-fluid info-panel-picture d-block ml-auto'/>
        </div>
      </div>
    )
  }
}

InfoSlider.propTypes = {
  slideItem: PropTypes.object.isRequired,
}

export default InfoSlider
