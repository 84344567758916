import { fetchWrapper } from '../_helpers'

const apiUrl = process.env.REACT_APP_API_URL
const baseUrl = `${apiUrl}/api/v1/question-flags`

export const questionFlagService = {
  getCategories,
  create,
  delete: _delete,
}

function getCategories () {
  return fetchWrapper.get(`${baseUrl}/categories`)
}

function create (params) {
  return fetchWrapper.post(`${baseUrl}/create`, params)
}

// prefixed with underscore because 'delete' is a reserved word in javascript
function _delete (id) {
  return fetchWrapper.delete(`${baseUrl}/${id}`)
}