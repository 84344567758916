import React, { Component } from 'react'
import PropTypes from 'prop-types'
import AvatarImage from '../common/AvatarImage'
import AvatarOverflow from '../common/AvatarOverflow'

class AssignmentGroupAvatars extends Component {
  
  getListToRender = (userList) => {
    const { users } = this.props
    
    if (users.length <= 4) {
      return [users, []]
    }
    
    let newUserArray = []
    newUserArray.push(users.slice(0, 3))
    newUserArray.push(users.slice(3))
    
    return newUserArray
    
  }
  
  render () {
    const { users } = this.props
    const processedUsers = this.getListToRender(users)
    return (
      <>
        {processedUsers[0].map((elem, index) => {
          return <AvatarImage key={'assignment-avatar_' + index} url={elem.avatar} hover={true}
                              hoverTarget={'avatar-tip-component'}
                              hoverText={elem.firstName + ' ' + elem.lastName}/>
        })}
        {
          processedUsers[1].length > 0 ? <AvatarOverflow users={processedUsers[1]}/> : null
        }
      </>
    )
  }
}

AssignmentGroupAvatars.propTypes = {
  users: PropTypes.array
}

export default AssignmentGroupAvatars
