import React, { Component } from 'react'
import ExploreList from './ExploreList'
import ExploreSearch from './ExploreSearch'
import { FaAngleDown } from 'react-icons/fa/index'
import Spinner from '../common/Spinner'
import PropTypes from 'prop-types'

class Explore extends Component {
  formatOptions = input => {
    const items = []
    input.forEach(item => {
      items.push({ label: item.title, value: item.id })
    })

    return items
  }

  render () {
    const {
      questions,
      categories,
      onSearch,
      loadingQuestions,
      questionFlagCategories,
      topicOptions,
      handleTopicBadgeClick,
      searchParams,
      updateSearchParams,
      topicSearchRef
    } = this.props

    return (
      <div className="container assignments-list">
        <div className="row">
          <div className="col">
            {/* Recommendations go here */}
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="row">
              {/* Search, filter and sorting elements in here */}
              <ExploreSearch
                categories={categories}
                onSearch={onSearch}
                topicOptions={this.formatOptions(topicOptions)} // TODO: add grouping child topics by parent
                topicSearchRef={topicSearchRef}
                searchParams={searchParams}
                updateSearchParams={updateSearchParams}
              />
            </div>
            <div className="row">
              {loadingQuestions
                ? <Spinner/>
                : <ExploreList questions={questions}
                               questionFlagCategories={questionFlagCategories}
                               showPreviousAnswers={searchParams.showPreviousAnswers}
                               handleTopicBadgeClick={handleTopicBadgeClick}/>}
            </div>
            {!(this.props.isLastPage || loadingQuestions) &&
              <div className="row no-gutters">
                <div className="col-1 ml-auto h-100 py-3 bg-white cursor-pointer text-center"
                     onClick={() => {
                       updateSearchParams({ page: searchParams.page + 1 }, function () {onSearch(true)})
                     }}
                >
                  {/* Button to "load more" */}
                  <FaAngleDown size="40px" className="font-sp"/>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    )
  }
}

Explore.propTypes = {
  categories: PropTypes.any,
  handleTopicBadgeClick: PropTypes.any,
  loadingQuestions: PropTypes.any,
  isLastPage: PropTypes.any,
  onSearch: PropTypes.any,
  questions: PropTypes.any,
  questionFlagCategories: PropTypes.any,
  searchParams: PropTypes.any,
  topicOptions: PropTypes.any,
  topicSearchRef: PropTypes.any,
  updateSearchParams: PropTypes.any,
}

export default Explore
