import React from 'react'

const AvatarComposite = ({avatar, classes = ''}) => {
  return (
    <div className={'sp-avatar avatar-composite ' + classes}>
      {avatar.map((elem, index) => {
        return (
          <img key={index} src={elem.url} style={{zIndex: (index + 1)}} alt="Avatar" />
        )
      })}
    </div>
  )
}

export default AvatarComposite
