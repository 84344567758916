import { fetchWrapper } from '../_helpers';

const apiUrl =  process.env.REACT_APP_API_URL;
const baseUrl = `${apiUrl}/api/v1/avatars`;

export const avatarService = {
    getAll,
    getParts,
    getUserAvatar,
};

function getAll() {
    return fetchWrapper.get(baseUrl);
}

function getParts() {
    return fetchWrapper.get(`${baseUrl}/parts`);
}

function getUserAvatar() {
    return fetchWrapper.get(`${baseUrl}/user`);
}