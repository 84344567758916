import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CollapseButton from '../common/CollapseButton'
import Questions from './questions/Index'
import QuestionCategoryLogo from './QuestionCategoryLogo'
import AssignmentsListItemBody from './AssignmentsListItemBody'
import { Collapse } from 'react-bootstrap'
import { FaMinus, FaPlus } from 'react-icons/fa/index'
import { accountService, alertService, assignmentService, userAnswerService } from '../../_services'
import AssignmentQuestion from '../../_models/AssignmentQuestion'
import Assignment from '../../_models/Assignment'
import User from '../../_models/User'
import AssignmentQuestionResultResponse from '../../_models/AssignmentQuestionResultResponse'
import AssignmentFinishedDialogue from './AssignmentFinishedDialogue'

class AssignmentsListItem extends Component {
  /**
   *
   * @type {{usersAnswers: AssignmentQuestionResultResponse[], expanded: boolean, assignmentQuestions: AssignmentQuestion[], loadingAssignmentQuestions: boolean, loadingUsersAnswers: boolean}}
   */
  state = {
    expanded: false,
    assignmentQuestions: [],
    loadingAssignmentQuestions: true,
    loadingUsersAnswers: true,
    usersAnswers: [],
    showModal: false
  }
  
  fetchAssignmentQuestions = (assignmentId) => {
    assignmentService
      .getAssignmentQuestions(assignmentId)
      .then((assignmentQuestions) => this.setState({
        assignmentQuestions: assignmentQuestions,
        loadingUsersAnswers: false
      }))
      .catch(error => {
        alertService.error('Something went wrong while loading questions.')
      })
  }
  
  fetchUsersAnswers = (assignmentId) => {
    userAnswerService.getByAssignmentId(assignmentId)
      .then((response) => this.setState({
        usersAnswers: (response ? response.map(el => new AssignmentQuestionResultResponse(el)) : []),
        loadingAssignmentQuestions: false
      }))
      .catch(error => {
        alertService.error('Something went wrong while loading submitted answers.')
      })
  }
  
  componentDidMount () {
    this.fetchAssignmentQuestions(this.props.assignment.id)
    this.fetchUsersAnswers(this.props.assignment.id)
  }
  
  componentDidUpdate (prevProps, prevState, snapshot) {
    if ((prevProps.usersAnswersNeedUpdate !== this.props.usersAnswersNeedUpdate) && this.props.usersAnswersNeedUpdate) {
      this.fetchUsersAnswers(this.props.assignment.id)
      this.props.handleMarkUsersAnswersUpdated()
    }
  }
  
  updateResponse (usersAnswers) {
    this.setState({ usersAnswers })
    return usersAnswers
  }
  
  handleAnswerSelect = (params, callback) => {
    userAnswerService.select(params)
      .then((response) => this.updateResponse(response))
      .catch(error => {
        alertService.error('There occurred an error selecting an answer.')
      })
      .finally(() => callback())
  }
  
  handleAnswerSubmit = (params, callback) => {
    userAnswerService.submit(params)
      .then((response) => this.updateResponse(response))
      .catch(error => {
        alertService.error('There occurred an error submitting an answer.')
      })
      .finally(() => callback())
  }
  
  handleAnswerRetry = (params, callback) => {
    userAnswerService.retry(params)
      .then((response) => this.updateResponse(response))
      .then(() => callback())
      .catch(error => {
        alertService.error('There occurred an error trying to re-vote.')
      })
  }
  
  handleAnswerConfirm = (params, callback) => {
    params = { ...params }
    userAnswerService.confirm(params)
      .then((response) => this.updateResponse(response))
      .then((response) => callback(response))
      .catch(error => {
        alertService.error('There occurred an error confirming an answer.')
      })
  }
  
  afterAnswerConfirm = (usersAnswers) => {
    if (usersAnswers && usersAnswers.findIndex(ua => ua.isCorrect !== true) === -1) {
      //alertService.success('Congratulations! You have answered all the questions in this assignment.')
      this.setState({ showModal: true })
    }
  }
  
  // callback function to prevent modal from showing up during unexpected render
  noLongerShowModal = () => {
    this.setState({ showModal: false })
  }
  
  render () {
    const currentUser = new User(accountService.userValue)
    // const assignmentQuestions = _.shuffle(this.state.assignmentQuestions) // TODO: enable if/when needed by client
    const { assignmentQuestions } = this.state
    const questionsTotalCount = assignmentQuestions.length
    const answeredQuestionsTotalCount = assignmentQuestions.filter(
      (assignmentQuestion) => this.state.usersAnswers.filter(
        /**
         * @param {AssignmentQuestionResultResponse} response
         * @returns {boolean}
         */
        (response) => (response.assignmentQuestionId === assignmentQuestion.id && (null !== response.isCorrect))
      ).length > 0
    ).length
    
    const category = this.props.assignment.categories.length > 0 ? this.props.assignment.categories[0] : null
    
    return (
      <div className="assignment-item row no-gutters">
        <div className="col-1">{category && <QuestionCategoryLogo backgroundColor={category.themeColor}
                                                                  icon={category.themeLogo}/>}</div>
        <div className="col-10">
          <AssignmentsListItemBody
            assignment={this.props.assignment}
            questionsTotalCount={questionsTotalCount}
            answeredQuestionsTotalCount={answeredQuestionsTotalCount}
            tz={this.props.tz}/>
        </div>
        <div className="col-1">
          <CollapseButton
            handleClick={() => this.props.handleToggleCollapsed(this.props.assignment)}
            isExpanded={!this.props.collapsed}
            collapsedIcon={<FaPlus size="25px"/>}
            expandedIcon={<FaMinus size="25px"/>}
            wrapperClass="h-100 text-center py-5 bg-white"
          />
        </div>
        
        <Collapse in={!this.props.collapsed}>
          <div className="w-100">
            {!(this.state.loadingAssignmentQuestions || this.state.loadingUsersAnswers)
              && assignmentQuestions.length > 0
              && <Questions user={currentUser}
                            assignmentQuestions={assignmentQuestions.map(el => new AssignmentQuestion(el))}
                            groupId={this.props.assignment.userGroupId}
                            handleAnswerRetry={this.handleAnswerRetry}
                            handleAnswerSelect={this.handleAnswerSelect}
                            handleAnswerSubmit={this.handleAnswerSubmit}
                            handleAnswerConfirm={this.handleAnswerConfirm}
                            afterAnswerConfirm={this.afterAnswerConfirm}
                            usersAnswers={this.state.usersAnswers.map(el => new AssignmentQuestionResultResponse(el))}
                            questionFlagCategories={this.props.questionFlagCategories}/>}
          </div>
        </Collapse>
        <AssignmentFinishedDialogue showModal={this.state.showModal}
                                    successText={'Congratulations! You have answered all the questions in this assignment.'}
                                    stateCallback={this.noLongerShowModal}
        />
      </div>
    )
  }
}

AssignmentsListItem.propTypes = {
  assignment: PropTypes.instanceOf(Assignment).isRequired,
  collapsed: PropTypes.bool.isRequired,
  handleToggleCollapsed: PropTypes.func.isRequired,
  handleMarkUsersAnswersUpdated: PropTypes.func.isRequired,
  usersAnswersNeedUpdate: PropTypes.bool,
  tz: PropTypes.string,
}

export default AssignmentsListItem
