import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Questions from '../questions/Index'
import List from './List'
import TextInput from './TextInput'
import QuestionAnswer from '../../../_models/QuestionAnswer'
import User from '../../../_models/User'
import QuestionUserAnswer from '../../../_models/QuestionUserAnswer'
import Question from '../../../_models/Question'
import QuestionResultResponse from '../../../_models/QuestionResultResponse'

class InputForm extends Component {
  render () {
    let answersInputForm
    switch (this.props.question.type) {
      case Questions.questionTypes.multipleChoice:
        answersInputForm =
          <List
            answers={this.props.answers}
            question={this.props.question}
            handleSelect={!this.props.isAnswered ? this.props.handleSelect : null}
            questionResultResponse={this.props.questionResultResponse}
            setCanQuestionAnswerBeConfirmed={this.props.setCanQuestionAnswerBeConfirmed}
            user={this.props.user}
          />
        break
      case Questions.questionTypes.trueOrFalse:
        answersInputForm =
          <List
            answers={this.props.answers}
            question={this.props.question}
            handleSelect={!this.props.isAnswered ? this.props.handleSelect : null}
            questionResultResponse={this.props.questionResultResponse}
            setCanQuestionAnswerBeConfirmed={this.props.setCanQuestionAnswerBeConfirmed}
            user={this.props.user}
          />
        break
      case Questions.questionTypes.text:
        const answer = this.props.questionResultResponse.answers[0]
        answersInputForm =
          <TextInput
            question={this.props.question}
            isAnswered={this.props.isAnswered}
            handleSubmit={!this.props.isAnswered ? this.props.handleSubmit : null}
            setCanQuestionAnswerBeConfirmed={!this.props.isAnswered ? this.props.setCanQuestionAnswerBeConfirmed : null}
            isCorrect={this.props.questionResultResponse.isCorrect}
            userAnswer={answer !== undefined ? new QuestionUserAnswer(answer) : new QuestionUserAnswer({})}
          />
        break
      default:
        answersInputForm = null
        break
    }
    
    return (answersInputForm)
  }
}

InputForm.propTypes = {
  answers: PropTypes.arrayOf(PropTypes.shape(new QuestionAnswer())).isRequired,
  question: PropTypes.instanceOf(Question).isRequired,
  handleSelect: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setCanQuestionAnswerBeConfirmed: PropTypes.func.isRequired,
  isAnswered: PropTypes.bool,
  questionResultResponse: PropTypes.instanceOf(QuestionResultResponse),
  user: PropTypes.instanceOf(User).isRequired,
}

export default InputForm
