import React, { Component } from 'react'
import PropTypes from 'prop-types'
import AssignmentQuestion from '../../../_models/AssignmentQuestion'

class SelectorItem extends Component {
  render () {
    return (
      <div
        className={'question-selector-item' + (this.props.hide ? ' d-none' : '') + (this.props.isActive ? ' active' : '') + (this.props.isAnswered ? ' is-answered' : '')}>
        <div className="btn btn-link" onClick={() => this.props.handleSelect(this.props.question)}>
          {this.props.questionNum}
        </div>
      
      </div>
    )
  }
}

SelectorItem.propTypes = {
  handleSelect: PropTypes.func.isRequired,
  hide: PropTypes.bool,
  question: PropTypes.instanceOf(AssignmentQuestion).isRequired,
  questionNum: PropTypes.number.isRequired,
  isActive: PropTypes.bool.isRequired,
  isAnswered: PropTypes.bool.isRequired
}

export default SelectorItem
