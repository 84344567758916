import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Update } from './Update'
import ScienceCarousel from '../common/ScienceCarousel'

function Profile ({ match }) {
  const { path } = match
  
  return (
    <ScienceCarousel infoPanel={false}>
      <div className="p-4">
        <div className="container bg-light">
          <Switch>
            <Route path={`${path}`} component={Update}/>
          </Switch>
        </div>
      </div>
    </ScienceCarousel>
  )
}

export { Profile }
