import React, { useEffect, useState } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { accountService } from '../../_services'
import { Alert, Navigation, PrivateRoute } from '../common'
import { Profile } from '../profile/Index'
import { Account } from '../account/Index'
import SchoolInfo from './SchoolInfo'
import PlatformInfo from './PlatformInfo'
import Contacts from './Contacts'
import { Assignments } from '../assignment/Index'
import { Awards } from '../awards/Index'
import { ExploreSection } from '../explore/Index'

function App () {
  const { pathname } = useLocation()
  // user element is used in other components so we disable the warning
  // eslint-disable-next-line
  const [user, setUser] = useState({})

  useEffect(() => {
    const subscription = accountService.user.subscribe(x => setUser(x))
    return subscription.unsubscribe
  }, [])

  return (
    <React.StrictMode>
      <div className={'app-container'}>
        <Navigation/>
        <Alert/>
        <Switch>
          <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)}/>
          <PrivateRoute path="/profile" component={Profile}/>
          <PrivateRoute path="/explore" component={ExploreSection}/>
          <PrivateRoute path="/assignments" component={Assignments}/>
          <PrivateRoute path="/awards" component={Awards}/>
          <Route path="/account" component={Account}/>
          <Route path="/ourSchool" component={SchoolInfo}/>
          <Route path="/studyBubble" component={PlatformInfo}/>
          <Route path="/contacts" component={Contacts}/>
          <Redirect from="*" to="/assignments"/>
        </Switch>
      </div>
    </React.StrictMode>
  )
}

export { App }
