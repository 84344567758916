import React from 'react'

const Footer = () => {
  return (
    <div className="footer background-white height-50px">
      <span>© 2022 Grenaa Gymnasium</span>
    </div>
  )
}

export default Footer
