import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Comments from '../comments/Index'
import ListItemBody from './ListItemBody'
import Answers from '../answers/Index'
import EscapeLines from '../escapeLines/Index'
import NextButton from './NextButton'
import AssignmentQuestion from '../../../_models/AssignmentQuestion'
import User from '../../../_models/User'
import QuestionAnswer from '../../../_models/QuestionAnswer'
import AssignmentQuestionResultResponse from '../../../_models/AssignmentQuestionResultResponse'
import PrevButton from './PrevButton'

class ListItem extends Component {
  state = {
    usedHints: []
  }

  updateUsedHints = (usersAnswers) => {
    if (usersAnswers && usersAnswers.findIndex(ua => ua.isCorrect !== true) !== -1) {
      const hints = this.props.assignmentQuestion.question.hints || []
      const unusedHint = hints.find(
        h => this.state.usedHints.findIndex(uh => h.id === uh.id) === -1
      )
      const usedHints = (typeof unusedHint === 'undefined')
        ? (hints.length > 0 ? [hints[0]] : [] )
        : [...this.state.usedHints, unusedHint]

      this.setState({ usedHints })
    }
  }

  render () {
    const isGroupQuestion = this.props.assignmentQuestion.groupQuestion
    
    return (
      <div className="questions-list-item">
        <ListItemBody isGroupQuestion={isGroupQuestion}
                      question={this.props.assignmentQuestion}
                      questionNum={this.props.assignmentQuestionNum + 1}
                      questionsTotalCount={this.props.assignmentQuestionsTotalCount}
                      user={this.props.user}
                      questionFlagCategories={this.props.questionFlagCategories}/>
        <Answers
          answers={this.props.assignmentQuestion.question.answers.map(el => new QuestionAnswer(el))}
          assignmentQuestion={this.props.assignmentQuestion}
          assignmentQuestionResultResponse={this.props.assignmentQuestionAnswerResultResponse}
          groupId={this.props.groupId}
          hint={[...this.state.usedHints].pop() || null}
          handleAnswerConfirm={this.props.handleAnswerConfirm}
          handleAnswerRetry={this.props.handleAnswerRetry}
          handleAnswerSelect={this.props.handleAnswerSelect}
          handleAnswerSubmit={this.props.handleAnswerSubmit}
          afterAnswerConfirm={this.props.afterAnswerConfirm}
          updateUsedHints={this.updateUsedHints}
          isGroupQuestion={isGroupQuestion}
          user={this.props.user}
          votesNeeded={this.props.assignmentQuestion.groupVotesNeeded}
         />
        <EscapeLines assignmentQuestion={this.props.assignmentQuestion}/>
        <Comments assignment={this.props.assignment} question={this.props.assignmentQuestion}/>
        <div className="assignment-item__middle-line"/>
        <div className='row'>
          {(this.props.assignmentQuestionNum > 0)
          && <PrevButton handleClick={this.props.handleNavButtonClick}
                         prevAssignmentQuestionNum={this.props.assignmentQuestionNum - 1}/>}
          {((this.props.assignmentQuestionNum + 1) < this.props.assignmentQuestionsTotalCount)
          && <NextButton handleClick={this.props.handleNavButtonClick}
                         nextAssignmentQuestionNum={this.props.assignmentQuestionNum + 1}/>}
        </div>
      </div>
    )
  }
}

ListItem.propTypes = {
  assignmentQuestion: PropTypes.instanceOf(AssignmentQuestion).isRequired,
  assignmentQuestionNum: PropTypes.number.isRequired,
  assignmentQuestionsTotalCount: PropTypes.number.isRequired,
  hint: PropTypes.object,
  groupId: PropTypes.number,
  handleAnswerRetry: PropTypes.func.isRequired,
  handleAnswerSelect: PropTypes.func.isRequired,
  handleAnswerSubmit: PropTypes.func.isRequired,
  afterAnswerConfirm: PropTypes.func.isRequired,
  handleAnswerConfirm: PropTypes.func.isRequired,
  handleNavButtonClick: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(User).isRequired,
  assignmentQuestionAnswerResultResponse: PropTypes.instanceOf(AssignmentQuestionResultResponse),
}

export default ListItem
