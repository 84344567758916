import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FaChevronRight } from 'react-icons/fa'

class NextButton extends Component {
  render () {
    return (
      <div className='col'>
        <button type="button"
                onClick={() => this.props.handleClick(this.props.nextAssignmentQuestionNum)}
                className="assignment-item__question-confirm-button w-100">Next Question <FaChevronRight/></button>
      </div>
    )
  }
}

NextButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
  nextAssignmentQuestionNum: PropTypes.number.isRequired,
}

export default NextButton
