import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import Step1 from './registerForm/Step1'
import Step2 from './registerForm/Step2'
import Step3 from './registerForm/Step3'
import * as Yup from 'yup'

import { accountService, alertService } from '../../_services'

function Register ({ history }) {
  const [formStep, setFormStep] = useState(1)
  const maxFormStep = 3
  
  const initialValues = {
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    avatar: [],
    acceptTerms: false
  }
  
  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .required('Username is required'),
    firstName: Yup.string()
      .required('First Name is required'),
    lastName: Yup.string()
      .required('Last Name is required'),
    email: Yup.string()
      .required('Email is required')
      .email('Email is invalid'),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required'),
    acceptTerms: Yup.bool()
      .oneOf([true], 'Accept Terms & Conditions is required')
  })
  
  function onSubmit (fields, { setStatus, setSubmitting }) {
    setStatus()
    accountService.register(fields)
      .then(() => {
        // TODO: add actual email validation when needed
        alertService.success('Registration successful, please login using your email and password', { keepAfterRouteChange: true })
        history.push('login')
      })
      .catch(error => {
        setSubmitting(false)
        alertService.error('Something went wrong while registering your account.')
      })
  }
  
  function handleNextStep () {
    const newStep = formStep + 1
    if (newStep <= maxFormStep) {
      setFormStep(newStep)
    }
  }
  
  function handlePreviousStep () {
    const newStep = formStep - 1
    if (newStep >= 1) {
      setFormStep(newStep)
    } else {
      history.push('/login')
    }
  }
  
  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ errors, touched, setFieldValue, values }) => (
        <Form>
          <div className="form-row">
            <div className="form-group col px-5 padding-top-x-small min-vh-60">
              <h3 className="font-sp">Register</h3>
              <div className="form-row">
                <div className="col-12">
                  <p>Please, enter your details.</p>
                </div>
              </div>
              <Step1
                step={formStep}
                maxStep={maxFormStep}
                onNextStep={handleNextStep}
                onPreviousStep={handlePreviousStep}
                errors={errors}
                touched={touched}
              />
              <Step2
                step={formStep}
                maxStep={maxFormStep}
                onNextStep={handleNextStep}
                onPreviousStep={handlePreviousStep}
                errors={errors}
                touched={touched}
              />
              <Step3
                step={formStep}
                maxStep={maxFormStep}
                onPreviousStep={handlePreviousStep}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                avatar={values.avatar}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export { Register }
