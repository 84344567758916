/**
 * @property {number} questionAnswerId
 * @property {boolean} [isCorrect]
 * @property {boolean} isVotedFor
 * @property {QuestionUserAnswer[]} [userAnswers]
 */
class AssignmentQuestionAnswerResultResponse {
  constructor (obj) {
    Object.assign(this, obj)
  }
}

export default AssignmentQuestionAnswerResultResponse
