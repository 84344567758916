import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import queryString from 'query-string'

import { accountService, alertService } from '../../_services'

function VerifyEmail ({ history }) {
  const EmailStatus = {
    Verifying: 'Verifying',
    Failed: 'Failed'
  }
  
  const [emailStatus, setEmailStatus] = useState(EmailStatus.Verifying)
  
  useEffect(() => {
    const { token } = queryString.parse(window.location.search)
    
    // remove token from url to prevent http referer leakage
    history.replace(window.location.pathname)
    
    accountService.verifyEmail(token)
      .then(() => {
        alertService.success('Verification successful, you can now login', { keepAfterRouteChange: true })
        history.push('login')
      })
      .catch(() => {
        setEmailStatus(EmailStatus.Failed)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  function getBody () {
    switch (emailStatus) {
      case EmailStatus.Verifying:
        return <div>Verifying...</div>
      case EmailStatus.Failed:
        return <div>Verification failed, you can also verify your account using the <Link to="forgot-password">forgot
          password</Link> page.</div>
      default:
        return null
    }
  }
  
  return (
    <div>
      <h3 className="card-header">Verify Email</h3>
      <div className="card-body">{getBody()}</div>
    </div>
  )
}

export { VerifyEmail }