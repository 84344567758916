import React from 'react'
import * as PropTypes from 'prop-types'

class SectionHeader extends React.Component {
  render () {
    let { title, wrapperClass} = this.props
    return (
      <div className={'awards-section-header mb-5 d-flex justify-content-center align-items-end ' + wrapperClass}>
        {title && <h2 className='font-white hyphen-around'>{title}</h2>}
      </div>
    )
  }
}

SectionHeader.propTypes = {
  wrapperClass: PropTypes.string,
  title: PropTypes.string,
}

export default SectionHeader
