import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SelectorItem from './SelectorItem'
import AssignmentQuestion from '../../../_models/AssignmentQuestion'

class Selector extends Component {
  render () {
    return (
      <div className="question-selector">
        <div className='text-center text-white mb-2 question-selector--title'>Q</div>
        <ul className='pl-0 list-unstyled'>
          {this.props.questions.map((question, index) => {
            const isActiveQuestion = this.props.activeQuestion && this.props.activeQuestion.id === question.id
            
            return (
              <li key={question.id}>
                <SelectorItem
                  handleSelect={this.props.handleSelect}
                  question={question}
                  questionNum={index + 1}
                  isActive={isActiveQuestion}/>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
}

Selector.propTypes = {
  activeQuestion: PropTypes.instanceOf(AssignmentQuestion).isRequired,
  handleSelect: PropTypes.func.isRequired,
  questions: PropTypes.array.isRequired,
}

export default Selector
