import React, { Component, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

class AssignmentFinishedDialogue extends Component {
  state = {
    show: false,
  }
  
  handleClose = () => {
    this.props.stateCallback()
    this.setState({ show: false })
  }
  handleShow = () => this.setState({ show: true })
  
  componentDidMount () {
    if (this.props.showModal) {
      this.handleShow()
    }
  }
  
  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.showModal && prevProps.showModal !== this.props.showModal) {
      this.handleShow()
    }
  }
  
  render () {
    return <>
      <Modal show={this.state.show} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="assignment-item__title">Assignment completed</Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.props.successText.length > 0 ? this.props.successText : ''}</Modal.Body>
        <Modal.Footer>
          <button type="primary" className="button-sp w-100 text-white" onClick={this.handleClose}>
            Thanks!
          </button>
        </Modal.Footer>
      </Modal>
    </>
  }
}

AssignmentFinishedDialogue.propTypes = {
  successText: PropTypes.string,
  showModal: PropTypes.bool,
  stateCallback: PropTypes.func
}

export default AssignmentFinishedDialogue