import React, { Component } from 'react'
import { FaExclamationTriangle } from 'react-icons/all'
import FlagForm from './FlagForm'
import ReactTooltip from 'react-tooltip'

class FlagIcon extends Component {
  state = {
    show: false
  }
  
  switchModal = () => {
    this.setState({ show: !this.state.show })
  }
  
  render () {
    return (
      <>
        <div className="row mb-5" >
          <div className="col-1 ml-auto mt-n4" onClick={this.switchModal}>
            <FaExclamationTriangle size="20px" className="cursor-pointer question-flag"
                                   data-tip="Flag this question"/>
          </div>
        </div>
        <div>
          <FlagForm show={this.state.show} onClose={this.switchModal} onSubmit={undefined}
                    categories={this.props.questionFlagCategories} question={this.props.question}
                    hideFlagForm={this.switchModal}/>
        </div>
        <ReactTooltip/>
      </>
    )
  }
}

export default FlagIcon