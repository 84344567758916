import React from 'react'
import Form from 'react-bootstrap/Form'
import * as PropTypes from 'prop-types'

class YearSelector extends React.Component {
  render () {
    let { year, years, handleYearChange } = this.props
    const change = (event) => {
      handleYearChange(event.target.value)
    }
    
    return (
      <Form className="form-inline">
        <Form.Group controlId="award-yearSelector">
          <Form.Label className="font-white">Year: </Form.Label>
          <Form.Control as="select" custom className="form-transparent font-white cursor-pointer"
                        onChange={change} value={year}>
            {years.map((_year) => {
              return (
                <option key={_year} defaultValue={_year === year}>{_year}</option>
              )
            })}
          </Form.Control>
        </Form.Group>
      </Form>
    )
  }
}

YearSelector.propTypes = {
  year: PropTypes.any,
  years: PropTypes.any,
  handleYearChange: PropTypes.any
}

export default YearSelector
