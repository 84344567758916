import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Questions from '../questions/Index'
import List from './List'
import TextInput from './TextInput'
import AssignmentQuestion from '../../../_models/AssignmentQuestion'
import QuestionAnswer from '../../../_models/QuestionAnswer'
import AssignmentQuestionResultResponse from '../../../_models/AssignmentQuestionResultResponse'
import User from '../../../_models/User'
import QuestionUserAnswer from '../../../_models/QuestionUserAnswer'

class InputForm extends Component {
  render () {
    let answersInputForm
    switch (this.props.assignmentQuestion.question.type) {
      case Questions.questionTypes.multipleChoice:
        answersInputForm =
          <List
            answers={this.props.answers}
            assignmentQuestion={this.props.assignmentQuestion}
            handleSelect={!this.props.isAnswered ? this.props.handleSelect : null}
            assignmentQuestionResultResponse={this.props.assignmentQuestionResultResponse}
            setCanQuestionAnswerBeConfirmed={this.props.setCanQuestionAnswerBeConfirmed}
            user={this.props.user}
          />
        break
      case Questions.questionTypes.trueOrFalse:
        answersInputForm =
          <List
            answers={this.props.answers}
            assignmentQuestion={this.props.assignmentQuestion}
            handleSelect={!this.props.isAnswered ? this.props.handleSelect : null}
            assignmentQuestionResultResponse={this.props.assignmentQuestionResultResponse}
            setCanQuestionAnswerBeConfirmed={this.props.setCanQuestionAnswerBeConfirmed}
            user={this.props.user}
          />
        break
      case Questions.questionTypes.text:
        const answer = this.props.assignmentQuestionResultResponse.answers[0]
        answersInputForm =
          <TextInput
            assignmentQuestion={this.props.assignmentQuestion}
            isAnswered={this.props.isAnswered}
            handleSubmit={!this.props.isAnswered ? this.props.handleSubmit : null}
            setCanQuestionAnswerBeConfirmed={!this.props.isAnswered ? this.props.setCanQuestionAnswerBeConfirmed : null}
            isCorrect={this.props.assignmentQuestionResultResponse.isCorrect}
            isVotedFor={answer.isVotedFor}
            userAnswer={new QuestionUserAnswer(answer.userAnswers[0] || {})}
          />
        break
      default:
        break
    }
    
    return answersInputForm || null
  }
}

InputForm.propTypes = {
  answers: PropTypes.arrayOf(PropTypes.shape(new QuestionAnswer())).isRequired,
  assignmentQuestion: PropTypes.instanceOf(AssignmentQuestion).isRequired,
  handleSelect: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setCanQuestionAnswerBeConfirmed: PropTypes.func.isRequired,
  isAnswered: PropTypes.bool,
  assignmentQuestionResultResponse: PropTypes.instanceOf(AssignmentQuestionResultResponse),
  user: PropTypes.instanceOf(User).isRequired,
}

export default InputForm
