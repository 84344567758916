import React, { Component } from 'react'
import PropTypes from 'prop-types'
import InputForm from './InputForm'
import Result from './Result'
import User from '../../../_models/User'
import QuestionAnswer from '../../../_models/QuestionAnswer'
import QuestionFeedback from '../../../_models/QuestionFeedback'
import ConfirmButton from './ConfirmButton'
import Question from '../../../_models/Question'
import QuestionResultResponse from '../../../_models/QuestionResultResponse'

class Answers extends Component {
  canQuestionAnswerBeConfirmed = function () {
    // TODO: handle submitting new answers
    const answers = this.props.questionResultResponse.answers
    if (answers.length > 0) {
      const question = this.props.question
      // Hardcoded value of 1 should be fine as users answer questions individually in Explore area (setting the value in backend could be considered)
      const minAnswersQt = 1
      const hasMinAnswersQtVotedFor = answers.length > 0
      const userAnswersUnconfirmed = answers
        .filter(answer => answer.questionId === question.id
          && !answer.confirmed
          && answer.user.id === this.props.user.id).length >= minAnswersQt
      
      return hasMinAnswersQtVotedFor && userAnswersUnconfirmed
    }
    
    return false
  }
  
  isQuestionConfirmedByUser = function () {
    if (this.props.questionResultResponse.answers.length > 0) {
      return this.props.questionResultResponse.answers
        .filter(answer => answer.questionId === this.props.question.id && answer.confirmed && answer.user.id === this.props.user.id).length > 0
    }
    
    return false
  }
  
  setCanQuestionAnswerBeConfirmed = (canQuestionAnswerBeConfirmed) => this.setState({ canQuestionAnswerBeConfirmed })
  
  state = {
    isQuestionConfirmedByUser: this.isQuestionConfirmedByUser(),
    canQuestionAnswerBeConfirmed: this.canQuestionAnswerBeConfirmed(),
  }
  
  render () {
    const canQuestionAnswerBeConfirmed = this.canQuestionAnswerBeConfirmed()
    const isCorrect = this.props.questionResultResponse.isCorrect
    const feedbacks = (isCorrect !== null)
      ? this.props.question.feedback.filter(f => !!f.isAnswerCorrect === !!isCorrect) : []
    const randomFeedback = feedbacks.length > 0
      ? feedbacks[Math.floor(Math.random() * feedbacks.length)]
      : {}
  
    return (
      <div className="answers row no-gutters mt-2 mb-4">
        <div className='col-12 mb-2'>
          <InputForm
            answers={this.props.answers}
            question={this.props.question}
            setCanQuestionAnswerBeConfirmed={this.setCanQuestionAnswerBeConfirmed}
            handleSelect={this.props.handleAnswerSelect}
            handleSubmit={this.props.handleAnswerSubmit}
            isAnswered={this.state.isQuestionConfirmedByUser}
            user={this.props.user}
            questionResultResponse={this.props.questionResultResponse}/>
        </div>
        {(canQuestionAnswerBeConfirmed && this.state.canQuestionAnswerBeConfirmed && !this.state.isQuestionConfirmedByUser)
        && <ConfirmButton question={this.props.question}
                          setCanQuestionAnswerBeConfirmed={this.setCanQuestionAnswerBeConfirmed}
                          setIsQuestionConfirmedByUser={(isQuestionConfirmedByUser) => this.setState({ isQuestionConfirmedByUser })}
                          handleAnswerConfirm={this.props.handleAnswerConfirm}
                          updateUsedHints={this.props.updateUsedHints}/>}
        <div className='col-12'>
          {(isCorrect !== null)
          && <Result correct={isCorrect}
                     setCanQuestionAnswerBeConfirmed={this.setCanQuestionAnswerBeConfirmed}
                     setIsQuestionConfirmedByUser={(isQuestionConfirmedByUser) => this.setState({ isQuestionConfirmedByUser })}
            // TODO: use answer specific feedback if exists/needed, refactor/change logic getting feedback msg?
                     feedback={randomFeedback ? new QuestionFeedback(randomFeedback) : null}
                     question={this.props.question}
                     handleAnswerRetry={this.props.handleAnswerRetry}
                     hint={this.props.hint}/>}
        </div>
      </div>
    )
  }
}

Answers.propTypes = {
  answers: PropTypes.arrayOf(PropTypes.shape(new QuestionAnswer())).isRequired,
  question: PropTypes.instanceOf(Question).isRequired,
  hint: PropTypes.object,
  handleAnswerRetry: PropTypes.func.isRequired,
  handleAnswerSelect: PropTypes.func.isRequired,
  handleAnswerSubmit: PropTypes.func.isRequired,
  handleAnswerConfirm: PropTypes.func.isRequired,
  updateUsedHints: PropTypes.func.isRequired,
  questionResultResponse: PropTypes.instanceOf(QuestionResultResponse),
  user: PropTypes.instanceOf(User).isRequired,
}

export default Answers
