import React, { Component } from 'react'
import Alert from 'react-bootstrap/Alert'
import PropTypes from 'prop-types'
import Assignment from '../../../_models/Assignment'

class Hint extends Component {
  render () {
    const { hint } = this.props
    if (!hint || (!hint.body && !hint.paths)) {
      return null
    }

    return (
      <div className="border-sp-medium mt-3">
        <Alert.Heading className={'position-relative mb-n4'}>
          <div
            className={'question-item-hint d-table p-2 ml-0 mr-auto'}>
            <div className={'pr-5 text-white select-none'}>Hint</div>
          </div>
        </Alert.Heading>
        <div className="row mb-3">
          <div className="col">
            {
              (hint.body && hint.body.length > 0) && <>
                <div className="row">
                  <div className="col text-center">
                    <span className="font-sp">{hint.body ?? ''}</span>
                  </div>
                </div>
              </>
            }
            {
              (hint.paths && hint.paths.length > 0) && <>
                <div className="row">
                  <div className="col-6 text-center mt-3 mx-auto">
                    <div className="list-group">
                      {
                        hint.paths.map((value, index) => {
                          return <a href={value} target="_blank" rel="noreferrer" className="list-group-item list-group-item-action">
                            Attachment {index + 1}
                          </a>
                        })
                      }
                    </div>
                  </div>
                </div>
              </>
            }
          </div>
        </div>
      </div>
    )
  }
}

Hint.propTypes = {
  hint: PropTypes.object,
}

export default Hint