import { fetchWrapper } from '../_helpers'

const apiUrl = process.env.REACT_APP_API_URL
const baseUrl = `${apiUrl}/api/v1/stats`

export const statisticsService = {
  assignmentViewed,
  questionViewed
}

function assignmentViewed(params) {
  return fetchWrapper.post(`${baseUrl}/assignment-viewed`, params);
}

function questionViewed(params) {
  return fetchWrapper.post(`${baseUrl}/question-viewed`, params);
}
