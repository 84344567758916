/**
 * @property {number} id
 * @property {boolean} isAnswerCorrect
 * @property {number} [questionId]
 * @property {string} body
 */
class QuestionFeedback {
  constructor (obj) {
    Object.assign(this, obj)
  }
}

export default QuestionFeedback
