import React from 'react'
import { FaAngleDown, FaAngleLeft, FaAngleRight, FaAngleUp, FaTimes, FaCheck } from 'react-icons/all'

const ArrowButton = ({
  direction = 'right',
  classes = '',
  classesInner = '',
  onClick = null,
  whiteButton = false,
  size = 55
}) => {
  const arrowDirection = (direction) => {
    switch (direction) {
      case 'left':
        if (!whiteButton) {
          return <FaAngleLeft className="font-white" size={size + 'px'}/>
        } else {
          return <FaAngleLeft className="font-sp" size={size + 'px'}/>
        }
      case 'right':
        if (!whiteButton) {
          return <FaAngleRight className="font-white" size={size + 'px'}/>
        } else {
          return <FaAngleRight className="font-sp" size={size + 'px'}/>
        }
      case 'up':
        if (!whiteButton) {
          return <FaAngleUp className="font-white" size={size + 'px'}/>
        } else {
          return <FaAngleUp className="font-sp" size={size + 'px'}/>
        }
      case 'down':
        if (!whiteButton) {
          return <FaAngleDown className="font-white" size={size + 'px'}/>
        } else {
          return <FaAngleDown className="font-sp" size={size + 'px'}/>
        }
      case 'tick':
        return <FaCheck className="font-white" size={size + 'px'}/>
      case 'cross':
        return <FaTimes className="font-white" size={size + 'px'}/>
      default:
        return <></>
    }
  }
  
  return (
    <div className={classes}>
      <div
        className={'p-1 nav-arrow cursor-pointer select-none ' + classesInner + ' ' + (whiteButton ? 'background-white' : 'background-sp')}
        onClick={onClick}>
        {arrowDirection(direction)}
      </div>
    </div>
  )
}

export default ArrowButton
