import React from 'react'
import * as PropTypes from 'prop-types'

class MedalOverviewListItem extends React.Component {
  render () {
    return (
      <div className={'medal-container ' + (this.props.wrapperClass || '')}>
        <div className={'d-flex align-items-center justify-content-center medal-container--' + this.props.type}>
          <span className="font-lg font-white font-italic">{this.props.medalsCount}</span>
        </div>
      </div>
    )
  }
}

MedalOverviewListItem.propTypes = {
  type: PropTypes.string.isRequired,
  medalsCount: PropTypes.number.isRequired,
  wrapperClass: PropTypes.string,
  altText: PropTypes.string,
}

export default MedalOverviewListItem
