export function configureFakeBackend () {
  let realFetch = window.fetch
  window.fetch = function (url, opts) {
    return new Promise((resolve, reject) => {
      // wrap in timeout to simulate server api call
      setTimeout(handleRoute, 1)
      
      function handleRoute () {
        const { method } = opts || { method: 'GET' } // TODO
        switch (true) {
          case url.match(/\/achievements\/medals$/) && method === 'GET':
            return getAchievementsMedals()
          default:
            // pass through any requests not handled above
            return realFetch(url, opts)
              .then(response => resolve(response))
              .catch(error => reject(error))
        }
      }
      
      // route functions
      // helper functions
      
      function ok (body) {
        resolve({ ok: true, text: () => Promise.resolve(JSON.stringify(body)) })
      }
      
      function getAchievementsMedals () {
        const medals =
          [
            {
              id: 1,
              category: {
                title: 'Mathematics',
                color: '#00897b',
                icon: '/storage/questions/question_cat_maths.png'
              },
              gold: 4,
              silver: 5,
              bronze: 2
            },
            {
              id: 2,
              category: {
                title: 'History',
                color: '#f9a825',
                icon: '/storage/questions/question_cat_history.png'
              },
              gold: 3,
              silver: 7,
              bronze: 1
            },
            {
              id: 3,
              category: {
                title: 'Physics',
                color: '#c51162',
                icon: '/storage/questions/question_cat_physics.png'
              },
              gold: 7,
              silver: 2,
              bronze: 0
            },
            {
              id: 4,
              category: {
                title: 'Chemistry',
                color: '#673ab7',
                icon: '/storage/questions/question_cat_chemistry.png'
              },
              gold: 6,
              silver: 3,
              bronze: 1
            },
            {
              id: 5,
              category: {
                title: 'Biological Chemistry',
                color: '#dd2c00',
                icon: '/storage/questions/question_cat_biochemistry.png'
              },
              gold: 3,
              silver: 1,
              bronze: 2
            },
          ]
        
        return ok(medals)
      }
    })
  }
}
