import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ListItem from './ListItem'
import QuestionAnswer from '../../../_models/QuestionAnswer'
import User from '../../../_models/User'
import Question from '../../../_models/Question'
import QuestionResultResponse from '../../../_models/QuestionResultResponse'

class List extends Component {
  render () {
    return (
      <div className="answers-list">
        <div className='row no-gutters'>
          {this.props.answers.map((answer, index) => {
            const questionAnswerResultResponse = this.props.questionResultResponse
            const isCorrect = this.props.questionResultResponse.isCorrect
            const isSelected = this.props.questionResultResponse.answers.filter((userAnswer) => (answer.id === userAnswer.questionAnswerId && userAnswer.createdBy === this.props.user.id)).length > 0
            
            return (
              <div key={answer.id}
                   className={'col-6 mt-2' + ((index % 2) ? ' pl-1' : ' pr-1')}>
                <ListItem
                  answer={answer}
                  question={this.props.question}
                  isSelected={isSelected}
                  isCorrect={isCorrect === true ? true : null}
                  isWrong={isCorrect === false ? true : null}
                  handleSelect={this.props.handleSelect}
                  setCanQuestionAnswerBeConfirmed={this.props.setCanQuestionAnswerBeConfirmed}
                  questionAnswerResultResponse={new QuestionResultResponse(questionAnswerResultResponse)}
                />
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

List.propTypes = {
  answers: PropTypes.arrayOf(PropTypes.shape(new QuestionAnswer())).isRequired,
  question: PropTypes.instanceOf(Question).isRequired,
  handleSelect: PropTypes.func,
  questionResultResponse: PropTypes.instanceOf(QuestionResultResponse),
  user: PropTypes.instanceOf(User).isRequired,
  setCanQuestionAnswerBeConfirmed: PropTypes.func,
}

export default List
