import React, { Component } from 'react'
import { Collapse } from 'react-bootstrap'
import { FaAngleDown, FaAngleUp } from 'react-icons/fa/index'
import CommentsList from './CommentsList'
import CollapseButton from '../../common/CollapseButton'
import CommentForm from './CommentForm'

import { accountService, alertService, commentService } from '../../../_services'
import Spinner from "../../common/Spinner";

class Comments extends Component {
  state = {
      expanded: false,
      comments: [],
      limit: 5,
      offset: 0,
      commentsLoading: false
  }

  saveComment = async (comment) => {
      await commentService.create({
          body: comment,
          assignmentQuestionId: this.props.question.id
      }).catch(error => {
        console.log(error)
        alertService.error('Something went wrong while posting your comment.')
      })
      await this.fetchComments();
  }

  fetchComments = async () => {
      this.setState({commentsLoading: true});
      const {limit} = this.state;
      let newComments = await commentService.getByAssignmentQuestionId(this.props.question.id, limit, 0)
        .catch(error => {
        alertService.error('Something went wrong while loading comments.')
      });
      this.setState({
        comments: newComments || [],
        offset: newComments && newComments.length,
        commentsLoading: false
      });
  }

  loadMoreComments = async () => {
      this.setState({commentsLoading: true});
      const {limit, offset, comments} = this.state;
      let newComments = await commentService.getByAssignmentQuestionId(this.props.question.id, limit, offset)
        .catch(error => {
        alertService.error('Something went wrong while loading comments.')
      });
      const newOffset = offset + newComments.length;
      newComments = [...comments, ...newComments];
      this.setState({comments: newComments, offset: newOffset, commentsLoading: false});
  }

  render () {
    const currentUser = accountService.userValue;
    return (
      <div className="comments row no-gutters">
        <div className='col-11'>
          <div className="d-flex assignment-item__section-header">
            <div className="pr-3">{'Comments'}</div>
            <div className="flex-grow-1 pr-2">
              <div className='assignment-item__middle-line'/>
            </div>
          </div>
        </div>
        <CollapseButton
          handleClick={async () => {
            await this.setState({expanded: !this.state.expanded});
            const {expanded, comments} = this.state;
            if (expanded && comments.length < 1)
              this.fetchComments();
          }}
          isExpanded={this.state.expanded}
          collapsedIcon={<FaAngleDown size='18px' color='white'/>}
          expandedIcon={<FaAngleUp size='18px' color='white'/>}
          wrapperClass='col-1 text-right wider-collapse-button'
        />

        <div className='col-12 mt-3'>
          <Collapse in={this.state.expanded}>
            <div className='w-100'>
                {(this.state.commentsLoading && this.state.comments.length < 1
                    ? <div className="CommentsList">
                        <Spinner/>
                    </div>
                : <CommentsList loadingComments={this.state.commentsLoading} comments={this.state.comments} loadMoreComments={this.loadMoreComments}/>)}
              <CommentForm comment={{body: '', user: currentUser}} saveComment={this.saveComment} fetchComments={this.fetchComments}/>
            </div>
          </Collapse>
        </div>
      </div>
    )
  }
}

Comments.propTypes = {
}

export default Comments
