import React, { Component } from 'react'
import AssignmentsList from './AssignmentsList'
import BackgroundSlider from './BackgroundSlider'
import InfoPanel from './InfoPanel'
import {
  accountService,
  alertService,
  assignmentService,
  carouselItemService,
  questionFlagService
} from '../../_services'
import Echo from 'laravel-echo'
import pusher from 'pusher-js'
import Assignment from '../../_models/Assignment'

class Assignments extends Component {
  tz = Intl.DateTimeFormat().resolvedOptions().timeZone
  
  state = {
    activeCarouselItemIndex: 0,
    questionFlagCategories: [],
    showCompleted: false,
    assignments: [],
    carouselItems: []
  }
  
  echoService = new Echo({
    broadcaster: 'pusher',
    key: process.env.REACT_APP_PUSHER_KEY,
    cluster: process.env.REACT_APP_PUSHER_CLUSTER,
    forceTLS: false,
    encrypted: process.env.REACT_APP_PUSHER_FORCETLS === 'true',
    wsHost: process.env.REACT_APP_PUSHER_HOST,
    wsPort: 6001,
    wssPort: 6001,
    disableStats: true,
    enabledTransports: ['ws', 'wss'],
    authEndpoint: process.env.REACT_APP_PUSHER_AUTH_ENDPOINT,
    
    auth: {
      headers: {
        Authorization: `Bearer ${accountService.userValue.jwtToken}`,
        Accept: 'application/json'
      },
    },
  })
  
  componentDidMount () {
    assignmentService.getAll().then((res) => this.initAssignments(res)).catch(error => {
      alertService.error('Something went wrong while loading assignments.')
    })
    carouselItemService.getAll().then((res) => this.initCarousel(res)).catch(error => {
      alertService.error('Something went wrong while loading carouselItems.')
    })
    questionFlagService.getCategories().then(r => {
      this.setState({ questionFlagCategories: r })
    }).catch(e => {
      alertService.error('Something went wrong while loading question flag categories.')
    })
  }
  
  switchCompleted = () => {
    assignmentService.getAll(`?showCompleted=${!this.state.showCompleted}`).then((res) => this.initAssignments(res)).catch(error => {
      alertService.error('Something went wrong while loading assignments.')
    })
    this.setState({ showCompleted: !this.state.showCompleted })
  }
  
  initAssignments = (assignments) => {
    this.setState({ assignments: assignments.map(el => new Assignment(el)) })
  }
  
  initCarousel = (carouselItems) => {
    this.setState({ carouselItems })
    this.transitionSlides(true)
    this.carouselInterval = setInterval(() => {this.transitionSlides()}, 120 * 1000)
  }
  
  transitionSlides = (isInitial = false) => {
    const activeCarouselItemIndex = this.state.activeCarouselItemIndex
    const nextCarouselItemIndex = (!isInitial && this.state.carouselItems[activeCarouselItemIndex + 1])
      ? (activeCarouselItemIndex + 1)
      : 0
    
    this.setState({ activeCarouselItemIndex: nextCarouselItemIndex })
  }
  
  componentWillUnmount () {
    clearInterval(this.carouselInterval)
  }
  
  render () {
    return (
      <div className="" style={{ height: 'calc(100% - 72px)' }}>
        <BackgroundSlider slideItem={this.state.carouselItems[this.state.activeCarouselItemIndex] || null}>
          {this.state.assignments &&
          <AssignmentsList
            assignments={this.state.assignments}
            tz={this.tz}
            echo={this.echoService}
            showCompleted={this.state.showCompleted}
            switchCompleted={this.switchCompleted}
            questionFlagCategories={this.state.questionFlagCategories}
          />}
        </BackgroundSlider>
        <InfoPanel slideItem={this.state.carouselItems[this.state.activeCarouselItemIndex] || null} tz={this.tz}
                   echo={this.echoService}/>
      </div>
    )
  }
}

export { Assignments }
