import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ListItems from './ListItems'
import Selector from './Selector'
import AssignmentQuestion from '../../../_models/AssignmentQuestion'
import User from '../../../_models/User'
import AssignmentQuestionResultResponse from '../../../_models/AssignmentQuestionResultResponse'

class Questions extends Component {
  static questionTypes
  
  state = {
    selectedAssignmentQuestion: this.props.assignmentQuestions[0] // TODO: start from where the user previously left?
  }
  
  handleNextButtonClick = (nextQuestionNum) => {
    this.setState({selectedAssignmentQuestion: this.props.assignmentQuestions[nextQuestionNum] || this.props.assignmentQuestions[0]})
  }
  
  render () {
    return (
      <div className="Questions row no-gutters mt-2">
        <div className='col-1'>
          <Selector questions={this.props.assignmentQuestions} activeQuestion={this.state.selectedAssignmentQuestion}
                    handleSelect={(selectedAssignmentQuestion) => this.setState({ selectedAssignmentQuestion })}/>
        </div>
        <div className='col-11'>
          <ListItems user={this.props.user}
                     assignmentQuestions={this.props.assignmentQuestions}
                     assignment={this.props.assignment}
                     groupId={this.props.groupId}
                     handleAnswerRetry={this.props.handleAnswerRetry}
                     handleAnswerSelect={this.props.handleAnswerSelect}
                     handleAnswerSubmit={this.props.handleAnswerSubmit}
                     handleAnswerConfirm={this.props.handleAnswerConfirm}
                     handleNextButtonClick={this.handleNextButtonClick}
                     selectedAssignmentQuestion={this.state.selectedAssignmentQuestion}
                     usersAnswers={this.props.usersAnswers}/>
        </div>
      </div>
    )
  }
}

Questions.propTypes = {
  assignmentQuestions: PropTypes.arrayOf(PropTypes.shape(new AssignmentQuestion())).isRequired,
  groupId: PropTypes.number,
  handleAnswerRetry: PropTypes.func.isRequired,
  handleAnswerSelect: PropTypes.func.isRequired,
  handleAnswerSubmit: PropTypes.func.isRequired,
  handleAnswerConfirm: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(User).isRequired,
  usersAnswers: PropTypes.arrayOf(PropTypes.shape(new AssignmentQuestionResultResponse())).isRequired,
}

Questions.questionTypes = {
  singleChoice: 1,
  multipleChoice: 2,
  trueOrFalse: 3,
  text: 4,
}

export default Questions
