/**
 * @property {number} id
 * @property {string} title
 * @property {string} body
 * @property {string} feedback
 * @property {bool} [isCorrect]
 * @property {bool} [isWrong]
 */
class QuestionAnswer {
  constructor (obj) {
    Object.assign(this, obj)
  }
}

export default QuestionAnswer
