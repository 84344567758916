import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Nav, Navbar } from 'react-bootstrap'
import { Role } from '../../_helpers'
import { accountService } from '../../_services'
import UserMenu from './UserMenu'

function Navigation () {
  const user = accountService.userValue

  return (
    <Navbar bg="white" expand="lg" className="pb-0 pt-0 sticky-top">
      <Navbar.Brand href="/user/">
        <div className="sp-school-logo width-300px height-50px">&nbsp;</div>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="navbarContent"/>
      <Navbar.Collapse id="navbarContent" className="ml-auto">
        <Nav className="ml-auto">
          {!user &&
          <React.Fragment>
            <NavLink exact to="/" className="nav-item nav-link nav-sp font-grey font-weight-semibold">Home</NavLink>
            {/*<NavLink exact to="/ourSchool" className="nav-item nav-link nav-sp font-grey font-weight-semibold">Our*/}
            {/*  School</NavLink>*/}
            <NavLink exact to="/studyBubble" className="nav-item nav-link nav-sp font-grey font-weight-semibold">The
              Study Bubble</NavLink>
            {/*<NavLink exact to="/contacts"*/}
            {/*         className="nav-item nav-link nav-sp font-grey font-weight-semibold">Contacts</NavLink>*/}
          </React.Fragment>
          }
          
          {/* TODO: Incorporate user menu into collapsed menu without repeating menu elements */}
          {user &&
          <React.Fragment>
            <NavLink to="/explore" className="nav-item nav-link nav-sp font-grey font-weight-semibold">Explore</NavLink>
            <NavLink to="/assignments"
                     className="nav-item nav-link nav-sp font-grey font-weight-semibold">Assignments</NavLink>
            <NavLink to="/awards"
                     className="nav-item nav-link nav-sp font-grey font-weight-semibold">Awards</NavLink>
            {user && user.role === Role.Admin &&
            <NavLink to="/admin" className="nav-item nav-link nav-sp font-grey font-weight-semibold">Admin</NavLink>
            }
            <NavLink exact to="/profile"
                     className="nav-item nav-link nav-sp font-grey font-weight-semibold d-lg-none d-md-block">Your
              Profile</NavLink>
            <Nav.Link href="#" className="nav-item nav-link nav-sp font-grey font-weight-semibold d-lg-none d-md-block"
                      onClick={accountService.logout}>Logout</Nav.Link>
          </React.Fragment>
          }
        
        </Nav>
        {user &&
        <UserMenu classes="col-2 d-none d-lg-inline-block align-self-center" user={user}/>
        }
      </Navbar.Collapse>
    </Navbar>
  )
}

export { Navigation }
