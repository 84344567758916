import React, { Component } from 'react'
import PropTypes from 'prop-types'

class AvatarImage extends Component {
  render () {
    return (
      <div className={'sp-assignment-avatar-group-icon'}
           data-for={this.props.hoverTarget}
           data-tip={this.props.hover ? this.props.hoverText : this.props.hoverHTML}>
        <img src={this.props.url} alt={'User avatar'}/>
      </div>
    )
  }
}

AvatarImage.propTypes = {
  url: PropTypes.string,
  hover: PropTypes.bool,
  hoverText: PropTypes.string,
  hoverHTML: PropTypes.array,
  hoverTarget: PropTypes.string
}

export default AvatarImage
