import React from 'react'
import ArrowButton from '../../common/ArrowButton'
import { ErrorMessage, Field } from 'formik'
import { accountService } from '../../../_services'

class Step1 extends React.Component {
  render () {
    const { step, onNextStep, maxStep, errors, touched } = this.props
    
    if (step !== 1) {
      return null
    }
  
    const validateEmail = value => {
      return accountService.validateEmail(value)
        .then(() => {
          return false;
        })
        .catch(() => {
          return 'Email already taken';
        })
    }
    
    return (
      <React.Fragment>
        <div className="form-row margin-bottom-x-small">
          <div className="col-12">
            <Field name="firstName" type="text" placeholder="First Name..."
                   className={'width-100 font-white placeholder-white placeholder-italic background-sp border-sp' + (errors.firstName && touched.firstName ? ' is-invalid' : '')}/>
            <ErrorMessage name="firstName" component="div" className="invalid-feedback"/>
          </div>
        </div>
        <div className="form-row margin-bottom-x-small">
          <div className="col-12">
            <Field name="lastName" type="text" placeholder="Last Name..."
                   className={'width-100 font-white placeholder-white placeholder-italic background-sp border-sp ' + (errors.lastName && touched.lastName ? ' is-invalid' : '')}/>
            <ErrorMessage name="lastName" component="div" className="invalid-feedback"/>
          </div>
        </div>
        <div className="form-row">
          <div className="col-12">
            <Field name="email" type="text" placeholder="E-Mail..." validate={validateEmail}
                   className={'width-100 font-white placeholder-white placeholder-italic background-sp border-sp ' + (errors.email && touched.email ? ' is-invalid' : '')}/>
            <ErrorMessage name="email" component="div" className="invalid-feedback"/>
          </div>
        </div>
        <hr className="hr-sp"/>
        <div className="form-row col">
          <ArrowButton direction="right"
                       classes="ml-auto"
                       onClick={(!(errors.firstName || errors.lastName || errors.email) && (touched.firstName && touched.lastName && touched.email))
                         ? onNextStep
                         : () => {}} />
        </div>
        <div className="form-row">
          <div className="col-3 ml-auto">
            <span className="font-italic font-sp">{step} / {maxStep}</span>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default Step1
