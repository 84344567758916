import React, { Component } from 'react'
import PropTypes from 'prop-types'
import AssignmentsListItem from './AssignmentsListItem'
import { accountService, statisticsService } from '../../_services'
import Assignment from '../../_models/Assignment'
import { MathJaxContext } from 'better-react-mathjax'

class AssignmentsList extends Component {
  state = {
    usersAnswersNeedUpdateForAssignmentId: null,
    activeAssignment: null,
  }
  
  timer = null
  user = null
  
  sendLogApiCall = (assignment, sec) => {
    const params = {
      assignmentId: assignment.id,
      userId: this.user.id,
      seconds: sec,
    }
    statisticsService.assignmentViewed(params)
      .catch(() => {
        console.log('Something went wrong while sending the stats.')
      })
  }
  
  handleToggleCollapse = (assignment) => {
    const activeAssignment = this.state.activeAssignment
      ? (this.state.activeAssignment.id !== assignment.id ? assignment : null)
      : assignment
    this.setState({ activeAssignment })
    if (activeAssignment) {
      clearInterval(this.timer)
      this.timer = null
      this.sendLogApiCall(activeAssignment, 5)
      this.timer = setInterval(() => this.sendLogApiCall(activeAssignment, 10), 10000)
    } else {
      clearInterval(this.timer)
      this.timer = null
    }
  }
  
  handleMarkUsersAnswersUpdated = () => {
    this.setState({ usersAnswersNeedUpdateForAssignmentId: null })
  }
  
  componentDidMount () {
    if (this.props.echo) {
      this.user = accountService.userValue
      this.props.echo.private(`App.User.${this.user.id}`).listen('QuestionUserAnswerChangedNotification', (response) => {
        if (response.notification) {
          this.setState({ usersAnswersNeedUpdateForAssignmentId: response.notification.assignmentId })
        }
      })
    }
  }
  
  render () {
    return (
      <div className="container assignments-list">
        <div className="ml-auto my-auto">
          <label htmlFor="showCompleted"
                 className="form-control border-radius-none select-none"><input
            type="checkbox" name="showCompleted" id="showCompleted" value={this.props.showCompleted}
            onChange={this.props.switchCompleted}/>&nbsp; Include completed assignments</label>
        </div>
  
        <MathJaxContext>
          <ul className='list-unstyled'>
            {this.props.assignments.map((assignmentModel, index) => {
              const isActiveAssignment = this.state.activeAssignment && this.state.activeAssignment.id === assignmentModel.id
      
              return (
                <li key={assignmentModel.id}
                    className={'mb-2 '}>
                  <a name={'assignment-' + assignmentModel.id} href='#'
                     style={{ marginTop: '-100px', paddingBottom: '100px', display: 'block' }}/>
          
                  <AssignmentsListItem
                    assignment={assignmentModel}
                    groupId={this.props.groupId}
                    collapsed={!isActiveAssignment}
                    handleToggleCollapsed={this.handleToggleCollapse}
                    handleMarkUsersAnswersUpdated={this.handleMarkUsersAnswersUpdated}
                    usersAnswersNeedUpdate={parseInt(this.state.usersAnswersNeedUpdateForAssignmentId) === assignmentModel.id}
                    tz={this.props.tz}
                    questionFlagCategories={this.props.questionFlagCategories}/>
                </li>
              )
            })}
          </ul>
        </MathJaxContext>
      </div>
    )
  }
}

AssignmentsList.propTypes = {
  assignments: PropTypes.arrayOf(PropTypes.shape(new Assignment())).isRequired,
  echo: PropTypes.object,
  groupId: PropTypes.number,
  tz: PropTypes.string,
}

export default AssignmentsList
