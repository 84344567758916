import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { FaTelegramPlane } from 'react-icons/fa/index'

import TextInput from '../../common/TextInput'

class CommentForm extends Component {
    doSaveComment = async (body) => {
        this.props.saveComment(body);
    }

    doFetchComments = async () => {
        this.props.fetchComments();
    }

  handleSaveComment = async (comment, callback) => {
    try {
      await this.doSaveComment(comment.body);
      callback()
    } catch (error) {
        console.log(error);
      //toast.error('Save failed. ' + error.message, { autoClose: false })
    }
  }

  render () {
    return (
      <div className="question-item__comment_body row no-gutters py-3 h-100">
        <div className='col-1'>
          <img src={this.props.comment.user.avatarUrl}
               className='img-fluid mx-auto d-block question-comment_avatar border border-2 border-white sp-avatar'
               alt={'comment author avatar'}/>
        </div>

        <div className='col-11'>
          <Formik
            initialValues={this.props.comment}
            validate={values => {
              const errors = {}
              if (!values.body) {
                errors.body = 'Required'
              }

              return errors
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setTimeout(() => {
                this.handleSaveComment(values, () => {
                    setSubmitting(false);
                    resetForm();
                })
                setSubmitting(false)
              }, 400)
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit} method={this.props.comment.id ? 'PUT' : 'POST'}>
                <div className='row no-gutters'>
                  <div className='col-11'>
                    <TextInput
                      name="body"
                      label=""
                      placeholder="Enter your comment here"
                      value={values.body}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={isSubmitting}
                      error={errors.body && touched.body && errors.body}
                    />
                  </div>
                  <div className='col-1'>
                    <button
                      style={{ borderRadius: '0', marginRight: 0, marginLeft: '0', display: 'block' }}
                      type="submit"
                      disabled={isSubmitting}
                      className="btn btn-success h-100 w-100">
                        {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                      <FaTelegramPlane size="30px"/>
                    </button>
                  </div>
                </div>

              </form>
            )}
          </Formik>
        </div>
      </div>
    )
  }
}

CommentForm.propTypes = {
  comment: PropTypes.object.isRequired,
}

export default CommentForm
