import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SelectorItem from './SelectorItem'
import AssignmentQuestion from '../../../_models/AssignmentQuestion'

class Selector extends Component {
  render () {
    return (
      <div className="question-selector">
        <div className="text-center text-white mb-2 question-selector--title">Q</div>
        <ul className="pl-0 list-unstyled">
          {this.props.questions
            .map((question, index) => {
              const activeQuestionIndex = this.props.activeQuestion
                ? this.props.questions.findIndex(q => this.props.activeQuestion.id === q.id)
                : 0
              const hide = Math.abs(activeQuestionIndex - index) > 2
              const isActiveQuestion = this.props.activeQuestion && this.props.activeQuestion.id === question.id
              const isAnswered = !!this.props.userAnswers.filter(item => question.id === item.assignmentQuestionId)[0].isCorrect // TODO: check if isCorrect logic is valid here

              return (
                <li key={question.id}>
                  <SelectorItem
                    hide={hide}
                    handleSelect={this.props.handleSelect}
                    question={question}
                    questionNum={index + 1}
                    isActive={isActiveQuestion}
                    isAnswered={isAnswered}/>
                </li>
              )
            })}
        </ul>
      </div>
    )
  }
}

Selector.propTypes = {
  activeQuestion: PropTypes.instanceOf(AssignmentQuestion).isRequired,
  handleSelect: PropTypes.func.isRequired,
  questions: PropTypes.array.isRequired,
  userAnswers: PropTypes.array.isRequired
}

export default Selector
