import React  from 'react'
import { Link } from 'react-router-dom'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'

import { accountService, alertService } from '../../_services'
import AvatarSelector from './AvatarSelector'

function Update ({ history }) {
  const user = accountService.userValue
  const initialValues = {
    username: user.username,
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    password: '',
    confirmPassword: '',
    avatar: []
  }
  
  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .required('Username is required'),
    firstName: Yup.string()
      .required('First Name is required'),
    lastName: Yup.string()
      .required('Last Name is required'),
    email: Yup.string()
      .email('Email is invalid')
      .required('Email is required'),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters'),
    confirmPassword: Yup.string()
      .when('password', (password, schema) => {
        if (password) return schema.required('Confirm Password is required')
      })
      .oneOf([Yup.ref('password')], 'Passwords must match')
  })
  
  function onSubmit (fields, { setStatus, setSubmitting }) {
    setStatus()
    accountService.update(user.id, fields)
      .then(() => {
        alertService.success('Update successful', { keepAfterRouteChange: true })
        history.push('.')
        window.location.reload(false)
      })
      .catch(error => {
        setSubmitting(false)
        alertService.error('Something went wrong while updating your profile.')
      })
  }
  
  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ errors, touched, isSubmitting, setFieldValue }) => (
        <Form className="pb-1 pt-1">
          <h1 className="font-sp">Update Profile</h1>
          <div className="form-row">
            <div className="form-group col">
              <label>Username</label>
              <Field name="username" type="text"
                     className={'width-100 font-white placeholder-white placeholder-italic background-sp border-sp' + (errors.firstName && touched.firstName ? ' is-invalid' : '')}/>
              <ErrorMessage name="username" component="div" className="invalid-feedback"/>
            </div>
            <div className="form-group col-5">
              <label>First Name</label>
              <Field name="firstName" type="text"
                     className={'width-100 font-white placeholder-white placeholder-italic background-sp border-sp' + (errors.firstName && touched.firstName ? ' is-invalid' : '')}/>
              <ErrorMessage name="firstName" component="div" className="invalid-feedback"/>
            </div>
            <div className="form-group col-5">
              <label>Last Name</label>
              <Field name="lastName" type="text"
                     className={'width-100 font-white placeholder-white placeholder-italic background-sp border-sp' + (errors.lastName && touched.lastName ? ' is-invalid' : '')}/>
              <ErrorMessage name="lastName" component="div" className="invalid-feedback"/>
            </div>
          </div>
          <div className="form-group">
            <label>Email</label>
            <Field name="email" type="text"
                   className={'width-100 font-white placeholder-white placeholder-italic background-sp border-sp' + (errors.email && touched.email ? ' is-invalid' : '')}/>
            <ErrorMessage name="email" component="div" className="invalid-feedback"/>
          </div>
          <div className="form-group col-12 col-sm-10 col-md-6 col-lg-4 col-xl-4">
            <label htmlFor="avatar" className="form-check-label">Update avatar</label>
            <Field as={AvatarSelector} name="avatar" id="avatar" setFieldValue={setFieldValue}/>
          </div>
          <h3 className="pt-3 font-sp">Change Password</h3>
          <p>Leave blank to keep the same password</p>
          <div className="form-row">
            <div className="form-group col">
              <label>Password</label>
              <Field name="password" type="password"
                     className={'width-100 font-white placeholder-white placeholder-italic background-sp border-sp' + (errors.password && touched.password ? ' is-invalid' : '')}/>
              <ErrorMessage name="password" component="div" className="invalid-feedback"/>
            </div>
            <div className="form-group col">
              <label>Confirm Password</label>
              <Field name="confirmPassword" type="password"
                     className={'width-100 font-white placeholder-white placeholder-italic background-sp border-sp' + (errors.confirmPassword && touched.confirmPassword ? ' is-invalid' : '')}/>
              <ErrorMessage name="confirmPassword" component="div" className="invalid-feedback"/>
            </div>
          </div>
          <div className="form-group">
            <button type="submit" disabled={isSubmitting} className="button-sp button-no-text-decoration font-white">
              {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"/>}
              Update
            </button>
            <Link to="." className="btn btn-link">Cancel</Link>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export { Update }
