import React, { Component } from 'react'
import PropTypes from 'prop-types'
import EscapeLinesListItem from './EscapeLinesListItem'

class EscapeLinesList extends Component {
  render () {
    return (
      <div className="EscapeLinesList">
        <ul className='list-unstyled'>
          {this.props.escapeLines.map((escapeLine, index) => {
            return (
              <li key={escapeLine.id} className='mb-1'>
                <EscapeLinesListItem
                  escapeLine={escapeLine}
                />
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
}

EscapeLinesList.propTypes = {
  escapeLines: PropTypes.array.isRequired,
}

export default EscapeLinesList
