/**
 * @property {number} questionId
 * @property {?boolean} [isCorrect]
 * @property {QuestionUserAnswer[]} [answers]
 */
class QuestionResultResponse {
  constructor (obj) {
    Object.assign(this, obj)
  }
}

export default QuestionResultResponse
