import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FaChevronLeft } from 'react-icons/fa'

class PrevButton extends Component {
  render () {
    return (
      <div className='col'>
        <button type="button"
                onClick={() => this.props.handleClick(this.props.prevAssignmentQuestionNum)}
                className="assignment-item__question-confirm-button w-100"><FaChevronLeft/> Prev Question</button>
      </div>
    )
  }
}

PrevButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
  prevAssignmentQuestionNum: PropTypes.number.isRequired,
}

export default PrevButton
