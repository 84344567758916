import React, { Component } from 'react'
import PropTypes from 'prop-types'

class BackgroundSlider extends Component {
  render () {
    return (
      <div className="background-slider pt-3"
           style={this.props.slideItem && { backgroundImage: 'url("' + this.props.slideItem.bgImageUrl + '")' }}>
        {this.props.children}
      </div>
    )
  }
}

BackgroundSlider.propTypes = {
  slideItem: PropTypes.object,
}

export default BackgroundSlider
