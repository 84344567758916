import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ListItem from './ListItem'
import AssignmentQuestion from '../../../_models/AssignmentQuestion'
import QuestionAnswer from '../../../_models/QuestionAnswer'
import AssignmentQuestionResultResponse from '../../../_models/AssignmentQuestionResultResponse'
import AssignmentQuestionAnswerResultResponse from '../../../_models/AssignmentQuestionAnswerResultResponse'
import User from '../../../_models/User'

class List extends Component {
  render () {
    let allSelectedAnswersCorrect = null
    // TODO: refactor - get rid of duplicate code
    this.props.answers.map((answer, index) => {
      const assignmentQuestionAnswerResultResponse = this.props.assignmentQuestionResultResponse.answers.filter(answerResponse => answerResponse.questionAnswerId === answer.id)[0]
      const isCorrect = assignmentQuestionAnswerResultResponse.isCorrect
      const isSelected = assignmentQuestionAnswerResultResponse.userAnswers.filter((userAnswer) => userAnswer.createdBy === this.props.user.id).length > 0

      if (isSelected && assignmentQuestionAnswerResultResponse.isVotedFor) {
        allSelectedAnswersCorrect = allSelectedAnswersCorrect !== false && isCorrect && isSelected && assignmentQuestionAnswerResultResponse.isVotedFor;
      }
    })

    return (
      <div className="answers-list">
        <div className='row no-gutters'>
          {this.props.answers.map((answer, index) => {
            const assignmentQuestionAnswerResultResponse = this.props.assignmentQuestionResultResponse.answers.filter(answerResponse => answerResponse.questionAnswerId === answer.id)[0]
            const isCorrect = assignmentQuestionAnswerResultResponse.isCorrect
            const isSelected = assignmentQuestionAnswerResultResponse.userAnswers.filter((userAnswer) => userAnswer.createdBy === this.props.user.id).length > 0
            
            return (
              <div key={answer.id}
                   className={'col-6 mt-2' + ((index % 2) ? ' pl-1' : ' pr-1')}>
                <ListItem
                  allSelectedAnswersCorrect={allSelectedAnswersCorrect === true}
                  answer={answer}
                  assignmentQuestion={this.props.assignmentQuestion}
                  isSelected={isSelected}
                  isCorrect={isCorrect === true ? true : null}
                  isFinal={assignmentQuestionAnswerResultResponse.isVotedFor}
                  isWrong={isCorrect === false ? true : null}
                  handleSelect={this.props.handleSelect}
                  setCanQuestionAnswerBeConfirmed={this.props.setCanQuestionAnswerBeConfirmed}
                  assignmentQuestionAnswerResultResponse={new AssignmentQuestionAnswerResultResponse(assignmentQuestionAnswerResultResponse)}
                />
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

List.propTypes = {
  answers: PropTypes.arrayOf(PropTypes.shape(new QuestionAnswer())).isRequired,
  assignmentQuestion: PropTypes.instanceOf(AssignmentQuestion).isRequired,
  handleSelect: PropTypes.func,
  assignmentQuestionResultResponse: PropTypes.instanceOf(AssignmentQuestionResultResponse),
  user: PropTypes.instanceOf(User).isRequired,
  setCanQuestionAnswerBeConfirmed: PropTypes.func,
}

export default List
