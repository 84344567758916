import React from 'react'
import { Link } from 'react-router-dom'
import { ErrorMessage, Field, Form, Formik } from 'formik'

import * as Yup from 'yup'
import { accountService, alertService } from '../../_services'

function Login ({ history, location }) {
  const initialValues = {
    email: '',
    password: ''
  }
  
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email is invalid')
      .required('Email is required'),
    password: Yup.string().required('Password is required')
  })
  
  function onSubmit ({ email, password }, { setSubmitting }) {
    alertService.clear()
    accountService.login(email, password)
      .then(() => {
        const { from } = location.state || { from: { pathname: '/' } }
        history.push(from)
      })
      .catch(error => {
        setSubmitting(false)
        console.log(error)
        alertService.error('Unable to log you in. Please check your email and password.')
      })
  }
  
  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ errors, touched, isSubmitting }) => (
        <Form>
          <div className="form-row">
            <div className="form-group col-md-12 col-sm-12 col-11 px-5 padding-top-x-small min-vh-60">
              <h3 className="font-sp">Login</h3>
              <div className="form-row">
                <div className="form-group col">
                  <Field name="email" type="text" placeholder="Email..."
                         className={'width-100 font-white placeholder-white placeholder-italic background-sp border-sp' + (errors.email && touched.email ? ' is-invalid' : '')}/>
                  <ErrorMessage name="email" component="div" className="invalid-feedback"/>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col">
                  <Field name="password" type="password" placeholder="Password..."
                         className={'width-100 font-white placeholder-white placeholder-italic background-sp border-sp' + (errors.password && touched.password ? ' is-invalid' : '')}/>
                  <ErrorMessage name="password" component="div" className="invalid-feedback"/>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col">
                  <button type="submit" disabled={isSubmitting} className="button-sp font-white">
                    {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"/>}
                    Login
                  </button>
                </div>
                <div className="form-group col text-right">
                  <Link to="forgot-password" className="btn btn-link pr-0">Forgot Password?</Link>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col margin-top-small">
                  <Link to="register" className="button-sp button-no-text-decoration font-white">Register</Link>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export { Login }
