import React from 'react'
import MedalList from '../list/Index'
import * as PropTypes from 'prop-types'
import MedalOverviewList from './MedalOverviewList'
import SectionHeader from '../../SectionHeader'
import { isNumeric } from 'rxjs/internal-compatibility'

class MedalOverview extends React.Component {
  render () {
    const getCountByType = (medals, type) => medals
      .reduce((total, value) => parseInt((isNumeric(total) ? total : total[type])) + parseInt(value[type]))
    const { medals } = this.props
    const bronze = getCountByType(medals, 'bronze')
    const gold = getCountByType(medals, 'gold')
    const silver = getCountByType(medals, 'silver')
    
    return (
      <div key="medal-overview" id="medal-overview" className="select-none">
        <div className="row">
          <SectionHeader wrapperClass='col awards-section-header--medals' title={'Medals'}/>
        </div>
        <MedalOverviewList bronze={bronze} gold={gold} silver={silver}/>
        <div className="row medals-total-count">
          <div className="col text-center">
            <p className="font-sp font-white font-italic">Total Medals: {bronze + silver + gold}</p>
          </div>
        </div>
        <div className="row mt-5 justify-content-center">
          <div className="col-12">
            <MedalList medals={medals}/>
          </div>
        </div>
      </div>
    )
  }
}

MedalOverview.propTypes = { medals: PropTypes.any }

export default MedalOverview
