import React from 'react'
import PropTypes from 'prop-types'

class PieCross extends React.Component {
  render () {
    let { coords } = this.props
    return (
      <g key={'g_' + coords[0]}>
        <line key={'a_' + coords[0]}
              x1={coords[0] - 5}
              x2={coords[0] + 5}
              y1={coords[1] - 5}
              y2={coords[1] + 5}
              strokeWidth={2}
              stroke={'white'}
        />
        <line key={'b_' + coords[0]}
              x1={coords[0] - 5}
              x2={coords[0] + 5}
              y1={coords[1] + 5}
              y2={coords[1] - 5}
              strokeWidth={2}
              stroke={'white'}
        />
      </g>
    )
  }
}

PieCross.defaultProps = { coords: [0, 0] }

PieCross.propTypes = {
  coords: PropTypes.array.isRequired
}

export default PieCross