import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Question from '../../../_models/Question'
import { FaCheck, FaExternalLinkAlt } from 'react-icons/fa/index'
import _ from 'lodash'
import TopicBadge from './TopicBadge'
import ReactHtmlParser from 'react-html-parser'
import FlagIcon from '../../common/flags/FlagIcon'
import User from '../../../_models/User'
import { MathJax } from 'better-react-mathjax'

class QuestionListItemBody extends Component {
  renderQuestionTopicBadges = (input) => {
    if (_.isArray(input) && input.length > 0) {
      return <div className='assignment-item__question-topic'>
        {
          input.map((value, index) => {
            return <TopicBadge title={value.title} key={index} value={value} clickAction={this.props.handleTopicBadgeClick}/>
          })
        }
      </div>
    } else {
      return <></>
    }
  }
  
  render () {
    const { isExpanded, question, user } = this.props
    const html = question.body
    const attachments = question.attachments
    
    return (
      <div className="assignment-item-body row no-gutters h-100 mr-2 p-4 bg-white">
        <div className='col-11'>
          <div className='assignment-item__title'>
            <span className={'d-inline-block' + (isExpanded ? '' : ' truncate-title')}><MathJax>{ReactHtmlParser(html)}</MathJax></span>
          </div>
          <div className='assignment-item__question-category'>{question.category.title}</div>
          {this.renderQuestionTopicBadges(question.topics)}
          <div className='assignment-item__question-type'>
            {question.typeText}
            {question.display_correct_qt && question.type === 2
              ? (' (' + (question.correct_qt > 0 ? question.correct_qt : 'Multiple')
                + ' correct answer'
                + (question.correct_qt !== 1 ? 's' : '')
                + ')')
              : ''}
          </div>
          <div className='assignment-item__questions-status'>
            {/* Status indicator showing question answered or unanswered */}
          </div>
          <div className='assignment-item__title'>
            {
              (attachments && attachments.length > 0) && <>
                <div className="my-3">
                  <h5>{'Attachments:'}</h5>
                  <div className="btn-toolbar" role="toolbar" aria-label="Attachments">
                    <div className="btn-group" role="group" aria-label="Attachments group">
                      {
                        attachments.map((attachment, index) => {
                          return <a href={attachment.path} target="_blank" key={attachment.id} rel="noreferrer"  className="btn btn-info mr-1">
                            {attachment.name ? attachment.name : ('Attachment #' + (index + 1))} <FaExternalLinkAlt size='18px' color='white'/>
                          </a>
                        })
                      }
                    </div>
                  </div>
                </div>
              </>
            }
          </div>
        </div>
        <div className="col-1 d-flex align-items-center">
          {
            (user && (user.role === 'admin' || user.role === 'admin-limit' || user.role === 'teacher')) &&
            <FlagIcon questionFlagCategories={this.props.questionFlagCategories} question={question}/>
          }
          {
            (question.answered === true) &&
            <FaCheck size="25px" color="green"/>
          }
        </div>
      </div>
    )
  }
}

QuestionListItemBody.propTypes = {
  isExpanded: PropTypes.bool,
  handleTopicBadgeClick: PropTypes.func,
  question: PropTypes.instanceOf(Question).isRequired,
  questionFlagCategories: PropTypes.array,
  user: PropTypes.instanceOf(User).isRequired,
}

export default QuestionListItemBody
