import React from 'react'
import * as d3 from 'd3'
import * as tinycolor from 'tinycolor2'
import PieArc from './PieArc'
import PieCross from './PieCross'
import * as PropTypes from 'prop-types'

class Target extends React.Component {
  render () {
    let { tests, height, width, outerRadius } = this.props
    const holeWidth = 5
    const min = 1
    const max = 10
    const outerRadiusDeviation = 0.82
    const middleRadiusDeviation = 0.5
    const innerRadiusDeviation = 0.22
    
    const generateDatasetFromTestData = (data) => {
      let total = 0
      let categoryData = {}
      let newDataset = []
      Object.keys(data).forEach((key, i) => {
        let categoryAmount = data[key].perfect.length + data[key].withHints.length + data[key].failed.length
        total = total + categoryAmount
        categoryData[key] = { color: data[key].category.color, value: categoryAmount }
      })
      Object.keys(categoryData).forEach((key, i) => {
        newDataset.push({ value: (categoryData[key].value / total) * 100, color: categoryData[key].color })
      })
      
      return newDataset.length > 0
        ? newDataset
        : [{ value: 100, color: 'white' }]
    }
    
    const getRadiusForChart = () => {
      let radiusOuter = Math.min(width, height) / 2
      let radiusMiddle = radiusOuter - holeWidth
      let radiusInner = radiusMiddle - holeWidth
      return { 'outer': radiusOuter, 'middle': radiusMiddle, 'inner': radiusInner }
    }
    
    const getArcsForChart = (radiuses) => {
      let arcs = {}
      
      arcs.inner = d3.arc()
        .innerRadius(radiuses.inner - (285 + holeWidth))
        .outerRadius(radiuses.inner)
      arcs.middle = d3.arc()
        .innerRadius(radiuses.middle - (185 + holeWidth))
        .outerRadius(radiuses.middle)
      arcs.outer = d3.arc()
        .innerRadius(radiuses.outer - (85 + holeWidth))
        .outerRadius(radiuses.outer)
      
      return arcs
    }
    
    const getColor = (color, key) => {
      let arcColor = color
      if (key === 'middle') {
        arcColor = tinycolor(arcColor).darken(10).toString()
      }
      
      return arcColor
    }
    
    const getRandomDeviation = () => {
      let positiveOrNegative = Math.random() < 0.5 ? -1 : 1
      return (Math.floor(Math.random() * (max + 1 - min)) + min) * positiveOrNegative
    }
    
    const renderPieChart = (data) => {
      let dataset = generateDatasetFromTestData(data)
      let radiuses = getRadiusForChart()
      let arcs = getArcsForChart(radiuses)
      
      let pieChart = d3
        .pie()
        .value(d => d.value)
        .sort(null)
      let pieData = pieChart(dataset)
      
      if (dataset.length > 0) {
        let pieSVGs = []
        Object.keys(arcs).forEach((key, i) => {
          let svgElement = Object.keys(pieData).map((d, i) => {
              return <PieArc
                key={key + '_' + d}
                arc={arcs[key](pieData[d])}
                color={getColor(pieData[d].data.color, key)}
              />
            }
          )
          pieSVGs = [...pieSVGs, svgElement]
        })
        
        return generateTargetHits(radiuses, pieSVGs, pieData, data)
      }
    }
    
    // TODO: Improve math to narrow angle so crosses don't get super close to the edge of their pie slice
    const renderTargetHits = (number, radius, radiusDeviation, data) => {
      let crossData = new Array(number).fill(undefined).map(() => ({
        angle: (data.startAngle + Math.random() * (data.endAngle - data.startAngle) - Math.PI / 2),
        radius: radius * radiusDeviation + getRandomDeviation()
      }))
      let crossElems = []
      
      // TODO: Maybe use something like shortID to generate IDs for read-only elements instead
      crossData.forEach((item, i) => {
        let coords = [item.radius * Math.cos(item.angle), item.radius * Math.sin(item.angle)]
        crossElems.push(<PieCross
          key={'cross_' + coords[0] + '_' + coords[1]}
          coords={coords}
        />)
      })
      
      return crossElems
    }
    
    const generateTargetHits = (radiuses, pieSVGs, pieData, data) => {
      if (Object.keys(data).length > 0) {
        let crosses = []
        Object.keys(data).forEach((key, i) => {
          let crossPerfect = renderTargetHits(data[key].perfect.length, radiuses.inner, innerRadiusDeviation, pieData[i])
          let crossWithHints = renderTargetHits(data[key].withHints.length, radiuses.middle, middleRadiusDeviation, pieData[i])
          let crossFailed = renderTargetHits(data[key].failed.length, radiuses.outer, outerRadiusDeviation, pieData[i])
          crosses = [...crosses, crossPerfect, crossWithHints, crossFailed]
        })
        pieSVGs = [...pieSVGs, crosses]
      }
      
      return pieSVGs
    }
    
    return (
      <div id="target">
        <svg key="pieChartSVG" width={width} height={height}>
          <g key="pieChartGraphic" transform={`translate(${outerRadius} ${outerRadius})`}>
            {renderPieChart(tests)}
          </g>
        </svg>
      </div>
    )
  }
}

Target.propTypes = {
  tests: PropTypes.any,
  height: PropTypes.number,
  width: PropTypes.number,
  outerRadius: PropTypes.number
}

Target.defaultProps = { height: 600, width: 600, outerRadius: 300 }

export default Target
