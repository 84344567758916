import React, { Component } from 'react'
import PropTypes from 'prop-types'
import iconGroupSmall from '../../../resources/img/ui/icon_group_sm.png'
import AssignmentQuestion from '../../../_models/AssignmentQuestion'
import FlagIcon from '../../common/flags/FlagIcon'
import ReactHtmlParser from 'react-html-parser'
import User from '../../../_models/User'
import { FaExternalLinkAlt } from 'react-icons/fa/index'
import { MathJax } from 'better-react-mathjax'

class ListItemBody extends Component {
  render () {
    const { question, user } = this.props
    const attachments = question.question.attachments

    return (
      <div className="question-item-body h-100 bg-white">
        <div className='question-item-type d-table p-2 mr-0 ml-auto'>
          <div>
            {question.groupQuestion ? 'Group Question' : 'Individual Question'}
            {question.groupQuestion && <img src={iconGroupSmall} alt={'icon Group Small'}/>}
          </div>
        </div>
        <div className='row no-gutters px-4 py-2'>
          <div className='col-12'>
            <div className='assignment-item__questions-status'>
              <span
                className='assignment-item__questions-status__complete-questions'>{this.props.questionNum}</span> {'/ ' + this.props.questionsTotalCount}
            </div>
            <div className='assignment-item__title'>
              <MathJax>{ReactHtmlParser(question.question.body)}</MathJax>
            </div>
            <div className='assignment-item__title'>
              {
                (attachments && attachments.length > 0) && <>
                  <div className="row my-3">
                    <h5>{'Attachments:'}</h5>
                    <div className="col-12 btn-toolbar" role="toolbar" aria-label="Attachments">
                      <div className="btn-group" role="group" aria-label="Attachments group">
                        {
                          attachments.map((attachment, index) => {
                            return <a href={attachment.path} target="_blank" key={attachment.id} rel="noreferrer"  className="btn btn-info mr-1">
                              {attachment.name ? attachment.name : ('Attachment #' + (index + 1))} <FaExternalLinkAlt size='18px' color='white'/>
                            </a>
                          })
                        }
                      </div>
                    </div>
                  </div>
                </>
              }
            </div>
            <div className='assignment-item__question-type' style={{color: '#212529'}}>
              {question.question.typeText}
              {question.question.display_correct_qt && question.question.type === 2
                ? (' (' + (question.question.correct_qt > 0 ? question.question.correct_qt : 'Multiple')
                  + ' correct answer'
                  + (question.question.correct_qt !== 1 ? 's' : '')
                  + ')')
                : ''}
            </div>
            {
              (user && (user.role === 'admin' || user.role === 'admin-limit' || user.role === 'teacher')) &&
              <div>
                <FlagIcon questionFlagCategories={this.props.questionFlagCategories} question={question}/>
              </div>
            }
            {
              question.question.type === 2 && question.question.display_correct_qt && question.question.displayedCorrectQt
              && <div className="text-info">{'Correct answers required: ' + question.question.displayedCorrectQt}</div>
            }
          </div>
        </div>
      </div>
    )
  }
}

ListItemBody.propTypes = {
  question: PropTypes.instanceOf(AssignmentQuestion).isRequired,
  questionNum: PropTypes.number.isRequired,
  questionsTotalCount: PropTypes.number.isRequired,
  questionFlagCategories: PropTypes.array,
  isGroupQuestion: PropTypes.bool,
  user: PropTypes.instanceOf(User),
}

export default ListItemBody
