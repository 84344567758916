import React from 'react'
import AwardListItem from './ListItem'
import { Table } from 'react-bootstrap'
import * as PropTypes from 'prop-types'

class MedalList extends React.Component {
  render () {
    return (
      <Table hover borderless className="awards-table">
        <tbody>
        {this.props.medals.map((medal, index) => {
          return (
            <AwardListItem key={'medal_' + index} medal={medal} number={index + 1}/>
          )
        })}
        </tbody>
      </Table>
    )
  }
}

MedalList.propTypes = { medals: PropTypes.any }

export default MedalList
