import React, { Component } from 'react'
import { FaAngleLeft, FaAngleRight } from 'react-icons/all'
import AvatarComposite from '../common/AvatarComposite'
import { avatarService } from '../../_services'

class AvatarSelector extends Component {
  constructor (props) {
    super(props)
    this.state = {
      parts: [],
      avatar: [],
      selectedParts: [0, 0, 0]
    }
    this.config = {
      'buttonRow': 'col-2 col-sm-3 col-md-2 col-lg-3 col-xl-3',
      'display': 'col-7 col-sm-6 col-md-8 col-lg-6 col-xl-6'
    }
    if (this.props.config && this.props.config === 'narrow') {
      this.config = {
        'buttonRow': 'col-1 col-sm-3 col-md-2 col-lg-3 col-xl-3',
        'display': 'col-6 col-sm-6 col-md-8 col-lg-6 col-xl-6'
      }
    }
  }
  
  handleInitialSetup (existingUserAvatar) {
    const { selectedParts, parts } = this.state
    let newSelectedParts = []
    if (this.props.avatar && this.props.avatar.length > 0) {
      // Apply avatar from register form
      existingUserAvatar = this.props.avatar
    }
    if (existingUserAvatar.length > 0) {
      newSelectedParts = [...selectedParts]
      existingUserAvatar.forEach((userAvatarPart, index) => {
        newSelectedParts[index] = userAvatarPart.id ?? userAvatarPart
      })
    } else {
      // Create randomized avatar when no avatar is set
      newSelectedParts = []
      parts.forEach((partCategory, index) => {
        // Assign a random part from that category
        let partIDs = partCategory.map(part => part.id)
        newSelectedParts[index] = partIDs[Math.floor(Math.random() * partIDs.length)]
      })
    }
    this.setState({ selectedParts: newSelectedParts })
    this.handleAvatarUpdate()
  }
  
  handleAvatarUpdate () {
    const { parts, avatar, selectedParts } = this.state
    const { setFieldValue } = this.props
    let newAvatar = [...avatar]
    selectedParts.forEach((selectedPart, index) => {
      if (parts[index] !== undefined) {
        let partObject = parts[index].filter(part => part.id === selectedPart)[0]
        newAvatar[index] = { url: partObject.imageUrl }
      }
    })
    setFieldValue('avatar', selectedParts)
    this.setState({ avatar: newAvatar })
  }
  
  async handleAvatarPartChange (part, direction) {
    const { parts, selectedParts } = this.state
    const partToChange = selectedParts[part]
    let newSelectedParts = [...selectedParts]
    let partObject = false
    switch (direction) {
      case 'previous':
        partObject = parts[part].filter(part => part.id === partToChange - 1)[0]
        if (partObject) {
          newSelectedParts[part]--
        }
        break
      case 'next':
        partObject = parts[part].filter(part => part.id === partToChange + 1)[0]
        if (partObject) {
          newSelectedParts[part]++
        }
        break
      default:
        break
    }
    
    await this.setState({ selectedParts: newSelectedParts })
    this.handleAvatarUpdate()
  }
  
  async componentDidMount () {
    await avatarService.getParts().then((res) => this.setState({ parts: res })).catch(error => {
      console.log(error)
    })
    avatarService.getUserAvatar().then((res) => this.handleInitialSetup(res))
  }
  
  render () {
    // TODO: Figure out better positioning (breaks when contained in smaller element, like register form)
    return (
      <div className="avatar-selector">
        <div className="row">
          <div className={this.config.buttonRow + ' button-row-left'}>
            {/* Arrows left */}
            <div className="row">
              <div className="col">
                {/* Arrows top */}
                <FaAngleLeft size="30px" onClick={() => (this.handleAvatarPartChange(2, 'previous'))}
                             className="font-sp cursor-pointer"/>
              </div>
            </div>
            <div className="row">
              <div className="col">
                {/* Arrows middle */}
                <FaAngleLeft size="30px" onClick={() => (this.handleAvatarPartChange(0, 'previous'))}
                             className="font-sp cursor-pointer"/>
              </div>
            </div>
            <div className="row">
              <div className="col">
                {/* Arrows bottom */}
                <FaAngleLeft size="30px" onClick={() => (this.handleAvatarPartChange(1, 'previous'))}
                             className="font-sp cursor-pointer"/>
              </div>
            </div>
          </div>
          <div className={this.config.display}>
            {/* Avatar display */}
            <AvatarComposite avatar={this.state.avatar}/>
          </div>
          <div className={this.config.buttonRow + ' button-row-right'}>
            {/* Arrows right */}
            <div className="row">
              <div className="col">
                {/* Arrows top */}
                <FaAngleRight size="30px" onClick={() => (this.handleAvatarPartChange(2, 'next'))}
                              className="font-sp cursor-pointer"/>
              </div>
            </div>
            <div className="row">
              <div className="col">
                {/* Arrows middle */}
                <FaAngleRight size="30px" onClick={() => (this.handleAvatarPartChange(0, 'next'))}
                              className="font-sp cursor-pointer"/>
              </div>
            </div>
            <div className="row">
              <div className="col">
                {/* Arrows bottom */}
                <FaAngleRight size="30px" onClick={() => (this.handleAvatarPartChange(1, 'next'))}
                              className="font-sp cursor-pointer"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AvatarSelector
