import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import Select from 'react-select'
import { alertService, questionFlagService } from '../../../_services'
import QuestionFlag from '../../../_models/QuestionFlag'

class FlagForm extends Component {
  state = {
    selectedCategory: null,
    userInput: '',
    showError: false,
    showErrorCat: false
  }
  
  submitFlag = () => {
    if (this.isValid()) {
      this.setState({ showError: false })
      const params = new QuestionFlag({
        questionFlagCategoryId: this.state.selectedCategory.id,
        questionId: this.props.question.id,
        body: this.state.userInput
      })
      questionFlagService.create(params).then(r => {
        this.setState({
          selectedCategory: null,
          userInput: ''
        })
        this.props.hideFlagForm()
        alertService.success('Question successfully flagged.')
      }).catch(e => {
        console.log(e)
        alertService.error('Something went wrong while submitting the question flag.')
      })
    } else {
      this.setState({ showError: true })
    }
  }
  
  checkAndUpdateText = (input) => {
    if (input.length <= 4096) {
      this.setState({ userInput: input, showError: false })
    }
  }
  
  requiresMoreInfo = () => {
    if (this.state.selectedCategory) {
      return this.state.selectedCategory.requires_input
    }
  }
  
  isValid = () => {
    if (this.state.selectedCategory) {
      this.setState({ showErrorCat: false })
      if (!this.requiresMoreInfo()) {
        return true
      }
      
      return !!(this.state.userInput && this.state.userInput.length > 4)
    } else {
      this.setState({ showErrorCat: true })
    }
    
    return false
  }
  
  render () {
    const { onClose, show = false, categories = [] } = this.props
    
    return (
      <Modal show={show}>
        <Modal.Header closeButton={false}>
          <Modal.Title>Flag Question</Modal.Title>
        </Modal.Header>
        
        <Modal.Body>
          <p>Please select a category and provide more information (if applicable).</p>
          <Select
            className="mb-3"
            options={categories.map(s => {
              return { label: s.title, value: s.id }
            })}
            placeholder={'Select....'}
            onChange={e => this.setState({
              selectedCategory: this.props.categories.filter(i => i.id === e.value)[0],
              showError: false,
              showErrorCat: false
            })}
          />
          {this.state.showErrorCat && <span className="error">Please select a category.</span>}
          <p>{this.state.selectedCategory ? this.state.selectedCategory.description : ''}</p>
          <textarea className="form-control"
                    placeholder={this.requiresMoreInfo() ? 'Please specify the issue (required)...' : 'Provide more information (optional)...'}
                    value={this.state.userInput}
                    onChange={e => {this.checkAndUpdateText(e.currentTarget.value)}}/>
          <span className="float-right textarea-counter">{this.state.userInput.length} / 4096</span>
          {this.state.showError && <span className="error">Please specify the issue (min. 5 characters).</span>}
        </Modal.Body>
        
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>Close</Button>
          <Button variant="success" className="ml-auto" onClick={this.submitFlag}>Submit</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default FlagForm