import React, { Component } from 'react'
import PropTypes from 'prop-types'
import EscapeLinesList from './EscapeLinesList'
import { Collapse } from 'react-bootstrap'
import { FaAngleDown, FaAngleUp } from 'react-icons/fa/index'
import CollapseButton from '../../common/CollapseButton'
import { alertService, escapeLineService } from '../../../_services'
import Spinner from "../../common/Spinner";
import AssignmentQuestion from '../../../_models/AssignmentQuestion'

class EscapeLines extends Component {
    state = {
        escapeLines: [],
        expanded: false,
        escapeLinesLoading: false
    }

    doLoadEscapeLines() {
        escapeLineService.getByQuestionId(this.props.assignmentQuestion.question.id).then((res) => this.initEscapeLines(res)).catch(error => {
          console.log(error)
          alertService.error('Something went wrong while loading Escape Lines.')
        })
    }

    initEscapeLines(escapeLines) {
        this.setState({escapeLines, escapeLinesLoading: false});
    }

  render () {
    return (
      <div className="escape-lines row no-gutters">
        <div className='col-11'>
          <div className="d-flex assignment-item__section-header">
            <div className="pr-3">Escape Lines</div>
            <div className="flex-grow-1 pr-2">
              <div className='assignment-item__middle-line'/>
            </div>
          </div>
        </div>
        <CollapseButton
          handleClick={async () => {
              await this.setState({expanded: !this.state.expanded});
              const {expanded, escapeLines} = this.state;
              if (expanded && escapeLines.length < 1) {
                  this.setState({escapeLinesLoading: true});
                  this.doLoadEscapeLines();
              }
          }}
          isExpanded={this.state.expanded}
          collapsedIcon={<FaAngleDown size='18px' color='white'/>}
          expandedIcon={<FaAngleUp size='18px' color='white'/>}
          wrapperClass='col-1 text-right wider-collapse-button'
        />

        <div className='col-12 mt-3'>
          <Collapse in={this.state.expanded}>
            <div className='w-100'>{(this.state.escapeLinesLoading && this.state.escapeLines.length < 1
                ? <div className="EscapeLinesList">
                    <Spinner/>
                </div>
                : <EscapeLinesList escapeLines={this.state.escapeLines}/>)}
            </div>
          </Collapse>
        </div>
      </div>
    )
  }
}

EscapeLines.propTypes = {
  assignmentQuestion: PropTypes.instanceOf(AssignmentQuestion).isRequired,
}

export default EscapeLines
