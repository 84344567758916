import React, { Component } from 'react'
import CategoryBar from './CategoryBar'
import { topicService } from '../../_services'
import ReactTooltip from 'react-tooltip'
import Select from 'react-select'
import PropTypes from 'prop-types'

class ExploreSearch extends Component {
  state = {
    typingTimeout: 0,
    difficultyOptions: [
      {
        label: '1 - Lowest',
        value: 1
      },
      {
        label: '2',
        value: 2
      },
      {
        label: '3',
        value: 3
      },
      {
        label: '4',
        value: 4
      },
      {
        label: '5 - Highest',
        value: 5
      }
    ]
  }

  triggerSearch = (callback) => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout)
    }

    this.setState({
      typingTimeout: setTimeout(function () {
        callback()
      }, 1000)
    })
  }

  formatOptions = input => {
    const items = []
    input.forEach(item => {
      items.push({ label: item.title, value: item.id })
    })

    return items
  }

  promiseOptions = (inputValue) => {
    return new Promise(resolve => {
      topicService.search({ query: inputValue }).then(r => {
        const options = this.formatOptions(r)
        this.setState({ topicOptions: options })
        resolve(options)
      }).catch(e => {
        const options = this.formatOptions([{ label: '', value: -1 }])
        this.setState({ topicOptions: options })
        resolve(options)
      })
    })
  }

  componentDidMount () {
    if (this.props.searchParams.topics.length > 0) {
      this.props.onSearch()
    }

    ReactTooltip.rebuild()
  }

  render () {
    const { onSearch, categories, searchParams, topicOptions, updateSearchParams } = this.props
    const { difficulties, difficultyOptions } = this.state

    return (
      <div className="col">
        <div className="row mb-2">
          <div className="col">
            {/* Search bar */}
            <input type="text" placeholder="Search by question title, subject or question type..."
                   className="form-control border-radius-none" onChange={e => {
              updateSearchParams({query: e.target.value, page: 1}, this.triggerSearch(onSearch))
            }}/>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col">
            {/* Difficulty filter bar */}
            <Select
              isMulti
              isClearable
              placeholder={'Search questions by their difficulty level(s)...'}
              options={difficultyOptions}
              value={difficulties}
              onChange={async e => {
                updateSearchParams({ difficulties: e || [], page: 1 }, this.triggerSearch(onSearch))
              }}
            />
          </div>
          <div className="col">
            {/* Topic filter bar */}
            <Select
              isMulti
              isClearable
              placeholder={'Search questions by their topic(s)...'}
              options={topicOptions}
              value={searchParams.topics}
              onChange={async e => {
                updateSearchParams({ topics: e || false, page: 1 }, this.triggerSearch(onSearch))
              }}
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-12 col-sm-12 col-md-6 col-lg-7 col-xl-9">
            {/* Filter bar */}
            <CategoryBar
              searchParams={searchParams}
              categories={categories}
                         onSearch={onSearch}
                         updateSearchParams={this.props.updateSearchParams}/>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-3 my-auto">
            <label htmlFor="includeAnswered"
                   className="form-control border-radius-none select-none"><input
              type="checkbox" name="includeAnswered" id="includeAnswered"
              value={this.props.searchParams.includeAnswered}
              onChange={async e => {
                updateSearchParams({ includeAnswered: !this.props.searchParams.includeAnswered, page: 1 }, this.triggerSearch(onSearch))
              }}
            />&nbsp; Include answered questions</label>
            <label htmlFor="showPreviousAnswers" hidden={!this.props.searchParams.includeAnswered}
                   className="form-control border-radius-none select-none"><input
              type="checkbox" name="showPreviousAnswers" id="showPreviousAnswers"
              value={this.props.searchParams.showPreviousAnswers}
              onChange={async e => {
                updateSearchParams({
                  showPreviousAnswers: !this.props.searchParams.showPreviousAnswers,
                  page: 1
                }, this.triggerSearch(onSearch))
              }}
            />&nbsp; Show previous answers</label>
          </div>
        </div>
        <ReactTooltip effect={'solid'}/>
      </div>
    )
  }
}

ExploreSearch.propTypes = {
  topicOptions: PropTypes.array,
  onSearch: PropTypes.any,
  categories: PropTypes.any,
  searchParams: PropTypes.any,
  topicSearchRef: PropTypes.any,
  updateSearchParams: PropTypes.any,
}

export default ExploreSearch
