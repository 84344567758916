import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FaCheck } from 'react-icons/fa/index'
import QuestionUserAnswer from '../../../_models/QuestionUserAnswer'

class UserAnswerIndicator extends Component {
  render () {
    return (
      <div className="user-answer-indicator">
        <img src={this.props.userAnswer.user.avatar} className='d-block' alt={'Answer author avatar'}/>
        {this.props.userAnswer.confirmed
        && <div>
          <FaCheck size='20px' color='white'/>
        </div>}
      </div>
    )
  }
}

UserAnswerIndicator.propTypes = {
  userAnswer: PropTypes.instanceOf(QuestionUserAnswer).isRequired,
}

export default UserAnswerIndicator
