import React, { Component } from 'react'
import { FaTrophy } from 'react-icons/fa/index'

class FinalIndicator extends Component {
  render () {
    return (
      <span className='mr-2'>
        <FaTrophy size='20px' color='#00897b'/>
      </span>
    )
  }
}

export default FinalIndicator
