import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'

const TextInput = ({ addonText, addonClick, dataAttrs, hide, disabled, name, label, onChange, placeholder, error, hint, inputClass }) => {
  let wrapperClass = 'form-group pr-1' + (hide ? ' hide' : '')
  if (error && error.length > 0) {
    wrapperClass += ' has-error'
  }
  
  return (
    <div className={wrapperClass}>
      {label && <label htmlFor={name}>{label}</label>}
      <div className="field">
        <div className={addonText && 'input-group'}>
          <Field name={name}>
            {({ field, form, meta }) => (
              <>
                <input
                  {...field}
                  type="text"
                  {...dataAttrs}
                  disabled={disabled}
                  id={name}
                  value={field.value || ''}
                  className={'form-control ' + (inputClass || '')}
                  onChange={onChange}
                  placeholder={placeholder}
                  style={{ borderRadius: '0' }}
                />
                {meta.touched &&
                meta.error && <div className="error">{meta.error}</div>}
              </>
            )}
          </Field>
          {addonText && <span className="input-group-btn"><button onClick={addonClick} className="btn btn-default"
                                                                  type="button">{addonText}</button></span>}
          {hint &&
          <div className='font-weight-bold font-italic'>{hint}</div>}
        </div>
      </div>
    </div>
  )
}

TextInput.propTypes = {
  dataAttrs: PropTypes.object,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string
}

export default TextInput
