import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CollapseButton from '../../common/CollapseButton'
import { Collapse } from 'react-bootstrap'
import EscapeLinesListItemBody from './EscapeLinesListItemBody'
import EscapeLinesListItemBodyDetails from './EscapeLinesListItemBodyDetails'
import { FaMinus, FaPlus, FaAngleRight } from 'react-icons/fa/index'

class EscapeLinesListItem extends Component {
  state = {
    expanded: false
  }

  isEscapeLineALink(escapeLine) {
      return escapeLine.url !== null && escapeLine.body === null;
  }

  render () {
    return (
      <div className="EscapeLinesListItem row no-gutters">
        <div className='col-11'>
          <EscapeLinesListItemBody escapeLine={this.props.escapeLine}>
            <Collapse in={this.state.expanded}>
              <div>
                  {!this.isEscapeLineALink(this.props.escapeLine) &&
                  <EscapeLinesListItemBodyDetails escapeLine={this.props.escapeLine}/>}
              </div>
            </Collapse>
          </EscapeLinesListItemBody>
        </div>
        <div className='col-1'>
            {(this.isEscapeLineALink(this.props.escapeLine)) ?
                <div className="h-100 text-center py-3 bg-white cursor-pointer">
                    <a className="btn btn-link h-100 w-100"
                       target="_blank"
                       rel="noreferrer"
                       href={this.props.escapeLine.url}>
                            <FaAngleRight size='24px'/>
                    </a>
                </div>
                :
                <CollapseButton
                    handleClick={() => this.setState({ expanded: !this.state.expanded })}
                    isExpanded={this.state.expanded}
                    collapsedIcon={<FaPlus size='18px'/>}
                    expandedIcon={<FaMinus size='18px'/>}
                    wrapperClass='h-100 text-center py-3 bg-white'
                />}

        </div>
      </div>
    )
  }
}

EscapeLinesListItem.propTypes = {
  escapeLine: PropTypes.object.isRequired
}

export default EscapeLinesListItem
