import React from 'react'
import * as PropTypes from 'prop-types'
import MedalOverviewListItem from '../overview/MedalOverviewListItem'

class AwardListItemMedal extends React.Component {
  render () {
    let { bronze, gold, silver } = this.props
    // TODO: improve smaller screens layout
    return (
      <div className="d-flex align-items-center">
        <MedalOverviewListItem medalsCount={gold} wrapperClass='mr-2 medal-container--small' altText={'Medal Gold'} type='gold'/>
        <MedalOverviewListItem medalsCount={silver} wrapperClass='mr-2 medal-container--small' altText={'Medal Silver'} type='silver'/>
        <MedalOverviewListItem medalsCount={bronze} wrapperClass='medal-container--small' altText={'Medal Bronze'} type='bronze'/>
      </div>
    )
  }
}

AwardListItemMedal.propTypes = {
  bronze: PropTypes.number.isRequired,
  gold: PropTypes.number.isRequired,
  silver: PropTypes.number.isRequired,
}

AwardListItemMedal.defaultProps = {
  bronze: 0,
  gold: 0,
  silver: 0
}

export default AwardListItemMedal
