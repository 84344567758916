import React from 'react'
import { accountService } from '../../_services'
import { Link } from 'react-router-dom'
import { Dropdown } from 'react-bootstrap'

const UserMenu = ({ classes = '', user }) => {
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <img
      src={user && user.avatarUrl}
      alt="User avatar"
      className="dropdown-toggle img-64 cursor-pointer sp-avatar"
      ref={ref}
      onClick={(e) => {
        e.preventDefault()
        onClick(e)
      }}
    />
  ))
  
  return (
    <div className={classes + ' '}>
      <div className="row">
        <div id="user-greeting" className="col-6 align-self-center">
          <span className="font-sp font-italic">Hello, {user.firstName}!</span>
        </div>
        <div className="col-6 ml-auto">
          <Dropdown drop="left">
            <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
              Dropdown Button
            </Dropdown.Toggle>
            
            <Dropdown.Menu>
              <Dropdown.Item as={Link} to="/profile" href="#">Your Profile</Dropdown.Item>
              <Dropdown.Item href="#" onClick={accountService.logout}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    
    </div>
  )
}

export default UserMenu
