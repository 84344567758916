import React, { Component } from 'react'
import PropTypes from 'prop-types'
//import * as moment from "moment/moment";
import * as moment from "moment-timezone";

class CommentsListItem extends Component {
  render () {
      const createdAtDate = this.props.comment.createdAt
      // Adding a Z makes Moment.js treat the date as UTC, allowing timezone conversion
      const createdAt = moment.tz(createdAtDate.date + "Z", moment.tz.guess());
      return (
      <div className="question-comment-body row no-gutters h-100 bg-white p-2 pr-3">
        <div className='col-1'>
          <img src={this.props.comment.user.avatarUrl}
               className='img-fluid mx-auto d-block question-comment_avatar sp-avatar'
               alt={'comment author avatar'}/>
        </div>

        <div className='col-11'>
          <div className='overflow-hidden'>
            <span className='question-comment_username'>{this.props.comment.user.username}</span>
            <span className='question-comment_date'>{createdAt.fromNow()}</span>
          </div>
          <div className='assignment-item__section__content'>{this.props.comment.body}</div>
        </div>
      </div>
    )
  }
}

CommentsListItem.propTypes = {
  comment: PropTypes.object.isRequired,
}

export default CommentsListItem
