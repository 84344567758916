import React from 'react';
import { render } from 'react-dom'
import { BrowserRouter as Router } from "react-router-dom";
import { createBrowserHistory as history} from 'history'
 import { accountService } from './_services';
import './index.css';
import './App.scss';
import './css/science.scss';
import { App } from './components/app/Index';
import {configureFakeBackend} from "./_helpers/fake-backend";
import * as serviceWorker from './serviceWorker';

configureFakeBackend()

// attempt silent token refresh before startup
accountService.refreshToken().finally(startApp) // TODO
//startApp()
function startApp() {
  render(
    <Router history={history} basename="/user">
      <App />
    </Router>,
    document.getElementById('root')
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
