import { fetchWrapper } from '../_helpers'

const apiUrl = process.env.REACT_APP_API_URL
const baseUrl = `${apiUrl}/api/v1/assignments`

export const assignmentService = {
  getAll,
  getAssignmentQuestions,
}

function getAll (params = '') {
  return fetchWrapper.get(`${baseUrl}/${params}`)
}

function getAssignmentQuestions (id) {
  return fetchWrapper.get(`${baseUrl}/${id}/questions`)
}
