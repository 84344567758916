import React, { Component } from 'react'
import PropTypes from 'prop-types'
import InputForm from './InputForm'
import QuestionStatus from './QuestionStatus'
import Result from './Result'
import AssignmentQuestion from '../../../_models/AssignmentQuestion'
import User from '../../../_models/User'
import QuestionAnswer from '../../../_models/QuestionAnswer'
import QuestionFeedback from '../../../_models/QuestionFeedback'
import AssignmentQuestionResultResponse from '../../../_models/AssignmentQuestionResultResponse'
import ConfirmButton from './ConfirmButton'

class Answers extends Component {
  canQuestionAnswerBeConfirmed = function () {
    const answers = this.props.assignmentQuestionResultResponse.answers
    const assignmentQuestion = this.props.assignmentQuestion
    const minAnswersQt = assignmentQuestion.question.minAnswersQt
    const hasMinAnswersQtVotedFor = answers
      .filter(answer => answer.userAnswers
        .filter(userAnswer => (userAnswer.assignmentQuestionId === assignmentQuestion.id)).length >= this.props.votesNeeded
      ).length >= minAnswersQt
    const userAnswersUnconfirmed = answers
      .filter(answer => answer.userAnswers
        .filter(userAnswer => (userAnswer.assignmentQuestionId === assignmentQuestion.id
          && !userAnswer.confirmed
          && userAnswer.user.id === this.props.user.id)).length > 0
      ).length >= minAnswersQt

    return hasMinAnswersQtVotedFor && userAnswersUnconfirmed
  }

  state = {
    isQuestionConfirmedByUser: this.props.assignmentQuestionResultResponse.answers
      .filter(answer => answer.userAnswers
        .filter(userAnswer => (userAnswer.assignmentQuestionId === this.props.assignmentQuestion.id && userAnswer.confirmed && userAnswer.user.id === this.props.user.id)).length > 0
      ).length > 0,
    canQuestionAnswerBeConfirmed: this.canQuestionAnswerBeConfirmed(),
  }

  setCanQuestionAnswerBeConfirmed = (canQuestionAnswerBeConfirmed) => this.setState({ canQuestionAnswerBeConfirmed })

  render () {
    const canQuestionAnswerBeConfirmed = this.canQuestionAnswerBeConfirmed()
    const isCorrect = this.props.assignmentQuestionResultResponse.isCorrect
    const feedbacks = (isCorrect !== null)
      ? this.props.assignmentQuestion.question.feedback.filter(f => !!f.isAnswerCorrect === !!isCorrect) : []
    const randomFeedback = feedbacks.length > 0
      ? feedbacks[Math.floor(Math.random() * feedbacks.length)]
      : {}

    return (
      <div className="answers row no-gutters mt-2 mb-4">
        <div className="col-12 mb-2">
          <InputForm
            answers={this.props.answers}
            assignmentQuestion={this.props.assignmentQuestion}
            setCanQuestionAnswerBeConfirmed={this.setCanQuestionAnswerBeConfirmed}
            handleSelect={this.props.handleAnswerSelect}
            handleSubmit={this.props.handleAnswerSubmit}
            isAnswered={this.state.isQuestionConfirmedByUser}
            user={this.props.user}
            assignmentQuestionResultResponse={this.props.assignmentQuestionResultResponse}/>
        </div>
        {(canQuestionAnswerBeConfirmed && this.state.canQuestionAnswerBeConfirmed && !this.state.isQuestionConfirmedByUser)
        && <ConfirmButton assignmentQuestion={this.props.assignmentQuestion}
                          setCanQuestionAnswerBeConfirmed={this.setCanQuestionAnswerBeConfirmed}
                          setIsQuestionConfirmedByUser={(isQuestionConfirmedByUser) => this.setState({ isQuestionConfirmedByUser })}
                          handleAnswerConfirm={this.props.handleAnswerConfirm}
                          afterAnswerConfirm={this.props.afterAnswerConfirm}
                          updateUsedHints={this.props.updateUsedHints}/>}
        <div className="col-12">
          {this.props.assignmentQuestion.groupQuestion &&
          <QuestionStatus votesNeeded={this.props.votesNeeded}/>}
        </div>
        <div className="col-12">
          {(isCorrect !== null)
          && <Result
            assignmentQuestion={this.props.assignmentQuestion}
            correct={isCorrect}
            feedback={randomFeedback ? new QuestionFeedback(randomFeedback) : null}
            handleAnswerRetry={this.props.handleAnswerRetry}
            setCanQuestionAnswerBeConfirmed={this.setCanQuestionAnswerBeConfirmed}
            hint={this.props.hint}
            setIsQuestionConfirmedByUser={(isQuestionConfirmedByUser) => this.setState({ isQuestionConfirmedByUser })}
          />
          }
        </div>
      </div>
    )
  }
}

Answers.propTypes = {
  answers: PropTypes.arrayOf(PropTypes.shape(new QuestionAnswer())).isRequired,
  assignmentQuestion: PropTypes.instanceOf(AssignmentQuestion).isRequired,
  hint: PropTypes.object,
  groupId: PropTypes.number,
  votesNeeded: PropTypes.number,
  handleAnswerRetry: PropTypes.func.isRequired,
  handleAnswerSelect: PropTypes.func.isRequired,
  handleAnswerSubmit: PropTypes.func.isRequired,
  handleAnswerConfirm: PropTypes.func.isRequired,
  afterAnswerConfirm: PropTypes.func.isRequired,
  updateUsedHints: PropTypes.func.isRequired,
  assignmentQuestionResultResponse: PropTypes.instanceOf(AssignmentQuestionResultResponse),
  user: PropTypes.instanceOf(User).isRequired,
}

export default Answers
