import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import queryString from 'query-string'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'

import { accountService, alertService } from '../../_services'

function ResetPassword ({ history }) {
  const TokenStatus = {
    Validating: 'Validating',
    Valid: 'Valid',
    Invalid: 'Invalid'
  }
  
  const [token, setToken] = useState(null)
  const [email, setEmail] = useState(null)
  const [tokenStatus, setTokenStatus] = useState(TokenStatus.Validating)
  
  useEffect(() => {
    const { token, email } = queryString.parse(window.location.search)
    
    accountService.validateResetToken({ token, email })
      .then(() => {
        setToken(token)
        setEmail(email)
        setTokenStatus(TokenStatus.Valid)
      })
      .catch(() => {
        setTokenStatus(TokenStatus.Invalid)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  function getForm () {
    const initialValues = {
      password: '',
      confirmPassword: ''
    }
    
    const validationSchema = Yup.object().shape({
      password: Yup.string()
        .min(6, 'Password must be at least 6 characters')
        .required('Password is required'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required'),
    })
    
    function onSubmit ({ password, confirmPassword }, { setSubmitting }) {
      alertService.clear()
      accountService.resetPassword({ token, email, password, confirmPassword })
        .then(() => {
          alertService.success('Password reset successful, you can now login', { keepAfterRouteChange: true })
          history.push('login')
        })
        .catch(error => {
          setSubmitting(false)
          console.log(error)
          alertService.error('Something went wrong while resetting your password. Please try again.')
        })
    }
    
    return (
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ errors, touched, isSubmitting }) => (
          <Form>
            <div className="form-row">
              <div className="form-group col-md-12 col-sm-12 col-10 px-5 padding-top-x-small min-vh-60">
                <h3 className="font-sp">Reset Password</h3>
                <div className="form-row">
                  <div className="form-group col">
                    <Field name="password" type="password" placeholder="New Password..."
                           className={'width-100 font-white placeholder-white placeholder-italic background-sp border-sp' + (errors.password && touched.password ? ' is-invalid' : '')}/>
                    <ErrorMessage name="password" component="div" className="invalid-feedback"/>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col">
                    <Field name="confirmPassword" type="password" placeholder="Confirm New Password..."
                           className={'width-100 font-white placeholder-white placeholder-italic background-sp border-sp' + (errors.confirmPassword && touched.confirmPassword ? ' is-invalid' : '')}/>
                    <ErrorMessage name="confirmPassword" component="div" className="invalid-feedback"/>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col">
                    <button type="submit" disabled={isSubmitting} className="button-sp font-white">
                      {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"/>}
                      Reset Password
                    </button>
                    <Link to="login" className="btn btn-link">Cancel</Link>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    )
  }
  
  function getBody () {
    switch (tokenStatus) {
      case TokenStatus.Valid:
        return getForm()
      case TokenStatus.Invalid:
        return <div>Token validation failed, if the token has expired you can get a new one at the <Link
          to="forgot-password">forgot password</Link> page.</div>
      case TokenStatus.Validating:
        return <div>Validating token...</div>
      default:
        return ''
    }
  }
  
  return (
    <div>
      <div>{getBody()}</div>
    </div>
  )
}

export { ResetPassword }
