import React from 'react'
import { FaLock, FaUnlock } from 'react-icons/all'
import * as PropTypes from 'prop-types'
import SectionHeader from '../SectionHeader'

class Privacy extends React.Component {
  render () {
    let { locked, onPrivacyChange, iconSize } = this.props
    return (
      <>
        <div className="divided mb-5 row align-items-center">
          <div className='col-auto'>
            <span className="font-white font-size-20">Profile --- Achievements --- Privacy</span>
          </div>
          <div className='col'>
            <div className="divider"/>
          </div>
        </div>
        <div className="row">
          <SectionHeader wrapperClass='col-12 col-sm-3 awards-section-header--privacy'/>
          <div className="col pl-5">
            <h4 className="font-white">Privacy</h4>
            <p className="font-white font-size-16">{'If you do not want your user profile to be visible to other users,\n' +
            'you can click the lock to make it private. That way, only you can look at your profile.'}</p>
            <div className="row">
              <div className="col-auto mr-auto font-white">
                <div className="sp-icon-button cursor-pointer" onClick={onPrivacyChange}>
                  {locked ? <FaLock size={iconSize}/> : <FaUnlock size={iconSize}/>}
                </div>
              </div>
              <div className="col font-white d-flex align-items-center">
                <span>{locked ? 'Unlock Profile' : 'Lock Profile'}</span>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

Privacy.propTypes = {
  locked: PropTypes.any,
  onPrivacyChange: PropTypes.any,
  iconSize: PropTypes.string
}

Privacy.defaultProps = { iconSize: '50px' }

export default Privacy
